import * as React from "react";

import { Table } from "@thisisbud/bui-react-table";

import { SearchInput } from "../../../search-input";
import { useSorting } from "../../../../hooks/use-sorting";
import {
  useFilteredProjects,
  useGetSortButtonLabel,
  useSortedProjects,
  useTableColumns,
  useTableData,
} from "./hooks";
import { allowedSortKeys, defaultSortParams } from "./constants";

import type { Project } from "../../../../api/types/entities";

type Props = {
  projects: Project[];
};

export default function ProjectList(props: Props): React.ReactElement {
  const { projects } = props;

  const [filter, setFilter] = React.useState("");
  const { onSortChange, sortDirection, sortedBy } = useSorting(allowedSortKeys, defaultSortParams);
  const columns = useTableColumns();
  const filteredProjects = useFilteredProjects(projects, filter);
  const sortedProjects = useSortedProjects(filteredProjects, sortDirection, sortedBy);
  const getSortButtonLabel = useGetSortButtonLabel();
  const data = useTableData(sortedProjects, {
    filter,
  });

  const onSearch = React.useCallback(
    function (value: string) {
      setFilter(value);
    },
    [setFilter],
  );

  return (
    <React.Fragment>
      <SearchInput id="project-search" onChange={onSearch} />
      <Table
        columns={columns}
        data={data}
        getSortButtonLabel={getSortButtonLabel}
        id="project-list-table"
        sortDirection={sortDirection}
        sortedBy={sortedBy}
        onSortColumnClick={onSortChange}
      />
    </React.Fragment>
  );
}
