import * as React from "react";
import { useSelector } from "react-redux";

import Loadable from "@thisisbud/react-loadable";
import { HttpError } from "@thisisbud/internal-sdk";

import { loadingView } from "../../../../loading";
import init from "./init";
import { getAvailableEnvironments, getEnvironment } from "../../../../../../store/environment";
import { useProject } from "../../hooks";
import { pageNotFound } from "../../../../../../constants/error-codes";
import CustomInsightsRouteGuard from "../route-guard";

import type { Project } from "../../../../../../api/types/entities";
import type { TargetEnvironment } from "../../../../../../types/environment";

export type LocalLoadableCtx = {
  availableEnvironments: TargetEnvironment[];
  environment: TargetEnvironment;
  project: Project;
};

/**
 * Lazily load the component.
 *
 * @returns The imported module
 */
async function loadComponent(): Promise<{ default: React.ComponentType }> {
  return import(/* webpackChunkName: 'views/projects/project/insights' */ "./component");
}

/**
 * Lazily load the component and initialize data for the project insights list view.
 */
export default function InsightsListViewLoader(): React.ReactElement {
  const currentEnvironment = useSelector(getEnvironment);

  const project = useProject();

  const availableEnvironments = useSelector(getAvailableEnvironments);

  if (typeof project === "undefined") {
    throw new HttpError(404, "Project not found", {
      code: pageNotFound,
    });
  }

  const localCtx = React.useMemo(function (): LocalLoadableCtx {
    return {
      availableEnvironments: availableEnvironments,
      environment: currentEnvironment,
      project: project,
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <CustomInsightsRouteGuard />
      <Loadable
        ctx={localCtx}
        fallback={loadingView}
        fallbackDelay={0}
        init={init}
        loadComponent={loadComponent}
      />
    </React.Fragment>
  );
}
