import { TeamMemberRole } from "../types/entities";

import type { TeamMember } from "../types/entities";
import type { GetTeamMembersResponse } from "../types/responses";

/**
 * Format the team member role.
 *
 * @param role - The team member role
 * @returns The formatted team member role
 */
function formatRole(role: string): TeamMemberRole {
  if (role === "ADMIN") {
    return TeamMemberRole.admin;
  }

  return TeamMemberRole.standard;
}

/**
 * Formats a GET team members API response.
 *
 * @param res - The response received from the team members endpoint
 * @returns A list of team members
 * @public
 */
export function formatGetTeamMembersResponse(res: GetTeamMembersResponse): TeamMember[] {
  const { data } = res;
  return data.map(function (teamMember) {
    return {
      email: teamMember.email,
      environment: teamMember.environment,
      firstName: teamMember.first_name,
      id: teamMember.uuid,
      lastName: teamMember.last_name,
      role: formatRole(teamMember.role),
    };
  });
}
