import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { State } from "../types";
import type { TargetEnvironment } from "../../types/environment";

export type StateSlice = {
  availableEnvironments: TargetEnvironment[];
  environment: TargetEnvironment;
};

const defaultEnv = "sandbox";

const initialState: StateSlice = {
  availableEnvironments: [defaultEnv],
  environment: defaultEnv,
};

const stateSlice = createSlice({
  initialState: initialState,
  name: "environment",
  reducers: {
    resetEnvironment() {
      return initialState;
    },
    setAvailableEnvironments(state: StateSlice, action: PayloadAction<TargetEnvironment[]>) {
      state.availableEnvironments = action.payload;
    },
    setEnvironment(state: StateSlice, action: PayloadAction<TargetEnvironment>) {
      state.environment = action.payload;
    },
  },
});

/**
 * Get the environment state slice.
 *
 * @param state - The root state of the application
 * @returns The environment state slice
 */
export function getStateSlice(state: State): StateSlice {
  return state[stateSlice.name];
}

/**
 * Get the current selected environment.
 *
 * @param state - The root state of the application
 * @returns The currently selected environment
 */
export function getEnvironment(state: State): TargetEnvironment {
  return getStateSlice(state).environment;
}

/**
 * Get the list of available environments.
 *
 * @param state - The root state of the application
 * @returns The list of available environments
 */
export function getAvailableEnvironments(state: State): TargetEnvironment[] {
  return getStateSlice(state).availableEnvironments;
}

export const { name, reducer } = stateSlice;
export const { resetEnvironment, setAvailableEnvironments, setEnvironment } = stateSlice.actions;
