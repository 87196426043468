import * as React from "react";
import { Navigate, useParams } from "react-router-dom";

import Loadable from "@thisisbud/react-loadable";

import { loadingView } from "../../../loading";
import { createHref } from "../../../../../router";
import { RouteName } from "../../../../../router/constants";
import { useTransaction } from "./hooks";

import type { Props } from "./component";

/**
 * Lazily load the component.
 *
 * @returns The imported module
 */
async function loadComponent(): Promise<{ default: React.ComponentType<Props> }> {
  return import(/* webpackChunkName: 'views/demo/dashboard/transaction' */ "./component");
}

/**
 * Lazily load the component for the Demo Dashboard Transaction view.
 */
export default function DemoDashboardTransactionViewLoader(): React.ReactElement {
  const { demoId } = useParams();
  const transaction = useTransaction();

  if (typeof transaction === "undefined") {
    return (
      <Navigate
        replace={true}
        to={createHref({
          name: RouteName.demoDashboardHome,
          params: {
            demoId,
          },
        })}
      />
    );
  }

  return (
    <Loadable fallback={loadingView} loadComponent={loadComponent} transaction={transaction} />
  );
}
