import React from "react";
import { useSelector } from "react-redux";

import { useI18n } from "@thisisbud/i18n-react";
import { snakeCaseToKebabCase } from "@thisisbud/string-utils";
import { Switch } from "@thisisbud/gds-ui/switch";
import { Link } from "@thisisbud/gds-ui/link";

import RouterLink from "../../../../router-link";
import { createHref } from "../../../../../router";
import { RouteName } from "../../../../../router/constants";
import { useProject } from "../hooks";
import { getEnvironment } from "../../../../../store/environment";
import { type Subscription, useToggleWebhookStatus } from "./api";
import { useConfig } from "../../../../../context/config";
import { sortWebhooks } from "./utils";

import type { WebhookTableColumn } from "./types";
import type { SortDirection } from "../../../../../types/sorting";
import type { SubscriptionCategory } from "./api";
import type { TableDataCell } from "../types";

const getDateFormatter = (locale: string): Intl.DateTimeFormat =>
  new Intl.DateTimeFormat(locale, {
    day: "numeric",
    month: "short",
    weekday: "short",
    year: "numeric",
  });

function formatDate(date: Date, locale: string): string {
  const today = new Date();
  const yesterday = new Date().setDate(today.getDate() - 1);
  const dateFormatter = getDateFormatter(locale);

  const formattedDate = dateFormatter.format(date);

  if (formattedDate === dateFormatter.format(today)) {
    return "Today";
  }

  if (formattedDate === dateFormatter.format(yesterday)) {
    return "Yesterday";
  }

  return formattedDate;
}

/**
 * Use the configuration for the table data.
 *
 * @param events - The events list
 * @param options - The table data options
 * @returns The table data configuration
 */
export function useTableData(events: Subscription[]): TableDataCell[][] {
  const i18n = useI18n();
  const project = useProject();
  const currentEnvironment = useSelector(getEnvironment);
  const { mutate: toggleWebhookStatus, isLoading } = useToggleWebhookStatus(
    currentEnvironment,
    project?.clientId ?? "",
  );
  const { defaultLocale } = useConfig();

  const handleToggleWebhookStatus = React.useCallback(
    (webhookId: string, isActive: boolean, category: SubscriptionCategory) => {
      const action = isActive ? "deactivate" : "activate";
      toggleWebhookStatus({ action, category, webhookId });
    },
    [toggleWebhookStatus],
  );

  return React.useMemo(
    function () {
      return events.map(({ eventType, lastModifiedAt, isActive, endpoint, category }) => {
        const formattedEventType = snakeCaseToKebabCase(eventType);
        const formattedLastModifiedAt = formatDate(new Date(lastModifiedAt), defaultLocale);

        return [
          <RouterLink
            as={Link}
            href={createHref({
              name: RouteName.projectSingleWebhook,
              params: {
                clientId: project?.clientId,
                webhookId: eventType,
              },
            })}
            id="demo-dashboard-back-to-console-button"
            key={formattedEventType}
          >
            {eventType}
          </RouterLink>,
          <span key="event-webhook-url">{endpoint}</span>,
          <span key="event-last-modified">{formattedLastModifiedAt}</span>,
          <span key="event-active">
            <Switch
              checked={isActive}
              disabled={isLoading}
              label={i18n.t("projects.project.events-list.column.active")}
              onCheckedChange={() => {
                handleToggleWebhookStatus(eventType, isActive, category);
              }}
            />
          </span>,
        ];
      });
    },
    [events, defaultLocale, project?.clientId, isLoading, i18n, handleToggleWebhookStatus],
  );
}

/**
 * Use the configuration for the table's columns.
 *
 * @returns The table column configuration
 */
export function useTableColumns(): WebhookTableColumn<keyof Subscription>[] {
  const i18n = useI18n();

  return React.useMemo(
    function () {
      return [
        {
          key: "eventType",
          sortable: true,
          title: i18n.t("projects.project.events-list.column.event-name"),
        },
        {
          key: "endpoint",
          sortable: true,
          title: i18n.t("projects.project.events-list.column.url"),
        },
        {
          key: "lastModifiedAt",
          sortable: true,
          title: i18n.t("projects.project.events-list.column.last-modified"),
        },
        { key: "isActive", title: i18n.t("projects.project.events-list.column.active") },
      ];
    },
    [i18n],
  );
}

export const useTranslateMessage = (): {
  translateMessage(message?: string): string;
} => {
  const i18n = useI18n();

  const translateMessage = (message: string): string => {
    if (!message) return "";
    return i18n.t(message);
  };

  return { translateMessage };
};

/**
 * Custom hook that sorts an array of webhooks based on the specified sort direction and property.
 *
 * @param webhooks - The array of webhooks to be sorted.
 * @param sortDirection - The direction in which to sort the webhooks (ascending or descending).
 * @param sortedBy - The property of the webhooks to sort by.
 * @returns The sorted array of webhooks.
 */
export function useSortedWebhooks(
  webhooks: Subscription[],
  sortDirection: SortDirection,
  sortedBy: keyof Subscription,
): Subscription[] {
  const i18n = useI18n();

  return React.useMemo(
    function () {
      return sortWebhooks(i18n, webhooks, sortDirection, sortedBy);
    },
    [i18n, sortDirection, sortedBy, webhooks],
  );
}

/**
 * Returns a function that generates the label for a sort button based on the provided key and direction.
 *
 * @returns A function that takes a key and direction as parameters and returns the generated label.
 */
export function useGetWebhooksSortButtonLabel(): (key: string, direction: string) => string {
  const i18n = useI18n();

  return React.useCallback(
    function (key: string, direction: string) {
      const columnName = i18n.t(`projects.project.webhooks.list.column.${key}`);
      const directionName = i18n.t(`common.sort-direction.${direction}`);

      return i18n.t("common.sort-by", {
        direction: directionName,
        property: columnName,
      });
    },
    [i18n],
  );
}
