import { getCookie } from "./cookies";
import { demoCustomerDetailsCookieName } from "../../common/constants/cookies";

import type { DemoCustomer } from "../../common/api/types/entities";

/**
 * Get demo customer data by an ID.
 *
 * @param id - The ID of the demo customer to retrieve
 * @returns The demo customer data if available, or `undefined` if not
 */
export function getDemoCustomerById(id: string): DemoCustomer | undefined {
  const demoCustomer = getCookie<{
    [key: string]: DemoCustomer;
  }>(demoCustomerDetailsCookieName);

  return typeof demoCustomer !== "undefined" ? demoCustomer[id] : undefined;
}
