import * as React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

/**
 * Use the React Query client provider component to connect and provide a query client.
 *
 * @param props - The component props
 * @returns The React Query provider
 */
export function QueryProvider(props: React.PropsWithChildren<{}>): React.ReactElement {
  const { children } = props;

  return (
    <QueryClientProvider client={client}>
      <ReactQueryDevtools />
      {children}
    </QueryClientProvider>
  );
}
