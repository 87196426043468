import { joinUrlPaths } from "@thisisbud/url-utils";
import { HttpError } from "@thisisbud/internal-sdk";

import { formatDemoAccountsResponse } from "../../../../../api/formatters/demo-accounts";
import { formatGetTransactionsResponse } from "../../../../../api/formatters/transactions";
import { internalServerErrror } from "../../../../../constants/error-codes";
import { demoAccountsPath, demoPath, demoTransactionsPath } from "../../../../../router/constants";
import getDemoOpenBankingProviders from "../../../../../api/operations/demo/get-demo-open-banking-providers";

import type { AuthDetails } from "../../../../../api/types/common";
import type { LoadableContext } from "@thisisbud/react-loadable";
import type { LocalLoadableContext } from "..";
import type { InitData } from "../init";
import type {
  GetDemoAccountsResponse,
  GetDemoTransactionsResponse,
} from "../../../../../api/types/responses";

/**
 * Get the static demo data.
 *
 * @param auth - The request auth
 * @param ctx - The loadable context
 * @returns The providers and the static transaction and accounts data
 */
export async function getStaticDemoData(
  auth: AuthDetails,
  ctx: LoadableContext & LocalLoadableContext,
): Promise<InitData> {
  const {
    config: { basePath },
  } = ctx;

  const { providers, accounts, transactions } = await Promise.all([
    getDemoOpenBankingProviders(auth, "sandbox"),
    fetch(joinUrlPaths(basePath, demoPath, demoAccountsPath), {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    }),
    fetch(joinUrlPaths(basePath, demoPath, demoTransactionsPath), {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    }),
  ]).then(async ([providersMap, accountsResponse, transactionsResponse]) => {
    if (!accountsResponse.ok || !transactionsResponse.ok) {
      throw new HttpError(500, "Cannot retrieve demo account data", {
        code: internalServerErrror,
      });
    }

    // TODO: type `any satisfies` feels wrong, should be refined
    return {
      accounts: formatDemoAccountsResponse({
        data: await accountsResponse.json(),
      } as any satisfies GetDemoAccountsResponse),
      providers: providersMap,
      transactions: formatGetTransactionsResponse({
        data: await transactionsResponse.json(),
      } as any satisfies GetDemoTransactionsResponse),
    };
  });

  return {
    accounts,
    providers,
    transactions,
  };
}
