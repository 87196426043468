import * as React from "react";

import { useI18n } from "@thisisbud/i18n-react";

import useCreateProjectDialog from "../../../../hooks/use-create-project-dialog";
import PageHeader from "../../../page-header";
import ProjectList from "./project-list";
import { useProjectsData } from "../hooks";
import { EnvironmentUrl } from "../../../environment-url";

export default function ProjectsList(): React.ReactElement {
  const {
    data: { projects },
    refetch,
  } = useProjectsData();
  const { open, dialog } = useCreateProjectDialog({ onCreated: refetch, screen: "projects-list" });
  const i18n = useI18n();

  return (
    <React.Fragment>
      <PageHeader
        actionButtonId="create-project-button"
        actionLabel={i18n.t("projects.open-create-dialog")}
        description={i18n.t("projects.body")}
        title={
          <div className="flex items-center gap-x-4">
            {i18n.t("projects.title")}
            <EnvironmentUrl />
          </div>
        }
        onActionClick={open}
      />
      <ProjectList projects={projects} />
      {dialog}
    </React.Fragment>
  );
}
