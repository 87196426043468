import I18n from "@thisisbud/i18n";
import { joinUrlPaths } from "@thisisbud/url-utils";

import type { I18nFormatOptions } from "@thisisbud/i18n";

type I18nConfig = {
  formats: I18nFormatOptions;
  locale: string;
  translations: {
    [key: string]: string;
  };
};

type Options = {
  appBasePath: string;
};

/**
 * Get a fully configured I18n instance.
 *
 * @returns A I18n instance configured for the user's preferred locale
 */
export async function getI18n(options: Options): Promise<I18n> {
  const { appBasePath } = options;

  const configUrl = joinUrlPaths(appBasePath, "i18n");
  const config: I18nConfig = await fetch(configUrl).then(async function (res) {
    return res.json();
  });

  return new I18n(config.locale, {
    formats: config.formats,
    translations: config.translations,
  });
}
