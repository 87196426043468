import * as React from "react";
import { useSelector } from "react-redux";

import Loadable from "@thisisbud/react-loadable";

import { getEnvironment } from "../../../store/environment";
import { loadingView } from "../loading";
import { usePageValue } from "./hooks";

import type { LocalLoadableCtx } from "./types";

/**
 * Lazily load the component.
 *
 * @returns The imported module
 */
async function loadComponent(): Promise<{ default: React.ComponentType }> {
  return import(/* webpackChunkName: 'views/customers' */ "./component");
}

/**
 * Lazily load the component and initialize data for the Customer view.
 */
export default function CustomersViewLoader(): React.ReactElement {
  const initialEnvironment = useSelector(getEnvironment);
  const pageValue = usePageValue();

  const localCtx = React.useMemo(function (): LocalLoadableCtx {
    return {
      environment: initialEnvironment,
      page: pageValue,
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Loadable
      ctx={localCtx}
      fallback={loadingView}
      fallbackDelay={0}
      loadComponent={loadComponent}
    />
  );
}
