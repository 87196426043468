import * as React from "react";

/**
 * Use the previous value of a given value
 *
 * @returns The previous value or undefined
 */
export default function usePrevious<T>(value: T): T | undefined {
  const ref = React.useRef(value);

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
