import * as React from "react";
import { useSearchParams } from "react-router-dom";

type Params = {
  [name: string]: string | number | boolean | (string | number | boolean)[] | null | undefined;
};

/**
 * Use a setter of search parameters for the current location.
 *
 * @returns A function that can set search parameters to the current location
 */
export function useSetLocationParams(): (params: Params) => void {
  const [searchParams, setSearchParams] = useSearchParams();

  return React.useCallback(
    function (params: Params) {
      const updatedParams = new URLSearchParams();

      // Set previous values
      searchParams.forEach(function (value, name) {
        updatedParams.set(name, value);
      });

      Object.entries(params).forEach(function ([name, value]) {
        if (value === null || value === undefined) {
          updatedParams.delete(name);
        } else if (Array.isArray(value)) {
          value.forEach(function (element) {
            updatedParams.set(name, String(element));
          });
        } else {
          updatedParams.set(name, String(value));
        }
      });

      setSearchParams(updatedParams);
    },
    [searchParams, setSearchParams],
  );
}
