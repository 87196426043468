import {
  Version,
  formatGetOpenBankingConnectStatusResponse,
  request,
} from "@thisisbud/internal-sdk";

import { Namespace } from "../../constants";

import type { AuthDetails } from "../../types/common";
import type { GetOpenBankingConnectStatusResponse } from "@thisisbud/internal-sdk/lib/types/types/responses";
import type { OpenBankingConnectStatus } from "@thisisbud/internal-sdk";

/**
 * Get the status of a unique connect status task ID.
 *
 * @param auth - The authentication details for the request
 * @param envType - The environment
 * @param connectionTaskId - The ID of the connection task
 * @returns The current status of the connection
 *
 */
export default async function getDemoOpenBankingConnectStatus(
  auth: AuthDetails,
  envType: string,
  connectionTaskId: string,
): Promise<OpenBankingConnectStatus> {
  const response = await request<GetOpenBankingConnectStatusResponse>(
    Version.v1,
    Namespace.openBankingDemo,
    {
      auth: auth,
      headers: {
        "X-User-Env": envType,
      },
      method: "GET",
      path: `/connect/${encodeURIComponent(connectionTaskId)}`,
    },
  );

  return formatGetOpenBankingConnectStatusResponse(response);
}
