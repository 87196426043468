import * as React from "react";
import { useFormikContext } from "formik";

import { useI18n } from "@thisisbud/i18n-react";
import { Button } from "@thisisbud/bui-react-buttons";

import DialogFooter from "../dialog-footer";

type Props = {
  onClose(event: React.MouseEvent<HTMLButtonElement> | KeyboardEvent): void;
};

export default function CreateDialogActions(props: Props): React.ReactElement {
  const { onClose } = props;
  const [isDisabled, setIsDisabled] = React.useState(true);
  const i18n = useI18n();
  const {
    values: { name },
    isSubmitting,
  } = useFormikContext<{
    name: string;
  }>();

  React.useEffect(
    function () {
      setIsDisabled(name.length === 0);
    },
    [name],
  );

  return (
    <DialogFooter>
      <Button
        disabled={isSubmitting}
        id="create-project-dialog-cancel-btn"
        variant="secondary"
        onClick={onClose}
      >
        {i18n.t("common.cancel")}
      </Button>
      <Button
        busy={isSubmitting}
        disabled={isDisabled}
        id="create-project-dialog-confirm-btn"
        type="submit"
      >
        {i18n.t("create-project-dialog.create.form.submit")}
      </Button>
    </DialogFooter>
  );
}
