import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { State } from "../types";

export type StateSlice = {
  authenticated: boolean;
};

const initialState: StateSlice = {
  authenticated: false,
};

const stateSlice = createSlice({
  initialState: initialState,
  name: "auth",
  reducers: {
    setAuthenticated(state: StateSlice, action: PayloadAction<boolean>) {
      state.authenticated = action.payload;
    },
  },
});

/**
 * Get the authentication state slice.
 *
 * @param state - The root state of the application
 * @returns The authentication state slice
 */
function getStateSlice(state: State): StateSlice {
  return state[stateSlice.name];
}

/**
 * Get the current authentication status of the user.
 *
 * @param state - The root state of the application
 * @returns A boolean indicating whether the user is authenticated or not
 */
export function getAuthenticated(state: State): boolean {
  return getStateSlice(state).authenticated;
}

export const { name, reducer } = stateSlice;
export const { setAuthenticated } = stateSlice.actions;
