import { requireAuth } from "../../../../client/utils/auth";
import getTeamMembers from "../../../api/operations/get-team-members";

import type { ProductionTargetEnvironment } from "../../../types/environment";
import type { TeamMember } from "../../../api/types/entities";

type TeamDataOptions = {
  productionEnvironment?: ProductionTargetEnvironment;
};

export type TeamData = {
  teamMembers: TeamMember[];
};

/**
 * Get the team data.
 *
 * @returns The team data
 */
export async function getTeamData(options: TeamDataOptions): Promise<TeamData> {
  const { productionEnvironment } = options;
  const auth = await requireAuth();

  const teamMembers = await getTeamMembers(auth);

  // the BE considers sandbox and production users different users but with the same ID
  const uniqueTeamMembers = teamMembers.reduce(function (acc, teamMember) {
    if (acc.has(teamMember.id)) {
      // update the map key if team member environment is production
      if (teamMember.environment === productionEnvironment) {
        acc.set(teamMember.id, teamMember);
      }
    } else {
      acc.set(teamMember.id, teamMember);
    }

    return acc;
  }, new Map());

  return {
    teamMembers: [...uniqueTeamMembers.values()],
  };
}
