import { HttpError } from "@thisisbud/internal-sdk";

import { pageNotFound } from "../../../constants/error-codes";
import { useConfig } from "../../../context/config";

function CredentialsRouteGuard(): null {
  const { enableCredentialsPage } = useConfig();

  if (!enableCredentialsPage) {
    throw new HttpError(404, "Not found", {
      code: pageNotFound,
    });
  }

  return null;
}

export default CredentialsRouteGuard;
