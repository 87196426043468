import { Namespace, Version, request } from "@thisisbud/internal-sdk";

import { formatOAuthTokenResponse } from "../formatters/oauth-token";

import type { ApiResponse } from "../formatters/oauth-token";
import type { AuthDetails } from "../types/common";
import type { OAuthToken } from "../types/entities";

/**
 * Refresh an existing OAuth token.
 *
 * @param auth - The authentication details for the request
 * @returns An oauth token object
 *
 */
export default async function refreshOAuthToken(auth: AuthDetails): Promise<OAuthToken> {
  const response = await request<ApiResponse>(Version.v1, Namespace.oAuth, {
    body: {
      grant_type: "refresh_token",
      refresh_token: auth.refreshToken,
    },
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "X-User-Id": auth.userId,
    },
    method: "POST",
    path: "/token",
  });

  return formatOAuthTokenResponse(response);
}
