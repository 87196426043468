import { formatGetAccountsResponse } from "@thisisbud/internal-sdk";

import type { Account } from "@thisisbud/internal-sdk";
import type { DemoAccount } from "../types/entities";
import type { GetDemoAccountsResponse } from "../types/responses";

type GetBetaAccountsResponse = Parameters<typeof formatGetAccountsResponse>[0];

/**
 * Map a list of legacy accounts to the beta accounts response.
 *
 * @param legacyAccountResponse - The legacy accounts response
 * @returns The list of accounts to be used in the formatter
 */
function mapLegacyAccountResponse(
  legacyAccountResponse: GetDemoAccountsResponse,
): GetBetaAccountsResponse {
  return {
    ...legacyAccountResponse,
    data: legacyAccountResponse.data.map((legacyAccount) => {
      const balance = legacyAccount.balances[0];

      return {
        account_id: legacyAccount.account_id,
        balance: {
          amount: {
            currency: balance.amount.currency,
            value: balance.amount.amount,
          },
          cdi: balance.credit_debit_indicator,

          credit_line: balance.credit_line.map((creditLine) => ({
            amount: {
              currency: creditLine.amount.currency,
              value: creditLine.amount.amount,
            },
            included: creditLine.included,
            type: creditLine.type,
          })),
          time: "", // refine
        },
        currency: legacyAccount.currency,
        display_identifier: null,
        holder_name: null,
        identifiers: [], // refine
        nickname: legacyAccount.nickname,
        provider: legacyAccount.provider,
        subtype: legacyAccount.account_sub_type,
        transaction_windows: [],
        type: legacyAccount.account_type,
      };
    }),
  };
}

/**
 * Map a list of connected accounts to include their raw data.
 *
 * @param accounts - The list of accounts to extend
 * @param accountsResponse - The list of responses to get the raw data from
 * @returns The list of extended accounts
 */
function mapAccountsToDemoAccounts(
  accounts: Account[],
  accountsResponse: GetBetaAccountsResponse,
): DemoAccount[] {
  return accounts.map((account, index) => {
    return { ...account, raw: accountsResponse.data[index] };
  });
}

/**
 * Formats a GET Demo Accounts API response.
 *
 * @param response - The response received from the demo accounts URL endpoint
 * @returns A list of demo accounts
 */
export function formatDemoAccountsResponse(response: GetDemoAccountsResponse): DemoAccount[] {
  const mappedResponse = mapLegacyAccountResponse(response);

  const formattedAccounts = formatGetAccountsResponse(mappedResponse);

  return mapAccountsToDemoAccounts(formattedAccounts, mappedResponse);
}
