import { HttpError } from "@thisisbud/internal-sdk";

import { pageNotFound } from "../../../constants/error-codes";
import { useConfig } from "../../../context/config";

function EnrichmentRouteGuard(): null {
  const { enableEnrichmentGuide } = useConfig();

  if (!enableEnrichmentGuide) {
    throw new HttpError(404, "Not found", {
      code: pageNotFound,
    });
  }

  return null;
}

export default EnrichmentRouteGuard;
