import { v4 as uuidv4 } from "uuid";

import { sessionIdCookieName } from "../../common/constants/cookies";
import { clearCookie, getCookie, setCookie } from "./cookies";

/**
 * Get an ID for the current session.
 *
 * @returns An ID for the current session
 */
export function getSessionId(): string {
  const sessionId = getCookie(sessionIdCookieName);

  if (typeof sessionId !== "string") {
    const newSessionId = uuidv4();

    setCookie(sessionIdCookieName, newSessionId);

    return newSessionId;
  }

  return sessionId;
}

/**
 * Clear the current session ID.
 */
export function clearSessionId(): void {
  clearCookie(sessionIdCookieName);
}
