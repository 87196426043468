export enum Namespace {
  demo = "showcase",
  insights = "insights",
  invite = "invite",
  me = "me",
  openBankingDemo = "showcase/open-banking",
  openBankingEvents = "",
  passwordRecovery = "password-recovery",
  paymentsEvents = "payments",
  paymentWebhooks = "payments/webhooks",
  project = "",
  prospectiveClients = "prospective-clients",
  resources = "resources",
  subscriptions = "subscriptions",
  user = "user",
  web = "web",
  webhooks = "webhooks",
}
