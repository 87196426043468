import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import { useI18n } from "@thisisbud/i18n-react";
import { useToastNotifications } from "@thisisbud/bui-react-toast-notifications";

import { deleteCustomer } from "./api";
import { getEnvironment } from "../../../../store/environment";
import { CustomerQueryKeys } from "./constants";

import type { UseMutationResult } from "@tanstack/react-query";
import type { DeleteCustomerMutationFn } from "./types";

export const useDeleteCustomer = (): UseMutationResult<
  unknown,
  unknown,
  DeleteCustomerMutationFn
> => {
  const queryClient = useQueryClient();
  const currentEnvironment = useSelector(getEnvironment);
  const { t } = useI18n();
  const toastNotifications = useToastNotifications();

  return useMutation<unknown, unknown, DeleteCustomerMutationFn>({
    mutationFn: async ({ id }): Promise<void> => {
      return deleteCustomer(currentEnvironment, id);
    },
    onError: () => {
      toastNotifications.push({
        label: t("common.action-complete-msg-label"),
        message: t("error.generic.body"),
        type: "error",
      });
    },

    // eslint-disable-next-line no-underscore-dangle
    onSuccess: (_data, { id }) => {
      toastNotifications.push({
        label: t("common.action-complete-msg-label"),
        message: t("customers.delete-dialog.success-message", { customerId: id }),
        type: "success",
      });

      queryClient.removeQueries([CustomerQueryKeys.customers, { customerId: id }]);
      void queryClient.invalidateQueries([CustomerQueryKeys.customers]);
    },
  });
};
