import * as React from "react";
import { Navigate } from "react-router-dom";

import ErrorView from "../views/error";
import { createHref } from "../../router";
import { RouteName } from "../../router/constants";
import { clearCurrentSession } from "../../../client/utils/auth";

import type { HttpError } from "@thisisbud/internal-sdk";

type Props = {
  children: React.ReactNode;
  setPathHasChanged: React.Dispatch<React.SetStateAction<boolean>>;
};

type State = {
  error?: Error | HttpError;
};

export class ErrorBoundary extends React.Component<Props, State> {
  public static getDerivedStateFromError(error: Error): Partial<State> {
    return {
      error,
    };
  }

  constructor(props: Props) {
    super(props);
    this.state = { error: undefined };
  }

  public componentDidCatch(): void {
    this.props.setPathHasChanged(true);
  }

  public render(): React.ReactNode {
    const { children } = this.props;
    const { error } = this.state;

    if (typeof error !== "undefined") {
      const { status } = error as HttpError;

      if (status === 401) {
        clearCurrentSession();

        return <Navigate to={createHref({ name: RouteName.login })} />;
      }

      return <ErrorView error={error} />;
    }

    return children;
  }
}
