import { Version, request } from "@thisisbud/internal-sdk";

import { Namespace } from "../constants";
import { formatGetTeamMembersResponse } from "../formatters/team-members";

import type { GetTeamMembersResponse } from "../types/responses";
import type { AuthDetails } from "../types/common";
import type { TeamMember } from "../types/entities";

/**
 * Get a list of team members.
 *
 * @param auth - The authentication details for the request
 * @returns A list of team members
 */
export default async function getTeamMembers(auth: AuthDetails): Promise<TeamMember[]> {
  const response = await request<GetTeamMembersResponse>(Version.v1, Namespace.user, {
    auth: auth,
    method: "GET",
    path: "/list",
  });

  return formatGetTeamMembersResponse(response);
}
