import { Version, request } from "@thisisbud/internal-sdk";

import { Namespace } from "../../constants";

import type { AuthDetails } from "../../types/common";

/**
 * Delete a project.
 *
 * @param auth - The authentication details for the request
 * @param envType - The environment of the project
 * @param projectClientId - The project client ID
 */
export default async function deleteProject(
  auth: AuthDetails,
  envType: string,
  projectClientId: string,
): Promise<void> {
  await request(Version.v1, Namespace.web, {
    auth: auth,
    headers: {
      "X-User-Env": envType,
    },
    method: "DELETE",
    path: `/api-credentials/${encodeURIComponent(projectClientId)}`,
  });
}
