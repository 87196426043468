import { Version, request } from "@thisisbud/internal-sdk";

import { Namespace } from "../constants";

import type { GetProfileResponse } from "../types/responses";
import type { AuthDetails } from "../types/common";
import type { Profile } from "../types/entities";

/**
 * Get the profile for the current user.
 *
 * @param auth - The authentication details for the request
 * @returns The user profile
 */
export default async function getProfile(auth: AuthDetails): Promise<Profile> {
  const response = await request<GetProfileResponse>(Version.v1, Namespace.me, {
    auth: auth,
    method: "GET",
    path: "/profile",
  });

  return {
    email: response.data.Email,
  };
}
