import styled from "styled-components";

import { selectFromTheme } from "@thisisbud/sc-utils";
import { buttonClassName } from "@thisisbud/bui-react-buttons";

// TODO: possibily removed with component improvements
export default styled.div`
  display: flex;
  margin-top: ${selectFromTheme("spacingSmallDefault")};
  justify-content: flex-end;

  .${buttonClassName} {
    width: auto;
    margin-right: initial;
  }

  .${buttonClassName} + .${buttonClassName} {
    margin-top: 0;
    margin-left: ${selectFromTheme("spacingXXSmallDefault")};
  }
`;
