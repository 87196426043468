import { parseDate } from "@thisisbud/datetime-utils";
import { snakeCaseToKebabCase } from "@thisisbud/string-utils";

import type {
  DemoTransaction as DemoTransactionEntity,
  DemoTransactionMerchant as DemoTransactionMerchantEntity,
} from "../types/entities";
import type {
  DemoTransaction,
  DemoTransactionMerchant,
  GetDemoTransactionsResponse,
} from "../types/responses";

/**
 * Format the merchant of a transaction
 *
 * @param merchant - A merchant from a transaction
 * @returns A formatted merchant or undefined if it is the empty merchant
 *
 */
function formatTransctionMerchant(
  merchant: DemoTransactionMerchant | null,
): DemoTransactionMerchantEntity | undefined {
  if (merchant !== null && merchant.id !== "UNK") {
    return {
      id: merchant.id,
      logoUrl: merchant.merchant_logo,
      name: merchant.merchant_name,
    };
  }
}

/**
 * Format a single transaction from an API response.
 *
 * @param transactionData - A single transaction from an API response
 * @returns A formatted transaction
 *
 */
function formatTransaction(transactionData: DemoTransaction): DemoTransactionEntity {
  const {
    attributes = {},
    enrichment: { merchant },
    provider,
    raw_transaction: {
      account_id,
      amount,
      credit_debit_indicator,
      booking_date_time,
      transaction_information,
      transaction_id,
    },
  } = transactionData;
  const category = attributes.category_l1?.[0];
  const subCategory = attributes.category_l2?.[0];

  return {
    accountId: account_id,
    benefit: false, // refine
    category: typeof category === "string" ? snakeCaseToKebabCase(category) : undefined,
    currency: amount.currency,
    date: parseDate(booking_date_time),
    debtCollection: false, // refine
    id: transaction_id,
    internal: false, // refine
    merchant: formatTransctionMerchant(merchant),
    oneOff: false, // refine
    providerId: provider,
    raw: transactionData,
    reference: transaction_information,
    regular: false, // refine
    salary: false, // refine
    subCategory: typeof subCategory === "string" ? snakeCaseToKebabCase(subCategory) : undefined,
    value: Math.abs(parseFloat(amount.amount)) * (credit_debit_indicator === "Credit" ? 1 : -1),
  };
}

/**
 * Formats a GET Transactions API response.
 *
 * @param res - The response received from the transactions endpoint
 * @returns The list of requested transactions
 *
 */
export function formatGetTransactionsResponse(
  response: GetDemoTransactionsResponse,
): DemoTransactionEntity[] {
  const { data } = response;

  if (!Array.isArray(data)) {
    return [];
  }

  // The API does not return transactions sorted by date
  return data.map(formatTransaction).sort(function (transactionA, transactionB) {
    return transactionB.date.getTime() - transactionA.date.getTime();
  });
}
