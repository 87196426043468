import * as React from "react";

import Loadable from "@thisisbud/react-loadable";

import { loadingView } from "../loading";
import init from "./init";
import { useAvailableProductionEnvironment } from "../../../hooks/use-available-production-environment";

import type { LocalLoadableCtx } from "./types";

/**
 * Lazily load the component.
 *
 * @returns The imported module
 */
async function loadComponent(): Promise<{ default: any }> {
  return import(/* webpackChunkName: 'views/team' */ "./component");
}

/**
 * Lazily load the component and initialize data for the Team view.
 */
export default function TeamViewLoader(): React.ReactElement {
  const productionEnvironment = useAvailableProductionEnvironment();

  const localCtx: LocalLoadableCtx = React.useMemo(() => {
    return {
      productionEnvironment,
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Loadable ctx={localCtx} fallback={loadingView} init={init} loadComponent={loadComponent} />
  );
}
