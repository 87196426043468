import * as React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { selectFromTheme } from "@thisisbud/sc-utils";
import { paragraphSmallCss } from "@thisisbud/bui-react-typography";

import AppNavigationLink from "./link";
import { appNavigationLinkActiveClassName, appNavigationNavClassName } from "./constants";
import { useConfig } from "../../context/config";
import { getAppNavigationRoutes } from "./utils";
import { getEnvironment } from "../../store/environment";

const StyledNav = styled.nav`
  ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    margin-left: ${selectFromTheme("spacingXLargeDefault")};
  }

  a {
    ${paragraphSmallCss}
    color: ${selectFromTheme("colorTextAppHeaderNavigationLinkDefault")};
    text-decoration: none;
    padding-bottom: ${selectFromTheme("spacingXXXXSmallDefault")};
    border-bottom-style: solid;
    border-bottom-width: ${selectFromTheme("thicknessAppHeaderNavigationLinkBorderDefault")};
    border-bottom-color: ${selectFromTheme("colorBorderAppHeaderNavigationLinkDefault")};
    font-weight: ${selectFromTheme("fontWeightAppHeaderNavigationLinkDefault")};

    &:hover {
      color: ${selectFromTheme("colorTextAppHeaderNavigationLinkHover")};
    }
  }

  .${appNavigationLinkActiveClassName} {
    color: ${selectFromTheme("colorTextAppHeaderNavigationLinkActive")};
    border-bottom-color: ${selectFromTheme("colorBorderAppHeaderNavigationLinkActive")};
  }
`;

export default function AppNavigation(): React.ReactElement {
  const config = useConfig();
  const currentEnvironment = useSelector(getEnvironment);
  const navigationRoutes = getAppNavigationRoutes(config, currentEnvironment);

  return (
    <StyledNav className={appNavigationNavClassName}>
      <ul>
        {navigationRoutes.map(function (route) {
          return (
            <li key={route.name}>
              <AppNavigationLink
                labelKey={route.labelKey}
                name={route.name}
                onClick={route.onClick}
              />
            </li>
          );
        })}
      </ul>
    </StyledNav>
  );
}
