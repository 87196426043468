import type I18n from "@thisisbud/i18n";

/**
 * Get a string comparison function for the purposes of sorting.
 *
 * @param i18n - The I18n instance
 * @returns A function that can be used to compare two strings for sorting
 */
export function getCompareFn(i18n: I18n): (x: string, y: string) => number {
  return new Intl.Collator(i18n.locale, {
    caseFirst: "upper",
    usage: "sort",
  }).compare;
}
