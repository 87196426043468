import { getUserId } from "../../../../client/utils/auth";
import { getTeamData } from "./utils";

import type { LoadableContext } from "@thisisbud/react-loadable";
import type { TeamMember } from "../../../api/types/entities";
import type { LocalLoadableCtx } from "./types";

export type InitData = {
  currentUser: TeamMember;
  teamMembers: TeamMember[];
};

export default async function init(ctx: LoadableContext & LocalLoadableCtx): Promise<InitData> {
  const { productionEnvironment } = ctx;
  const currentUserId = getUserId();

  const { teamMembers } = await getTeamData({
    productionEnvironment,
  });

  return {
    currentUser: teamMembers.find(function (member) {
      return member.id === currentUserId;
    }) as TeamMember,
    teamMembers: teamMembers,
  };
}
