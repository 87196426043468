import type I18n from "@thisisbud/i18n";
import type { EventStatus, RequestStatus } from "../api";
import type { Props as PillProps } from "@thisisbud/gds-ui/pill";

type FormatEventStatusLabel = {
  isTestEvent: boolean;
  status?: EventStatus | RequestStatus;
  i18n: I18n;
};

const eventPillStatuses: { [K in EventStatus | RequestStatus | "unknown"]: PillProps["status"] } = {
  created: "neutral",
  failed: "error",
  failure: "error",
  retrying: "info",
  sent: "success",
  succeeded: "success",
  unknown: "neutral",
};

export const getEventPillStatus = (status?: EventStatus | RequestStatus): PillProps["status"] => {
  return eventPillStatuses[status ?? "unknown"];
};

export const formatEventStatusLabel = ({
  isTestEvent,
  status,
  i18n,
}: FormatEventStatusLabel): string => {
  const statusLabel = i18n.t(`projects.event-history.event-status.${status ?? "unknown"}`);

  if (isTestEvent) {
    return `Test - ${statusLabel.toLowerCase()}`;
  }

  return statusLabel;
};
