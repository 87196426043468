import * as React from "react";
import { useLocation } from "react-router-dom";

import { ErrorBoundary as ErrorBoundaryClass } from "./error-boundary";
import usePrevious from "../../hooks/use-previous";

type Props = {
  children: React.ReactNode;
};

export default function ErrorBoundary(props: Props): React.ReactElement {
  const { children } = props;
  const [key, setKey] = React.useState(0);
  const [pathHasChanged, setPathHasChanged] = React.useState(false);
  const { pathname } = useLocation();
  const previousPathName = usePrevious(pathname);

  React.useEffect(() => {
    if (pathHasChanged && previousPathName !== pathname) {
      setKey(function (prevKey) {
        return prevKey + 1;
      });
      setPathHasChanged(false);
    }
  }, [pathHasChanged, previousPathName, pathname]);

  return (
    <ErrorBoundaryClass key={key} setPathHasChanged={setPathHasChanged}>
      {children}
    </ErrorBoundaryClass>
  );
}
