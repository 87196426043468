import { requireAuth } from "../../../../client/utils/auth";
import getProjects from "../../../api/operations/get-projects";
import { RouteName } from "../../../router/constants";
import { isUUID } from "../../../utils/validation";

import type { Breadcrumb, Crumb } from "./types";
import type I18n from "@thisisbud/i18n";
import type { QueryFunctionContext } from "@tanstack/react-query";
import type { Project } from "../../../api/types/entities";

export type ProjectsData = {
  projects: Project[];
};

type QueryKey = [
  name: "projects",
  environment: string,
  obSubscribableEventsSupportedEnvs: string[],
  paymentsSubscribableEventsSupportedEnvs: string[],
];

/**
 * Get the projects data.
 *
 * @param ctx - The query function context
 * @returns The projects data
 */
export async function getProjectsData(ctx: QueryFunctionContext<QueryKey>): Promise<ProjectsData> {
  const {
    queryKey: [, environment],
  } = ctx;

  const auth = await requireAuth();

  const projects = await getProjects(auth, environment);

  return {
    projects,
  };
}

/**
 * Capitalizes the first letter of a string.
 *
 * @param string - The input string.
 * @returns The input string with the first letter capitalized.
 */
export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const unusableCrumbs = ["requests", "payment", "events", "edit"];

/**
 * Filters the breadcrumbs based on a given condition.
 * @param crumb - The breadcrumb to be filtered.
 * @returns A boolean value indicating whether the breadcrumb should be included or not.
 */
const filterBreadcrumbs = (crumb: Breadcrumb): boolean => {
  return !unusableCrumbs.includes(crumb.children.toLowerCase());
};

/**
 * Checks if the current index corresponds to an event path in the given array of crumbs.
 *
 * @param index - The index of the current item in the array.
 * @param childrenCrumbs - The array of crumbs.
 * @returns A boolean indicating whether the current index corresponds to an event path.
 */
const getIsEventPath = (index: number, childrenCrumbs: Crumb[]): boolean => {
  const isNotTheFirstItem = index > 0;
  const isNotTheLastItem = index < childrenCrumbs.length - 1;
  const previousItem = isNotTheFirstItem ? childrenCrumbs[index - 1] : undefined;
  const previousItemPath = previousItem?.path.split("/").pop();

  const isEventPath = isNotTheFirstItem && previousItemPath === "events" && isNotTheLastItem;

  return isEventPath;
};

const customBreadcrumbTitles: { [key: string]: string } = {
  insights: "projects.project.navigation.insights",
  projects: "projects.all-projects",
};

/**
 * Formats the label for a breadcrumb link.
 *
 * @param link - The breadcrumb link.
 * @param isEventPath - Indicates if the link is for an event path.
 * @param t - The translation function.
 * @returns The formatted breadcrumb label.
 */
const formatBreadcrumbLabel = (link: Crumb, isEventPath: boolean, t: I18n["t"]): string => {
  const isCustomBreadcrumb = link.path in customBreadcrumbTitles;

  if (isCustomBreadcrumb) {
    return t(customBreadcrumbTitles[link.path]);
  }

  const isPathUUID = isUUID(link.path);
  const path = isPathUUID ? link.path : capitalizeFirstLetter(link.path);

  if (isEventPath) {
    return t("projects.event-overview");
  }

  return path;
};

/**
 * Formats the breadcrumbs for a project.
 *
 * @param breadcrumbs - The array of breadcrumbs.
 * @param projectName - The name of the project.
 * @param t - The translation function.
 * @returns The formatted breadcrumbs.
 */

export const formatBreadcrumbs = (
  breadcrumbs: Crumb[],
  projectName: string,
  t: I18n["t"],
): Breadcrumb[] => {
  const filteredBreadcrumbs = [...breadcrumbs].filter(
    (breadcrumb) => !["requests"].includes(breadcrumb.path),
  );

  const formattedBreadcrumbs = filteredBreadcrumbs
    .map((link, index) => {
      const isEventPath = getIsEventPath(index, filteredBreadcrumbs);
      const pathLabel = formatBreadcrumbLabel(link, isEventPath, t);

      return { children: pathLabel, href: link.href };
    })
    .filter(filterBreadcrumbs);

  if (Boolean(projectName)) {
    formattedBreadcrumbs.splice(1, 1, {
      children: projectName,
      href: `/${RouteName.projects}/${filteredBreadcrumbs[1]?.path}`,
    });
  }

  return formattedBreadcrumbs;
};
