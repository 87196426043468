import * as React from "react";

import Loadable from "@thisisbud/react-loadable";

import { loadingView } from "../loading";
import init from "./init";

/**
 * Lazily load the component.
 *
 * @returns The imported module
 */
async function loadComponent(): Promise<{ default: React.ComponentType }> {
  return import(/* webpackChunkName: 'views/demo' */ "./component");
}

/**
 * Lazily load the component for the Demo view.
 */
export default function DemoViewLoader(): React.ReactElement {
  return (
    <Loadable fallback={loadingView} fallbackDelay={0} init={init} loadComponent={loadComponent} />
  );
}
