import * as React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { useI18n } from "@thisisbud/i18n-react";
import { Form } from "@thisisbud/bui-react-form";
import { SelectInput } from "@thisisbud/bui-react-select-input";
import { VisuallyHidden } from "@thisisbud/bui-react-visually-hidden";
import { selectFromTheme } from "@thisisbud/sc-utils";

import { getAvailableEnvironments, getEnvironment, setEnvironment } from "../../store/environment";

import type { TargetEnvironment } from "../../types/environment";

const StyledForm = styled(Form)`
  margin-right: ${selectFromTheme("spacingXSmallDefault")};
`;

export default function EnvironmentSelector(): React.ReactElement | null {
  const i18n = useI18n();
  const currentEnvironment = useSelector(getEnvironment);
  const environments = useSelector(getAvailableEnvironments);
  const dispatch = useDispatch();

  const initialFormValues = React.useMemo(
    function () {
      return {
        environment: currentEnvironment,
      };
    },
    [currentEnvironment],
  );

  const onChange = React.useCallback(
    function (event: React.ChangeEvent<HTMLSelectElement>) {
      dispatch(setEnvironment(event.target.value as TargetEnvironment));
    },
    [dispatch],
  );

  if (environments.length === 0) {
    return null;
  }

  return (
    <StyledForm initialValues={initialFormValues}>
      <label htmlFor="environment">
        <VisuallyHidden>{i18n.t("header.environment-selector.label")}</VisuallyHidden>
      </label>
      <SelectInput id="environment-select-input" name="environment" onChange={onChange}>
        {environments.map(function (environment) {
          const environmentKey = `header.environment-selector.list.${environment}`;

          return (
            <option key={environment} value={environment}>
              {i18n.hasTranslation(environmentKey) ? i18n.t(environmentKey) : environment}
            </option>
          );
        })}
      </SelectInput>
    </StyledForm>
  );
}
