import React from "react";
import classNames from "classnames";

import { useI18n } from "@thisisbud/i18n-react";
import { Table } from "@thisisbud/bui-react-table";
import { Button } from "@thisisbud/gds-ui/button";

import {
  useEventFilterDialog,
  useEventHistoryTableColumns,
  useEventHistoryTableData,
  useReplayEventsDialog,
} from "./hooks";
import { EventFilterDialog } from "./event-filter-dialog";
import { ReplayEventsDialog } from "./replay-events-dialog";
import { useConfig } from "../../../../../../context/config";
import { StatusBar } from "../replay-events/status-bar";
import { ReplayHistoryDialog } from "../replay-events/replay-history.dialog";
import { useAppDispatch } from "../../../../../../store";
import { openReplayHistoryDialog } from "../../../../../../store/webhooks";
import LoadingView from "../../../../loading";

export function EventHistory(): React.ReactElement {
  const { t } = useI18n();
  const columns = useEventHistoryTableColumns();
  const {
    isEventFilterDialogOpen: isOpen,
    closeEventFilterDialog: onClose,
    openEventFilterDialog,
    filters,
    actions,
    activeFiltersAmount,
  } = useEventFilterDialog();
  const { enableReplayEvents } = useConfig();
  const { openReplayEventsDialog, isReplayEventsDialogOpen, closeReplayEventsDialog } =
    useReplayEventsDialog();
  const { data, fetchNextPage, isLoading, isFetching, hasNextPage, inView } =
    useEventHistoryTableData(filters);
  const dispatch = useAppDispatch();

  const isReplayEventsButtonDisabled = true;

  const openReplayDialog = React.useCallback(() => {
    dispatch(openReplayHistoryDialog());
  }, [dispatch]);

  React.useEffect(() => {
    if (inView && Boolean(hasNextPage)) {
      void fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  return (
    <div className="mb-[7.75rem] mt-8">
      <EventFilterDialog actions={actions} filters={filters} isOpen={isOpen} onClose={onClose} />
      <ReplayEventsDialog
        isOpen={isReplayEventsDialogOpen}
        openEventFilterDialog={openEventFilterDialog}
        totalAmount={10}
        onClose={closeReplayEventsDialog}
      />
      {/* TODO: pass the correct prop */}
      <ReplayHistoryDialog totalAmount={10} />
      <header className="mb-6 flex w-auto flex-col gap-6">
        <div
          className={classNames("flex w-full items-center justify-end", {
            "justify-between": enableReplayEvents,
          })}
        >
          {enableReplayEvents ? (
            <div className="flex items-center gap-4">
              <Button
                disabled={isReplayEventsButtonDisabled}
                variant="secondary"
                onClick={openReplayEventsDialog}
              >
                {t("projects.event-history.replay-events-dialog.replay-events")}
              </Button>
              <Button variant="secondary" onClick={openReplayDialog}>
                {t("projects.event-history.replay-history-dialog.view-replay-history")}
              </Button>
            </div>
          ) : null}
          <div className="flex items-center gap-4">
            <span className="text-surface-body">{t("projects.event-history.description")}</span>
            <Button variant="secondary" onClick={openEventFilterDialog}>
              {t("projects.event-history.filter-dialog.filters", {
                amount: activeFiltersAmount,
              })}
            </Button>
          </div>
        </div>
        <StatusBar amount={1000} current={20} />
      </header>
      {isLoading ? <LoadingView /> : <Table columns={columns} data={data} />}
      {isFetching && !isLoading ? <LoadingView /> : null}
    </div>
  );
}
