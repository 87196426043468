import { track } from "../../../client/utils/tracking";
import Events from "../../constants/events";
import { RouteName } from "../../router/constants";

import type { TargetEnvironment } from "../../types/environment";
import type { AppConfig } from "../../../types/config";

export type AppNavRoute = {
  labelKey: string;
  name: string;
  onClick?(): void;
};

/**
 * Generates the application navigation routes based on the provided configuration and environment.
 *
 * @param config - The application configuration object.
 * @param currentEnvironment - The current target environment.
 * @returns An array of application navigation routes.
 *
 * @remarks
 * The function dynamically constructs the navigation routes based on the configuration settings and the environment.
 * It includes routes for home, projects, team, and conditionally includes routes for customers, demo, and credentials
 * based on the configuration and environment.
 *
 */
export function getAppNavigationRoutes(
  config: AppConfig,
  currentEnvironment: TargetEnvironment,
): AppNavRoute[] {
  const { enableDemo, enableCredentialsPage } = config;
  const isJhProductionEnvironment = currentEnvironment === "jh-production";
  const enableCustomersPage = !isJhProductionEnvironment;

  const routes: AppNavRoute[] = [];

  routes.push(
    {
      labelKey: "navigation.home",
      name: RouteName.home,
      onClick: () => {
        track(Events.homeTabClicked);
      },
    },
    {
      labelKey: "navigation.projects",
      name: RouteName.projects,
      onClick: () => {
        track(Events.projectsTabClicked);
      },
    },
    ...(enableCustomersPage
      ? [
          {
            labelKey: "navigation.customers",
            name: RouteName.customers,
            onClick: () => {
              track(Events.customersTabClicked);
            },
          },
        ]
      : []),
    {
      labelKey: "navigation.team",
      name: RouteName.team,
      onClick: () => {
        track(Events.teamTabClicked);
      },
    },
  );

  if (enableDemo && !isJhProductionEnvironment) {
    routes.push({
      labelKey: "navigation.demo",
      name: RouteName.demo,
      onClick: () => {
        track(Events.demoTabClicked);
      },
    });
  }

  if (enableCredentialsPage && !isJhProductionEnvironment) {
    routes.push({
      labelKey: "navigation.credentials",
      name: RouteName.credentials,
      onClick: () => {
        track(Events.credentialsTabClicked);
      },
    });
  }

  return routes;
}
