import * as React from "react";
import styled from "styled-components";

import { useI18n } from "@thisisbud/i18n-react";
import { dividerClassName } from "@thisisbud/bui-react-divider";
import { selectFromTheme } from "@thisisbud/sc-utils";
import { Dialog } from "@thisisbud/bui-react-dialog";

import { track } from "../../../../../../client/utils/tracking";
import Events from "../../../../../constants/events";
import ManageProjectDialogEdit from "./edit";
import ManageProjectDialogDelete from "./delete";

import type { Project } from "../../../../../api/types/entities";
import type { Action } from "./types";

type Props = {
  project: Project;
  onClose(): void;
};

const StyledDialog = styled(Dialog)`
  .${dividerClassName} {
    margin: ${selectFromTheme("spacingSmallDefault")} 0;
  }
`;

function renderDialogContent(
  project: Project,
  action: Action,
  onGoToDelete: () => void,
  onEdited: () => void,
  onClose: () => void,
): React.ReactNode {
  if (action === "edit") {
    return (
      <ManageProjectDialogEdit project={project} onDelete={onGoToDelete} onEdited={onEdited} />
    );
  }

  return <ManageProjectDialogDelete project={project} onClose={onClose} />;
}

export default function ManageProjectDialog(props: Props): React.ReactElement {
  const { project, onClose } = props;
  const [action, setAction] = React.useState<Action>("edit");
  const i18n = useI18n();

  const onGoToDelete = React.useCallback(function () {
    track(Events.manageProjectDeleteClicked);

    setAction("delete");
  }, []);

  const onCloseProxy = React.useCallback(() => {
    if (action === "edit") {
      track(Events.manageProjectEditCancelled);
    } else {
      track(Events.manageProjectDeleteCancelled);
    }

    onClose();
  }, [action, onClose]);

  return (
    <StyledDialog
      allowClose={true}
      closeLabel={i18n.t("common.close")}
      id="manage-project-dialog"
      open={true}
      title={i18n.t(`projects.project.manage-project-dialog.${action}.title`)}
      onClose={onCloseProxy}
    >
      {renderDialogContent(project, action, onGoToDelete, onClose, onCloseProxy)}
    </StyledDialog>
  );
}
