import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { State } from "../types";

export type StateSlice = {
  email: string | undefined;
};

const initialState: StateSlice = {
  email: undefined,
};

const stateSlice = createSlice({
  initialState: initialState,
  name: "user",
  reducers: {
    resetUser() {
      return initialState;
    },
    setUserEmail(state: StateSlice, action: PayloadAction<string>) {
      state.email = action.payload;
    },
  },
});

/**
 * Get the user state slice.
 *
 * @param state - The root state of the application
 * @returns The authentication state slice
 */
function getStateSlice(state: State): StateSlice {
  return state[stateSlice.name];
}

/**
 * Get the domain of the current user's email.
 *
 * @param state - The root state of the application
 * @returns The current user's email domain if available
 */
export function getUserEmailDomain(state: State): string | undefined {
  return getStateSlice(state).email?.split("@")[1];
}

export const { name, reducer } = stateSlice;
export const { resetUser, setUserEmail } = stateSlice.actions;
