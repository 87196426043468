import cookie from "js-cookie";

import { base64Encode } from "@thisisbud/base64-utils";
import { HttpError } from "@thisisbud/internal-sdk";

import login from "../../common/api/operations/login";
import refreshOAuthToken from "../../common/api/operations/refresh-oauth-token";
import { companyCookieName, sessionCookieName } from "../../common/constants/cookies";
import { expiredSession, invalidSession } from "../../common/constants/error-codes";
import passwordRecovery from "../../common/api/operations/password-recovery";
import { clearSessionId } from "./session";

import type { AuthDetails, UserLoginDetails } from "../../common/api/types/common";
import type { OAuthToken } from "../../common/api/types/entities";

type SessionCookie = OAuthToken;

const oneMinInMs = 60000;

let onSessionStarted: () => void;
let onSessionEnded: () => void;

/**
 * Set a callback function to be notified when the session starts.
 *
 * @param callback - A function to be called when the session starts
 */
export function setOnSessionStarted(callback: () => void): void {
  onSessionStarted = callback;
}

/**
 * Set a callback function to be notified when the session ends.
 *
 * @param callback - A function to be called when the session ends
 */
export function setOnSessionEnded(callback: () => void): void {
  onSessionEnded = callback;
}

/**
 * Get the current session data.
 *
 * @returns The current session data if available, or `undefined` if not
 */
function getSession(): SessionCookie | undefined {
  const sessionCookie = cookie.get(sessionCookieName);

  return typeof sessionCookie === "string"
    ? (JSON.parse(atob(sessionCookie)) as SessionCookie)
    : undefined;
}

/**
 * Clears the current session.
 */
export function clearCurrentSession(): void {
  cookie.remove(sessionCookieName, {
    domain: location.hostname,
    path: "/",
  });

  clearSessionId();

  cookie.remove(companyCookieName, {
    domain: location.hostname,
    path: "/",
  });

  if (typeof onSessionEnded === "function") {
    onSessionEnded();
  }
}

/**
 * Sets a cookie with the current session.
 *
 * @param session - The data to store in the session cookie
 */
function setSession(session: SessionCookie): void {
  const location = window.location;

  cookie.set(sessionCookieName, base64Encode(JSON.stringify(session)), {
    domain: location.hostname,
    path: "/",
    sameSite: "lax",
    secure: location.protocol === "https:",
  });
}

/**
 * Updates the current session details and notifies event listeners.
 *
 * @param currentSession - The current session details
 */
function updateCurrentSession(currentSession: SessionCookie): void {
  setSession(currentSession);
}

/**
 * Refreshes an existing session by exchanging the refresh token for a new OAuth token object.
 *
 * @returns The authentication details
 */
async function refreshSession(refreshToken: string, userId: string): Promise<AuthDetails> {
  try {
    const auth = await refreshOAuthToken({ refreshToken, userId });

    updateCurrentSession(auth);

    return {
      accessToken: auth.accessToken,
      userId: auth.userId,
    };
  } catch (err) {
    clearCurrentSession();

    if (err instanceof HttpError) {
      throw err;
    }

    throw new HttpError(401, "The session has expired", { code: expiredSession });
  }
}

/**
 * Creates a new session by exchanging the user details for an OAuth token object.
 *
 * @param details - The details of the user to start the session
 * @returns The authentication details
 *
 */
export async function startSession(details: UserLoginDetails): Promise<AuthDetails> {
  try {
    const auth = await login(details);

    setSession(auth);

    if (typeof onSessionStarted === "function") {
      onSessionStarted();
    }

    return {
      accessToken: auth.accessToken,
      userId: auth.userId,
    };
  } catch (err) {
    clearCurrentSession();

    if (err instanceof HttpError) {
      throw err;
    }

    throw new HttpError(401, "Invalid session", { code: invalidSession });
  }
}

/**
 * Require the details of the current session and refresh existing sessions as necessary.
 *
 * @returns The details of the current session
 */
export async function requireAuth(): Promise<AuthDetails> {
  const currentSession = getSession();

  if (typeof currentSession !== "undefined") {
    if (currentSession.expires <= Date.now() - oneMinInMs) {
      return refreshSession(currentSession.refreshToken, currentSession.userId);
    }
    return currentSession;
  }

  throw new HttpError(401, "The session has expired", { code: expiredSession });
}

/**
 * Initiates a password reset flow
 *
 * @returns The email of the account to reset the password
 */
export async function passwordReset(email: string): Promise<void> {
  return passwordRecovery({ email });
}

/**
 * Get the current user status.
 *
 * @returns A boolean indicationg if the user is authenticated or not
 */
export function isAuthenticated(): boolean {
  return Boolean(getSession());
}

/**
 * Get the ID of the current authenticated user.
 *
 * @returns The ID of the current user
 */
export function getUserId(): string | undefined {
  const currentSession = getSession();

  return currentSession?.userId;
}
