import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import type { Props as GdsLinkProps, Link } from "@thisisbud/gds-ui/link";
import type { Button, Props as GdsButtonProps } from "@thisisbud/gds-ui/button";

type LocationState = {
  [propName: string]: any;
};

type ComponentProps = {
  as: typeof Button | typeof Link;
  href: string;
  onClick?(e: React.MouseEvent<HTMLAnchorElement>): void;
};

type ButtonProps = Omit<GdsButtonProps<"button">, "as">;
type AnchorProps = Omit<GdsButtonProps<"a">, "as">;
type LinkProps = Omit<GdsLinkProps<"a">, "as">;

type Props = (ButtonProps | AnchorProps | LinkProps) & ComponentProps;

export default function RouterLink(props: Props): React.ReactElement {
  const { as, href, onClick, ...otherProps } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const onClickHandler = React.useCallback(
    function (e: React.MouseEvent<HTMLAnchorElement>) {
      if (typeof onClick === "function") {
        onClick(e);
      }

      if (e.defaultPrevented) {
        return;
      }

      e.preventDefault();

      const locState = (location.state ?? {}) as LocationState;

      // Set the scroll position of the current page in the history state
      navigate(
        {
          hash: location.hash,
          pathname: location.pathname,
          search: location.search,
        },
        {
          replace: true,
          state: {
            ...locState,
            scrollPos: {
              x: window.scrollX,
              y: window.scrollY,
            },
          },
        },
      );

      navigate(href);
    },
    [location, href, navigate, onClick],
  );

  const Component = as;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...(otherProps as any)} as="a" href={href} onClick={onClickHandler} />;
}
