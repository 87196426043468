import type { LoadableContext } from "@thisisbud/react-loadable";
import type { LocalLoadableCtx } from ".";

export type InitData = {
  projectId: string;
  environment: string;
  projectName?: string;
};

export default async function init(ctx: LoadableContext & LocalLoadableCtx): Promise<InitData> {
  const {
    environment,
    project: { clientId: projectId, name: projectName },
  } = ctx;

  return {
    environment,
    projectId,
    projectName,
  };
}
