import * as React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { selectFromTheme } from "@thisisbud/sc-utils";

import AppHeader from "../app-header";
import AppDemoHeader from "../app-demo-header";
import { useConfig } from "../../context/config";
import { getEnvironment } from "../../store/environment";
import EnvironmentBanner from "../environment-banner";
import { getAuthenticated } from "../../store/auth";
import { useAvailableProductionEnvironment } from "../../hooks/use-available-production-environment";

type Props = {
  children: React.ReactNode;
};

const demoPathRegExp = /\/demo\/.+/;

const StyledMain = styled.main`
  max-width: ${selectFromTheme("breakpointExtraWide")};
  margin: ${selectFromTheme("spacingLargeDefault")} auto;
  padding: 0 ${selectFromTheme("spacingLargeDefault")};

  @media (min-width: calc(${selectFromTheme("breakpointExtraWide")} + ${selectFromTheme(
      "spacingLargeDefault",
    )})) {
    padding: 0;
  }
`;

export default function AppLayout(props: Props): React.ReactElement {
  const { children } = props;
  const { enableEnvironmentSelector } = useConfig();
  const location = useLocation();
  const currentEnvironment = useSelector(getEnvironment);
  const authenticated = useSelector(getAuthenticated);
  const productionEnvironment = useAvailableProductionEnvironment();

  const showDemoHeader = authenticated && demoPathRegExp.test(location.pathname);
  const showProductionBannerWarning =
    enableEnvironmentSelector &&
    authenticated &&
    currentEnvironment === productionEnvironment &&
    !showDemoHeader;

  return (
    <React.Fragment>
      {showProductionBannerWarning ? <EnvironmentBanner /> : null}
      {/** TODO: refactor as both headers are similar in structure */}
      {showDemoHeader ? <AppDemoHeader /> : <AppHeader />}
      <StyledMain>{children}</StyledMain>
    </React.Fragment>
  );
}
