import * as React from "react";
import styled from "styled-components";

import { useI18n } from "@thisisbud/i18n-react";
import { Form } from "@thisisbud/bui-react-form";
import { InputGroup } from "@thisisbud/bui-react-input-group";
import { Input } from "@thisisbud/bui-react-input";
import { Paragraph } from "@thisisbud/bui-react-typography";
import { Notification } from "@thisisbud/bui-react-notification";
import { Button } from "@thisisbud/bui-react-buttons";
import { selectFromTheme } from "@thisisbud/sc-utils";

import {
  deleteProjectDetailListClassName,
  deleteProjectDetailListLabelClassName,
  deleteProjectDetailListValueClassName,
} from "./constants";
import DialogFooter from "../../../../dialog-footer";
import { useOnDeleteProjectSubmit, useOnDeleteProjectValidate } from "./hooks";

import type { Project } from "../../../../../api/types/entities";

type Props = {
  project: Project;
  onClose(): void;
};

const StyledDetailList = styled.dl`
  margin-top: ${selectFromTheme("spacingSmallDefault")};
  margin-bottom: 0;

  .${deleteProjectDetailListValueClassName} {
    margin-top: ${selectFromTheme("spacingXXXXSmallDefault")};
  }

  .${deleteProjectDetailListValueClassName} + .${deleteProjectDetailListLabelClassName} {
    margin-top: ${selectFromTheme("spacingXXXSmallDefault")};
  }

  + * {
    margin-top: ${selectFromTheme("spacingSmallDefault")};
  }
`;

const initialValues = {
  confirm: "",
};

export default function ManageProjectDialogDelete(props: Props): React.ReactElement {
  const { project, onClose } = props;
  const i18n = useI18n();
  const { busy, callback, error } = useOnDeleteProjectSubmit(project);
  const validate = useOnDeleteProjectValidate();

  return (
    <React.Fragment>
      <Paragraph>{i18n.t("projects.project.manage-project-dialog.delete.body")}</Paragraph>
      <StyledDetailList className={deleteProjectDetailListClassName}>
        <Paragraph as="dt" className={deleteProjectDetailListLabelClassName} size="small">
          {i18n.t("projects.project.manage-project-dialog.delete.detail.name")}
        </Paragraph>
        <Paragraph as="dd" className={deleteProjectDetailListValueClassName}>
          <strong>{project.name}</strong>
        </Paragraph>
        <Paragraph as="dt" className={deleteProjectDetailListLabelClassName} size="small">
          {i18n.t("projects.project.manage-project-dialog.delete.detail.client-id")}
        </Paragraph>
        <Paragraph as="dd" className={deleteProjectDetailListValueClassName}>
          <strong>{project.clientId}</strong>
        </Paragraph>
      </StyledDetailList>
      <Form initialValues={initialValues} validate={validate} onSubmit={callback}>
        {typeof error !== "undefined" && (
          <Notification
            id="manage-project-dialog-delete-error"
            label={i18n.t("common.error")}
            type="error"
          >
            {i18n.t("projects.project.manage-project-dialog.delete.form.error.unknown")}
          </Notification>
        )}
        <InputGroup
          label={
            i18n.tToParts("projects.project.manage-project-dialog.delete.form.confirm.label", {
              renderBold: (text: string) => <strong key={text}>{text}</strong>,
            }) as unknown as string
          }
        >
          <Input id="manage-project-dialog-delete-confirm-input" name="confirm" />
        </InputGroup>
        <DialogFooter>
          <Button
            disabled={busy}
            id="manage-project-dialog-delete-cancel-button"
            variant="secondary"
            onClick={onClose}
          >
            {i18n.t("common.cancel")}
          </Button>
          <Button busy={busy} id="manage-project-dialog-delete-submit-button" type="submit">
            {i18n.t("projects.project.manage-project-dialog.delete.form.submit")}
          </Button>
        </DialogFooter>
      </Form>
      <Notification
        id="manage-project-dialog-delete-warning"
        label={i18n.t("common.warning")}
        type="warning"
      >
        {i18n.t("projects.project.manage-project-dialog.delete.notification.warning")}
      </Notification>
    </React.Fragment>
  );
}
