import * as React from "react";
import { useSelector } from "react-redux";

import Loadable from "@thisisbud/react-loadable";

import { getEnvironment } from "../../../store/environment";
import { loadingView } from "../loading";
import init from "./init";

import type { TargetEnvironment } from "../../../types/environment";

export type LocalLoadableCtx = {
  environment: TargetEnvironment;
};

/**
 * Lazily load the component.
 *
 * @returns The imported module
 */
async function loadComponent(): Promise<{ default: React.ComponentType }> {
  return import(/* webpackChunkName: 'views/home' */ "./component");
}

/**
 * Lazily load the component for the Home view.
 */
export default function HomeViewLoader(): React.ReactElement {
  const currentEnvironment = useSelector(getEnvironment);

  const localCtx = React.useMemo(
    function (): LocalLoadableCtx {
      return {
        environment: currentEnvironment,
      };
    },
    [currentEnvironment],
  );

  return (
    <Loadable
      ctx={localCtx}
      fallback={loadingView}
      fallbackDelay={0}
      init={init}
      loadComponent={loadComponent}
    />
  );
}
