import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { track } from "../../../../../client/utils/tracking";
import Events from "../../../../constants/events";
import { getEnvironment } from "../../../../store/environment";
import { createHref } from "../../../../router";
import { RouteName } from "../../../../router/constants";
import ManageProjectDialog from "./manage-project-dialog";
import { useProjectsData } from "../hooks";

import type { ProjectParams } from "./types";
import type { Project } from "../../../../api/types/entities";

/**
 * Use a dialog to manage a project.
 *
 * @param project - The project to manage
 * @returns The dialog component and a function to be called to trigger the dialog
 */
export function useManageProjectDialog(project: Project): {
  dialog: React.ReactNode;
  open(): void;
} {
  const [isOpen, setIsOpen] = React.useState(false);

  const open = React.useCallback(function () {
    track(Events.manageProjectClicked);

    setIsOpen(true);
  }, []);

  const close = React.useCallback(function () {
    setIsOpen(false);
  }, []);

  return {
    dialog: isOpen ? <ManageProjectDialog project={project} onClose={close} /> : null,
    open: open,
  };
}

/**
 * Use the current project based on the URL.
 *
 * @returns The current project
 */
export const useProject = (): Project | undefined => {
  const { clientId } = useParams<keyof ProjectParams>() as ProjectParams;
  const {
    data: { projects },
  } = useProjectsData();

  return React.useMemo(() => {
    return projects.find((p) => clientId === p.clientId);
  }, [clientId, projects]);
};

/**
 * Use a redirect to the projects view if the environment changes.
 */
export const useRedirectOnEnvironmentChange = (): void => {
  const navigate = useNavigate();
  const environment = useSelector(getEnvironment);
  const prevEnvironment = React.useRef(environment);

  React.useEffect(() => {
    if (environment !== prevEnvironment.current) {
      navigate(createHref({ name: RouteName.projects }));
    }
  }, [environment, navigate]);
};
