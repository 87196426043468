import { Version, request } from "@thisisbud/internal-sdk";

import { Namespace } from "../constants";
import { formatPostProjectResponse } from "../formatters/project";

import type { ApiResponse } from "../formatters/project";
import type { AuthDetails } from "../types/common";
import type { CreatedProject } from "../types/entities";

/**
 * Create a new project
 *
 * @param auth - The authentication details for the request
 * @param envType - The environment to get a customer list from
 * @param name - The name of the project to create
 * @returns The created project details
 */
export default async function createProject(
  auth: AuthDetails,
  envType: string,
  name?: string,
): Promise<CreatedProject> {
  const response = await request<ApiResponse>(Version.v1, Namespace.web, {
    auth: auth,
    body: {
      label: name,
    },
    headers: {
      "X-User-Env": envType,
    },
    method: "POST",
    path: "/api-credentials",
  });

  return formatPostProjectResponse(response);
}
