import type { SortParams } from "../../../../../types/sorting";
import type { Subscription } from "./api";

export const allowedWebhooksSortKeys: readonly (keyof Subscription)[] = [
  "lastModifiedAt",
  "eventType",
  "endpoint",
] as const;

export const defaultWebhooksSortParams: SortParams<(typeof allowedWebhooksSortKeys)[number]> = {
  sortDirection: "ascending",
  sortedBy: "eventType",
};
