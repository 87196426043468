declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Navigator {
    msSaveBlob?(blob: any, defaultName?: string): boolean;
  }
}

/**
 * Create a downloadble JSON file from an Object
 *
 * @param data - The data to download as JSON
 * @param fileName - The file name
 */
export const downloadJson = (
  data: {
    [key: string]: any;
  },
  fileName: string,
): void => {
  const dataAsJson = JSON.stringify(data);
  const blob = new Blob([dataAsJson], { type: "application/json" });

  // IE 11
  if (window.navigator.msSaveBlob) {
    window.navigator.msSaveBlob(blob, fileName);
  } else {
    const element = document.createElement("a");
    const url = window.URL.createObjectURL(blob);
    element.href = url;
    element.download = `${fileName}.json`;
    document.body.appendChild(element);
    element.click();

    document.body.removeChild(element);
    window.URL.revokeObjectURL(url);
  }
};
