import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useToastNotifications } from "@thisisbud/bui-react-toast-notifications";
import { useI18n } from "@thisisbud/i18n-react";

import {
  createSubscription,
  deleteSubscription,
  sendTestEvent,
  toggleSubscriptionStatus,
} from "./api";
import { getEnvironment } from "../../../../../../store/environment";
import { TestEventNotification } from "../single-webhook/test-event-notification";
import { WebhookQueryKeys } from "./constants";
import { createHref } from "../../../../../../router";
import { RouteName } from "../../../../../../router/constants";

import type {
  CreateSubscriptionMutationFn,
  DeleteSubscriptionMutationFn,
  SubscriptionCategory,
} from "./types";
import type { TargetEnvironment } from "../../../../../../types/environment";
import type { UseMutationResult } from "@tanstack/react-query";
import type { ProjectParams } from "../../types";

type MutationFnArgs = {
  webhookId: string;
  action: "activate" | "deactivate";
  category: SubscriptionCategory;
};

type TestEventMutationFnArgs = {
  category?: SubscriptionCategory;
};

export const useToggleWebhookStatus = (
  environment: TargetEnvironment,
  projectId: string,
): UseMutationResult<unknown, unknown, MutationFnArgs> => {
  const queryClient = useQueryClient();
  const toastNotifications = useToastNotifications();
  const { t } = useI18n();

  return useMutation<unknown, unknown, MutationFnArgs>({
    mutationFn: async ({ webhookId, action, category }): Promise<void> => {
      await toggleSubscriptionStatus({
        action: action,
        applicationId: projectId,
        category: category,
        currentEnvironment: environment,
        eventType: webhookId,
      });
    },
    // eslint-disable-next-line no-underscore-dangle
    onSettled: (_data, _error, { webhookId, action }) => {
      toastNotifications.push({
        label: t("common.action-complete-msg-label"),
        message: t(`projects.single-webhook.status.${action}-success`, {
          name: webhookId,
        }),
        type: "info",
      });
      void queryClient.invalidateQueries([WebhookQueryKeys.projects, { projectId }]);
      void queryClient.invalidateQueries([WebhookQueryKeys.webhooks, { projectId }]);
      void queryClient.invalidateQueries([WebhookQueryKeys.subscribedEvents]);
    },
  });
};

export const useSendTestEvent = (
  projectId: string,
  webhookId: string,
): UseMutationResult<unknown, unknown, TestEventMutationFnArgs> => {
  const queryClient = useQueryClient();
  const currentEnvironment = useSelector(getEnvironment);
  const toastNotifications = useToastNotifications();
  const { t } = useI18n();

  return useMutation<unknown, unknown, TestEventMutationFnArgs>({
    mutationFn: async ({ category }) => {
      return sendTestEvent({
        applicationId: projectId,
        category: category ?? "open-banking",
        currentEnvironment: currentEnvironment,
        eventType: webhookId,
      });
    },
    onError: () => {
      toastNotifications.push({
        label: t("common.action-complete-msg-label"),
        message: t("projects.single-webhook.details.send-event-error"),
        type: "warning",
      });
    },
    onSuccess: () => {
      toastNotifications.push({
        label: t("common.action-complete-msg-label"),
        message: <TestEventNotification clientId={projectId} webhookId={webhookId} />,
        type: "info",
      });
      void queryClient.invalidateQueries([WebhookQueryKeys.webhookEventHistory]);
    },
  });
};

export const useCreateSubscription = (
  onClose: () => void,
): UseMutationResult<unknown, unknown, CreateSubscriptionMutationFn> => {
  const queryClient = useQueryClient();
  const currentEnvironment = useSelector(getEnvironment);
  const { clientId: projectId } = useParams<keyof ProjectParams>() as ProjectParams;
  const toastNotifications = useToastNotifications();
  const { t } = useI18n();

  return useMutation<unknown, unknown, CreateSubscriptionMutationFn>({
    mutationFn: async ({ webhookUrl, eventType, optionalSettings, category }): Promise<void> => {
      await createSubscription({
        applicationId: projectId,
        category: category,
        currentEnvironment: currentEnvironment,
        eventType: eventType,
        optionalSettings: optionalSettings,
        webhookUrl: webhookUrl,
      });
    },
    onError: () => {
      toastNotifications.push({
        label: t("common.action-complete-msg-label"),
        message: t("error.generic.body"),
        type: "error",
      });
    },
    // eslint-disable-next-line no-underscore-dangle
    onSuccess: (_data, { eventType }) => {
      onClose();
      toastNotifications.push({
        label: t("common.action-complete-msg-label"),
        message: t("projects.single-webhook.edit-dialog.success", {
          event: eventType,
        }),
        type: "success",
      });
      void queryClient.invalidateQueries([WebhookQueryKeys.projects, { projectId }]);
      void queryClient.invalidateQueries([WebhookQueryKeys.webhooks, { projectId }]);
      void queryClient.invalidateQueries([WebhookQueryKeys.subscribedEvents]);
    },
  });
};

export const useDeleteSubscription = (
  onClose: () => void,
): UseMutationResult<unknown, unknown, DeleteSubscriptionMutationFn> => {
  const queryClient = useQueryClient();
  const currentEnvironment = useSelector(getEnvironment);
  const { clientId: projectId } = useParams<keyof ProjectParams>() as ProjectParams;
  const toastNotifications = useToastNotifications();
  const { t } = useI18n();
  const navigate = useNavigate();

  return useMutation<unknown, unknown, DeleteSubscriptionMutationFn>({
    mutationFn: async ({ eventType, category }): Promise<void> => {
      await deleteSubscription({
        applicationId: projectId,
        category: category,
        currentEnvironment: currentEnvironment,
        eventType: eventType,
      });
    },
    onError: () => {
      toastNotifications.push({
        label: t("common.action-complete-msg-label"),
        message: t("error.generic.body"),
        type: "error",
      });
    },
    // eslint-disable-next-line no-underscore-dangle
    onSuccess: (_data, { eventType }) => {
      onClose();
      toastNotifications.push({
        label: t("common.action-complete-msg-label"),
        message: t("projects.single-webhook.remove-dialog.success", {
          event: eventType,
        }),
        type: "success",
      });
      void queryClient.invalidateQueries([WebhookQueryKeys.projects, { projectId }]);
      void queryClient.invalidateQueries([WebhookQueryKeys.webhooks, { projectId }]);
      void queryClient.invalidateQueries([WebhookQueryKeys.subscribedEvents]);

      navigate(
        createHref({
          name: RouteName.projectWebhooks,
          params: { clientId: projectId },
        }),
      );
    },
  });
};
