import * as React from "react";
import { useSelector } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { useToastNotifications } from "@thisisbud/bui-react-toast-notifications";
import { useI18n } from "@thisisbud/i18n-react";

import { requireAuth } from "../../../../../../client/utils/auth";
import { track } from "../../../../../../client/utils/tracking";
import Events from "../../../../../constants/events";
import deleteProject from "../../../../../api/operations/projects/delete-project";
import updateProject from "../../../../../api/operations/projects/update-project";
import { getEnvironment } from "../../../../../store/environment";
import { deleteConfimationInputValue } from "./constants";
import { RouteName } from "../../../../../router/constants";
import { createHref } from "../../../../../router";

import type { FormikErrors } from "formik";
import type { Project } from "../../../../../api/types/entities";
import type { DeleteFormValues, EditFormValues } from "./types";

type ManageStatus = {
  busy: boolean;
  error?: Error;
};

/**
 * Use an edit project on submit callback function with a busy and error state.
 *
 * @param projectClientId - The client ID of the project
 * @param onEdited - A function to called once a project has been edited
 * @returns A submit callback function with a busy and error state
 */
export function useOnEditProjectSubmit(
  projectClientId: string,
  onEdited: () => void,
): ManageStatus & { callback(values: EditFormValues): Promise<void> } {
  const environment = useSelector(getEnvironment);
  const queryClient = useQueryClient();
  const i18n = useI18n();
  const toastNotifications = useToastNotifications();
  const [status, setStatus] = React.useState<ManageStatus>({ busy: false, error: undefined });

  const callback = React.useCallback(
    async function (values: EditFormValues) {
      track(Events.manageProjectEditSubmitted);

      setStatus({
        busy: true,
        error: undefined,
      });

      try {
        const auth = await requireAuth();

        await updateProject(auth, environment, projectClientId, values);

        // TODO: use key factories for providing query keys. See https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
        await queryClient.invalidateQueries({ queryKey: ["projects"] });

        toastNotifications.push({
          label: i18n.t("common.action-complete-msg-label"),
          message: i18n.t("projects.project.manage-project-dialog.edit.success-notification", {
            name: values.name,
          }),
          type: "success",
        });

        onEdited();
      } catch (err) {
        track(Events.manageProjectEditFailed);

        setStatus({
          busy: false,
          error: err,
        });
      }
    },
    [setStatus],
  );

  return {
    ...status,
    callback,
  };
}

/**
 * Use a delete project on submit callback function with a busy and error state.
 *
 * @param projectClientId - The client ID of the project
 * @param onClose - A function to call on close of the dialog
 * @returns A submit callback function with a busy and error state
 */
export function useOnDeleteProjectSubmit(
  project: Project,
): ManageStatus & { callback(values: EditFormValues): Promise<void> } {
  const environment = useSelector(getEnvironment);
  const queryClient = useQueryClient();
  const i18n = useI18n();
  const toastNotifications = useToastNotifications();
  const [status, setStatus] = React.useState<ManageStatus>({ busy: false, error: undefined });
  const navigate = useNavigate();

  const callback = React.useCallback(
    async function () {
      track(Events.manageProjectDeleteSubmitted);

      setStatus({
        busy: true,
        error: undefined,
      });

      try {
        const auth = await requireAuth();

        await deleteProject(auth, environment, project.clientId);

        navigate(createHref({ name: RouteName.projects }));

        // TODO: use key factories for providing query keys. See https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
        await queryClient.invalidateQueries({ queryKey: ["projects"] });

        toastNotifications.push({
          label: i18n.t("common.action-complete-msg-label"),
          message: i18n.t("projects.project.manage-project-dialog.delete.success-notification", {
            name: project.name,
          }),
          type: "info",
        });
      } catch (err) {
        track(Events.manageProjectDeleteFailed);

        setStatus({
          busy: false,
          error: err,
        });
      }
    },
    [setStatus],
  );

  return {
    ...status,
    callback,
  };
}

/**
 * Use a callback function to validate the values of the delete project form.
 *
 * @returns A callback function to validate the values of the delete project form
 */
export function useOnDeleteProjectValidate(): (
  values: DeleteFormValues,
) => FormikErrors<DeleteFormValues> {
  const i18n = useI18n();

  return React.useCallback(
    function (values: DeleteFormValues) {
      const { confirm } = values;
      const output: FormikErrors<DeleteFormValues> = {};

      if (confirm.toUpperCase() !== deleteConfimationInputValue) {
        output.confirm = i18n.t(
          "projects.project.manage-project-dialog.delete.form.confirm.validation.invalid",
        );
      }

      return output;
    },
    [i18n],
  );
}

/**
 * Use a callback function to validate the values  of the edit project form.
 *
 * @returns A callback function to validate the values of the edit project form
 */
export function useOnEditProjectValidate(): (
  values: EditFormValues,
) => FormikErrors<EditFormValues> {
  const i18n = useI18n();

  return React.useCallback(
    function (values: EditFormValues) {
      const { name } = values;
      const output: FormikErrors<EditFormValues> = {};

      const normalizedName = name.trim();

      if (normalizedName === "") {
        output.name = i18n.t(
          "projects.project.manage-project-dialog.edit.form.name.validation.required",
        );
      }

      return output;
    },
    [i18n],
  );
}
