import type { GenericResponse } from "@thisisbud/internal-sdk";
import type { CreatedProject } from "../types/responses";
import type { CreatedProject as CreatedProjectEntity } from "../types/entities";

export type ApiResponse = GenericResponse<CreatedProject>;

/**
 * Formats a POST Project API response.
 *
 * @param res - The response received from the project endpoint
 * @returns A created project entity
 * @public
 */
export function formatPostProjectResponse(response: ApiResponse): CreatedProjectEntity {
  const { data } = response;

  return {
    clientId: data.client_id,
    clientSecret: data.client_secret,
    name: data.label.length ? data.label : undefined,
    raw: data,
  };
}
