import * as React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Loadable from "@thisisbud/react-loadable";
import { HttpError } from "@thisisbud/internal-sdk";

import { loadingView } from "../../../../loading";
import { getEnvironment } from "../../../../../../store/environment";
import { useProject } from "../../hooks";
import { pageNotFound } from "../../../../../../constants/error-codes";
import CustomInsightsRouteGuard from "../route-guard";
import init from "./init";

import type { Project } from "../../../../../../api/types/entities";

export type LocalLoadableCtx = {
  insightId?: string;
  environment: string;
  project: Project;
};

/**
 * Lazily load the component.
 *
 * @returns The imported module
 */
async function loadComponent(): Promise<{ default: React.ComponentType }> {
  return import(/* webpackChunkName: 'views/projects/project/insights' */ "./component");
}

/**
 * Lazily load the component and initialize data for the project insights edit view.
 */
export default function InsightsEditViewLoader(): React.ReactElement {
  const currentEnvironment = useSelector(getEnvironment);
  const { insightId } = useParams();

  const project = useProject();
  if (typeof project === "undefined") {
    throw new HttpError(404, "Project not found", {
      code: pageNotFound,
    });
  }

  const localCtx = React.useMemo(
    function (): LocalLoadableCtx {
      return {
        environment: currentEnvironment,
        insightId: insightId,
        project: project,
      };
    },
    [insightId, currentEnvironment, project],
  );

  return (
    <React.Fragment>
      <CustomInsightsRouteGuard />
      <Loadable
        ctx={localCtx}
        fallback={loadingView}
        fallbackDelay={0}
        init={init}
        loadComponent={loadComponent}
      />
    </React.Fragment>
  );
}
