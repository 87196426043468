import { useSelector } from "react-redux";

import { productionEnvironments } from "../constants/environment";
import { getAvailableEnvironments } from "../store/environment";

import type { ProductionTargetEnvironment } from "../types/environment";

type AvailableProductionEnvrionment = ProductionTargetEnvironment | undefined;

/**
 * Use the available known production environment.
 *
 * @returns The known available production environment or undefined
 */
export function useAvailableProductionEnvironment(): AvailableProductionEnvrionment {
  const environments = useSelector(getAvailableEnvironments);

  const productionEnvironment = environments.find((environment) =>
    productionEnvironments.includes(environment as ProductionTargetEnvironment),
  );

  return productionEnvironment as AvailableProductionEnvrionment;
}
