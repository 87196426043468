import React from "react";
import { useSearchParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";

import { Link } from "@thisisbud/gds-ui/link";
import { useI18n } from "@thisisbud/i18n-react";

import { useProject } from "../../hooks";
import RouterLink from "../../../../../router-link";
import { createHref } from "../../../../../../router";
import { RouteName } from "../../../../../../router/constants";
import { useGetWebhookEvents, webhookEventsPageSize } from "../api";
import { EventStatusPill } from "../single-event/event-status-pill";
import { formatEventDate } from "../utils";
import { calculateActiveFiltersAmount, eventFilterReducer, initialFilterState } from "./utils";
import { useConfig } from "../../../../../../context/config";

import type { SubscriptionCategory } from "../api";
import type { FilterState, UseEventFilterDialog, UseReplayEventsDialog } from "./types";
import type { TableColumn } from "../../types";

/**
 * Use the configuration for the table's columns.
 *
 * @returns The table column configuration
 */
export function useEventHistoryTableColumns(): TableColumn[] {
  const { t } = useI18n();

  return React.useMemo(
    function () {
      return [
        { key: "event-name", title: t("projects.event-history.id") },
        { key: "last-modified", title: t("projects.event-history.event-name") },
        { key: "url", title: t("projects.event-history.request-id") },
        { key: "customer-id", title: t("projects.event-history.customer-id") },
        { key: "status", title: t("projects.event-history.status") },
        { key: "timestamp", title: t("projects.event-history.timestamp") },
      ];
    },
    [t],
  );
}

/**
 * Use the configuration for the table data.
 *
 * @param events - The events list
 * @param options - The table data options
 * @returns The table data configuration
 */

// Disabling the rule to type the return type of the function and letting it infer the type instead

export function useEventHistoryTableData(filters: FilterState) {
  const project = useProject();
  const { defaultLocale } = useConfig();
  const category: SubscriptionCategory = filters.showPaymentEvents ? "payments" : "open-banking";
  const { data, isLoading, fetchNextPage, isFetching, hasNextPage } = useGetWebhookEvents({
    category: category,
    filters: filters,
    pageSize: webhookEventsPageSize,
  });
  const { ref, inView } = useInView({ root: null, rootMargin: "0px", threshold: 1 });
  const { t } = useI18n();

  const tableDataCells = React.useMemo(
    function () {
      if (!Boolean(data?.pages[0]?.events.length) || !data?.pages) {
        return [
          [
            {
              content: t("projects.event-history.empty-list"),
              span: 6,
            },
          ],
        ];
      }

      return data.pages.flatMap((group) => {
        return group.events.map(
          ({ id, requestId, eventType, status, createdAt, customerId, isTestEvent }, index) => {
            return [
              <RouterLink
                as={Link}
                href={createHref({
                  name:
                    category === "open-banking"
                      ? RouteName.projectSingleEvent
                      : RouteName.projectSinglePaymentEvent,
                  params: {
                    clientId: project?.clientId,
                    eventId: id,
                  },
                })}
                key={id}
              >
                {id}
              </RouterLink>,
              <span
                key="event-history-event-name"
                ref={group.events.length === index + 1 ? ref : undefined}
              >
                {eventType}
              </span>,
              <span key="event-history-request-id">{requestId}</span>,
              <span key="event-history-customer-id">{customerId}</span>,
              <span key="event-history-event-status">
                <EventStatusPill isTestEvent={isTestEvent} status={status} />
              </span>,
              <span key="event-history-event-timestamp">
                {formatEventDate(createdAt, defaultLocale)}
              </span>,
            ];
          },
        );
      });
    },
    [category, data?.pages, defaultLocale, project?.clientId, ref, t],
  );

  return {
    data: tableDataCells,
    fetchNextPage: fetchNextPage,
    hasNextPage: hasNextPage,
    inView: inView,
    isFetching: isFetching,
    isLoading: isLoading,
  };
}

/**
 * Custom hook for managing the event filter dialog and state.
 * @returns An object containing the necessary functions and state for the event filter dialog.
 */
export const useEventFilterDialog = (): UseEventFilterDialog => {
  const [isEventFilterDialogOpen, setEventFilterDialogOpen] = React.useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, dispatch] = React.useReducer(eventFilterReducer, initialFilterState);

  const openEventFilterDialog = React.useCallback(() => {
    setEventFilterDialogOpen(true);
  }, []);

  const closeEventFilterDialog = React.useCallback(() => {
    setEventFilterDialogOpen(false);
  }, []);

  const handleResetFilters = React.useCallback(() => {
    dispatch({ type: "reset" });
  }, [dispatch]);

  const handleSetSentEvents = React.useCallback(
    (isChecked: boolean) => {
      dispatch({ payload: isChecked, type: "setShowSentEvents" });
    },
    [dispatch],
  );

  const handleSetFailedEvents = React.useCallback(
    (isChecked: boolean) => {
      dispatch({ payload: isChecked, type: "setShowFailedEvents" });
    },
    [dispatch],
  );

  const handleSetRetryingEvents = React.useCallback(
    (isChecked: boolean) => {
      dispatch({ payload: isChecked, type: "setShowRetryingEvents" });
    },
    [dispatch],
  );

  const handleSetCreatedEvents = React.useCallback(
    (isChecked: boolean) => {
      dispatch({ payload: isChecked, type: "setShowCreatedEvents" });
    },
    [dispatch],
  );

  const handleSetShowPaymentEvents = React.useCallback(
    (isChecked: boolean) => {
      dispatch({ payload: isChecked, type: "setShowPaymentEvents" });
    },
    [dispatch],
  );

  const handleSetDateFrom = React.useCallback(
    (payload: string) => {
      dispatch({ payload: payload, type: "setDateFrom" });
    },
    [dispatch],
  );

  const handleSetDateTo = React.useCallback(
    (payload: string) => {
      dispatch({ payload: payload, type: "setDateTo" });
    },
    [dispatch],
  );

  const activeFiltersAmount = React.useMemo(() => {
    return calculateActiveFiltersAmount(filters);
  }, [filters]);

  const onSubmit = React.useCallback(
    (data: FilterState) => {
      searchParams.set("page", "1");
      handleSetSentEvents(data.showSentEvents);
      handleSetFailedEvents(data.showFailedEvents);
      handleSetRetryingEvents(data.showRetryingEvents);
      handleSetCreatedEvents(data.showCreatedEvents);
      handleSetShowPaymentEvents(data.showPaymentEvents);
      handleSetDateFrom(data.from);
      handleSetDateTo(data.to);
      closeEventFilterDialog();
      setSearchParams(searchParams);
    },
    [
      closeEventFilterDialog,
      handleSetCreatedEvents,
      handleSetDateFrom,
      handleSetDateTo,
      handleSetFailedEvents,
      handleSetRetryingEvents,
      handleSetSentEvents,
      handleSetShowPaymentEvents,
      setSearchParams,
      searchParams,
    ],
  );

  const actions = {
    handleResetFilters,
    handleSetCreatedEvents,
    handleSetDateFrom,
    handleSetDateTo,
    handleSetFailedEvents,
    handleSetRetryingEvents,
    handleSetSentEvents,
    handleSetShowPaymentEvents,
    onSubmit,
  };

  return {
    actions,
    activeFiltersAmount,
    closeEventFilterDialog,
    filters,
    isEventFilterDialogOpen,
    openEventFilterDialog,
  };
};

export const useReplayEventsDialog = (): UseReplayEventsDialog => {
  const [isReplayEventsDialogOpen, setReplayEventsDialogOpen] = React.useState(false);

  const openReplayEventsDialog = React.useCallback(() => {
    setReplayEventsDialogOpen(true);
  }, []);

  const closeReplayEventsDialog = React.useCallback(() => {
    setReplayEventsDialogOpen(false);
  }, []);

  return {
    closeReplayEventsDialog,
    isReplayEventsDialogOpen,
    openReplayEventsDialog,
  };
};
