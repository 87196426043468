import * as React from "react";

import { useI18n } from "@thisisbud/i18n-react";
import { Form } from "@thisisbud/bui-react-form";
import { Paragraph } from "@thisisbud/bui-react-typography";
import { InputGroup } from "@thisisbud/bui-react-input-group";
import { Input } from "@thisisbud/bui-react-input";
import { Button } from "@thisisbud/bui-react-buttons";
import { Divider } from "@thisisbud/bui-react-divider";
import { Notification } from "@thisisbud/bui-react-notification";

import DialogFooter from "../../../../dialog-footer";
import { useOnEditProjectSubmit, useOnEditProjectValidate } from "./hooks";

import type { EditFormValues } from "./types";
import type { Project } from "../../../../../api/types/entities";

type Props = {
  project: Project;
  onDelete(): void;
  onEdited(): void;
};

export default function ManageProjectDialogEdit(props: Props): React.ReactElement {
  const { project, onDelete, onEdited } = props;
  const i18n = useI18n();
  const { busy, callback, error } = useOnEditProjectSubmit(project.clientId, onEdited);
  const validate = useOnEditProjectValidate();

  const initialValues: EditFormValues = React.useMemo(
    function () {
      return {
        name: project.name ?? "",
      };
    },
    [project.name],
  );

  return (
    <React.Fragment>
      <Paragraph>{i18n.t("projects.project.manage-project-dialog.edit.body")}</Paragraph>
      <Form initialValues={initialValues} validate={validate} onSubmit={callback}>
        {typeof error !== "undefined" && (
          <Notification
            id="manage-project-dialog-edit-error"
            label={i18n.t("common.error")}
            type="error"
          >
            {i18n.t("projects.project.manage-project-dialog.edit.form.error.unknown")}
          </Notification>
        )}
        <InputGroup label={i18n.t("projects.project.manage-project-dialog.edit.form.name.label")}>
          <Input id="manage-project-dialog-name-input" name="name" required={true} />
        </InputGroup>
        <DialogFooter>
          <Button busy={busy} id="manage-project-dialog-confirm-button" type="submit">
            {i18n.t("projects.project.manage-project-dialog.edit.form.submit")}
          </Button>
        </DialogFooter>
      </Form>
      <Divider />
      <Paragraph>{i18n.t("projects.project.manage-project-dialog.edit.footer.body")}</Paragraph>
      <DialogFooter>
        <Button id="manage-project-dialog-delete-button" variant="destructive" onClick={onDelete}>
          {i18n.t("projects.project.manage-project-dialog.edit.footer.delete-project")}
        </Button>
      </DialogFooter>
    </React.Fragment>
  );
}
