export const basePath =
  process.env.RUNTIME_ENV === "client"
    ? (document.documentElement.getAttribute("data-base-path") as string)
    : (process.env.BASE_PATH as string);

export const staticPath = "/static";

export const loginPath = "/login";

export const i18nPath = "/i18n";

export const hubspotRouterPath = "/hubspot";

export const demoRouterPath = "/demo-project";

export const signupOptionsPath = "/signup-options";

export const demoAuthorizePath = "/demo/authorize";
