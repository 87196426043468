import { getCompareFn } from "../../../../utils/i18n";

import type I18n from "@thisisbud/i18n";
import type { Project } from "../../../../api/types/entities";
import type { SortDirection } from "../../../../types/sorting";

/**
 * Map a column by which projects are being sorted to a project data property.
 *
 * @param sortedBy - The column by which projects are being sorted
 * @returns A project property corresponding to the column being sorted
 */
function getSortedByMapping(sortedBy: string): keyof Project {
  switch (sortedBy) {
    case "client-id":
      return "clientId";
    case "created-at":
      return "createdAt";
    default:
      return "name";
  }
}

/**
 * Sort a list of projects using a given sort direction and column.
 *
 * @param i18n - The i18n instance
 * @param projects - The list of projects to sort
 * @param sortDirection - The sort direction by which projects are to be sorted
 * @param sortedBy - The column by which projects are to be sorted
 * @returns A list of projects sorted by the given direction and property
 */
export function sortProjects(
  i18n: I18n,
  projects: Project[],
  sortDirection: SortDirection,
  sortedBy: string,
): Project[] {
  const compareFn = getCompareFn(i18n);
  const mappedSortBy = getSortedByMapping(sortedBy);

  return [...projects].sort(function (a, b) {
    const propertyA = a[mappedSortBy] ?? "";
    const propertyB = b[mappedSortBy] ?? "";

    if (typeof propertyA === "string" && typeof propertyB === "string") {
      const result = compareFn(propertyA, propertyB);

      if (result !== 0) {
        return result * (sortDirection === "ascending" ? 1 : -1);
      }
    }

    if (sortedBy === "created-at") {
      if (propertyA > propertyB) {
        return sortDirection === "ascending" ? 1 : -1;
      } else if (propertyA < propertyB) {
        return sortDirection === "ascending" ? -1 : 1;
      }
    }

    return 0;
  });
}
