import { parseDate } from "@thisisbud/datetime-utils";

import type { GenericResponse } from "@thisisbud/internal-sdk";
import type { CustomInsight } from "../types/responses";
import type { CustomInsight as CustomInsightEntity } from "../types/entities";

export type ApiResponse = GenericResponse<CustomInsight[] | null>;

/**
 * Formats a GET Custom Insights API response.
 *
 * @param res - The response received from the custom insights endpoint
 * @returns A list of custom insights for the user
 * @public
 */
export function formatGetCustomInsightsResponse(response: ApiResponse): CustomInsightEntity[] {
  const { data } = response;

  if (data === null || data.length === 0) {
    return [];
  }

  return data.map(function (insight) {
    return {
      active: Boolean(insight.active),
      createdAt: parseDate(insight.created_at),
      id: insight.custom_insight_id,
      identifier: insight.identifier,
      query: insight.query,
    };
  });
}
