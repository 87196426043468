import * as React from "react";

import { allowedSortDirections, sortDirectionParam, sortedByParam } from "../constants/sorting";
import { useSetLocationParams } from "./use-set-location-params";
import { useQuery } from "./use-query";

import type { SortDirection, SortParams } from "../types/sorting";

/**
 * Use a callback function to return sort params.
 *
 * @returns A callback function to handle changes to the table sort direction
 */
export function useSorting<TValue extends string>(
  allowedSortKeys: readonly TValue[],
  defaultSortParams: SortParams<TValue>,
): {
  sortedBy: TValue;
  sortDirection: SortDirection;
  onSortChange(event: React.MouseEvent<HTMLButtonElement>): void;
} {
  const query = useQuery();
  const setLocationParams = useSetLocationParams();

  const onSortChange = React.useCallback(
    function (event: React.MouseEvent<HTMLButtonElement>) {
      const {
        currentTarget: { name: sortedBy, value: sortDirection },
      } = event;
      setLocationParams({ [sortDirectionParam]: sortDirection, [sortedByParam]: sortedBy });
    },
    [setLocationParams],
  );

  const sortDirection = React.useMemo(
    function () {
      const nextSortDirection = query[sortDirectionParam] as SortDirection;
      return allowedSortDirections.includes(nextSortDirection)
        ? nextSortDirection
        : defaultSortParams.sortDirection;
    },
    [query[sortDirectionParam]],
  );

  const sortedBy = React.useMemo(
    function () {
      const nextSortedBy = query[sortedByParam] as TValue;
      return allowedSortKeys.includes(nextSortedBy) ? nextSortedBy : defaultSortParams.sortedBy;
    },
    [allowedSortKeys, query[sortedByParam]],
  );

  return {
    onSortChange,
    sortDirection,
    sortedBy,
  };
}
