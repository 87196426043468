import { HttpError } from "@thisisbud/internal-sdk";

import { getEventById } from "../api";
import { pageNotFound } from "../../../../../../constants/error-codes";

import type { SubscriptionCategory, WebhookEvent } from "../api";
import type { LoadableContext } from "@thisisbud/react-loadable";
import type { LocalLoadableCtx } from ".";

export type InitData = {
  currentEnvironment: string;
  projectId: string;
  eventId: string;
  category: SubscriptionCategory;
  singleEvent: WebhookEvent;
};

export async function initSingleEvent(ctx: LoadableContext & LocalLoadableCtx): Promise<InitData> {
  const { eventId, environment: currentEnvironment, clientId: applicationId } = ctx;
  const singleEvent = await getEventById({
    applicationId: applicationId,
    category: "open-banking",
    currentEnvironment: currentEnvironment,
    id: eventId,
  });

  if (typeof singleEvent === "undefined") {
    throw new HttpError(404, "Request not found", {
      code: pageNotFound,
    });
  }

  return {
    category: "open-banking",
    currentEnvironment: currentEnvironment,
    eventId: eventId,
    projectId: applicationId,
    singleEvent: singleEvent,
  };
}

export async function initSinglePaymentEvent(
  ctx: LoadableContext & LocalLoadableCtx,
): Promise<InitData> {
  const { eventId, environment: currentEnvironment, clientId: applicationId } = ctx;
  const singleEvent = await getEventById({
    applicationId: applicationId,
    category: "payments",
    currentEnvironment: currentEnvironment,
    id: eventId,
  });

  if (typeof singleEvent === "undefined") {
    throw new HttpError(404, "Request not found", {
      code: pageNotFound,
    });
  }

  return {
    category: "payments",
    currentEnvironment: currentEnvironment,
    eventId: eventId,
    projectId: applicationId,
    singleEvent: singleEvent,
  };
}
