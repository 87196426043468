import * as React from "react";
import styled, { keyframes } from "styled-components";

import { selectFromTheme } from "@thisisbud/sc-utils";
import { visuallyHiddenCss } from "@thisisbud/bui-react-visually-hidden";

type Props = {
  id: string;
  label: string;
};

const strokeOffsetanimation = keyframes`
  100% {
    stroke-dashoffset: 0;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  label {
    ${visuallyHiddenCss}
  }
`;

const StyledSvg = styled.svg`
  height: ${selectFromTheme("sizeSpinnerDefault")};
  width: ${selectFromTheme("sizeSpinnerDefault")};

  path {
    stroke-dasharray: 462;
    stroke-dashoffset: 460;
    animation-direction: alternate;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: ${strokeOffsetanimation};
    animation-timing-function: ease-in-out;
  }
`;

export default function BudLoadingIndicator(props: Props): React.ReactElement {
  const { id, label } = props;

  const labelId = `${id}-label`;

  return (
    <StyledDiv>
      <StyledSvg
        aria-labelledby={labelId}
        fill="none"
        id={id}
        role="progressbar"
        viewBox="0 0 112 48"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.32933 4.15311L108.58 4.15311V17.6214H3.32933L3.32933 31.0414H108.58V44.44H3.32933"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="6.62"
        />
      </StyledSvg>
      <label id={labelId}>{label}</label>
    </StyledDiv>
  );
}
