import { parseDate } from "@thisisbud/datetime-utils";

import type { Customer, CustomerData, CustomerResponse, GetCustomersResponse } from "./types";

export const formatCustomer = (customer: CustomerResponse): Customer => {
  const createdAt = customer.created_at;

  return {
    // legacy customers don't always have created at values
    createdAt:
      typeof createdAt === "string" && createdAt !== ""
        ? parseDate(customer.created_at)
        : undefined,
    id: customer.customer_id,
  };
};

/**
 * Formats a GET Customers API response.
 *
 * @param response - The response received from the customers endpoint
 * @param totalAmount - The total amount of customers
 * @returns A list of customers with a total amount
 * @public
 */
export function formatGetCustomersResponse(
  response: GetCustomersResponse,
  totalAmount: number,
): CustomerData {
  const { data } = response;

  return {
    // Additional safeguard if null or undefined is returned instead of empty array
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    customers: data?.map(formatCustomer),
    totalAmount: totalAmount,
  };
}
