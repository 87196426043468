import getUserInsights from "../../../../../../api/operations/get-user-insights";
import { requireAuth } from "../../../../../../../client/utils/auth";

import type { LoadableContext } from "@thisisbud/react-loadable";
import type { LocalLoadableCtx } from ".";
import type { UserInsight } from "../../../../../../api/types/entities";

export type InitData = {
  insights: UserInsight[];
  projectId: string;
  environment: string;
  projectName?: string;
};

export default async function init(ctx: LoadableContext & LocalLoadableCtx): Promise<InitData> {
  const {
    availableEnvironments,
    environment,
    project: { clientId: projectId, name: projectName },
  } = ctx;

  const auth = await requireAuth();

  const insights = await getUserInsights({
    auth: auth,
    environments: availableEnvironments,
  });

  return {
    environment,
    insights,
    projectId,
    projectName,
  };
}
