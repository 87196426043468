import React from "react";

import { Notification } from "@thisisbud/gds-ui/notification";
import { useI18n } from "@thisisbud/i18n-react";

import { renderI18nElements } from "../utils";
import { useConfig } from "../../../../../../context/config";

type StatusBarProps = {
  amount: number;
  current: number;
};

export function StatusBar({ amount, current }: StatusBarProps): React.ReactElement | null {
  const { enableReplayEvents } = useConfig();
  const { tToParts } = useI18n();
  const percentage = amount === 0 ? "0%" : `${Math.round((current / amount) * 100)}%`;
  const statusMessage = React.useMemo(
    () =>
      renderI18nElements(
        tToParts("projects.event-history.replay-events-status", {
          amount: amount,
          status: <strong className="ml-[0.5ch]">{percentage}</strong>,
        }),
      ),
    [amount, percentage, tToParts],
  );

  if (!enableReplayEvents) {
    return null;
  }
  return (
    <Notification status="info">
      <span className="flex" role="status">
        {statusMessage}
      </span>
    </Notification>
  );
}
