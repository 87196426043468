import { HttpError } from "@thisisbud/internal-sdk";

import { getSingleRequestAttempt } from "../api";
import { pageNotFound } from "../../../../../../constants/error-codes";

import type { SubscriptionCategory, WebhookRequest } from "../api";
import type { LoadableContext } from "@thisisbud/react-loadable";
import type { LocalLoadableCtx } from ".";

export type InitData = {
  currentEnvironment: string;
  projectId: string;
  webhookId: string;
  eventId: string;
  requestId: string;
  category: SubscriptionCategory;
  singleRequest: WebhookRequest;
};

export async function initSingleRequest(
  ctx: LoadableContext & LocalLoadableCtx,
): Promise<InitData> {
  const {
    eventId,
    environment: currentEnvironment,
    requestId,
    webhookId,
    clientId: applicationId,
  } = ctx;
  const category = "open-banking";
  const singleRequest = await getSingleRequestAttempt({
    applicationId,
    category,
    currentEnvironment,
    requestId,
  });

  if (typeof singleRequest === "undefined") {
    throw new HttpError(404, "Request not found", {
      code: pageNotFound,
    });
  }

  return {
    category: category,
    currentEnvironment: currentEnvironment,
    eventId: eventId,
    projectId: applicationId,
    requestId: requestId,
    singleRequest: singleRequest,
    webhookId: webhookId,
  };
}

export async function initSinglePaymentRequest(
  ctx: LoadableContext & LocalLoadableCtx,
): Promise<InitData> {
  const {
    eventId,
    environment: currentEnvironment,
    requestId,
    webhookId,
    clientId: applicationId,
  } = ctx;
  const category = "payments";
  const singleRequest = await getSingleRequestAttempt({
    applicationId,
    category,
    currentEnvironment,
    requestId,
  });

  if (typeof singleRequest === "undefined") {
    throw new HttpError(404, "Request not found", {
      code: pageNotFound,
    });
  }

  return {
    category: category,
    currentEnvironment: currentEnvironment,
    eventId: eventId,
    projectId: applicationId,
    requestId: requestId,
    singleRequest: singleRequest,
    webhookId: webhookId,
  };
}
