import * as React from "react";

import { ProgressSpinner } from "@thisisbud/bui-react-progress";
import { useI18n } from "@thisisbud/i18n-react";

import { staticDemoFallbackMinDuration, staticDemoLoadingLabelKeys } from "../constants";

export default function StaticLoadingView(): React.ReactElement {
  const [labelId, setLabelId] = React.useState(staticDemoLoadingLabelKeys[0]);
  const i18n = useI18n();

  React.useEffect(() => {
    const intervalTimer = staticDemoFallbackMinDuration / staticDemoLoadingLabelKeys.length;
    let currentIndex = 1;

    const intervalId = setInterval(() => {
      if (currentIndex >= staticDemoLoadingLabelKeys.length) {
        clearInterval(intervalId);
        return;
      }

      setLabelId(staticDemoLoadingLabelKeys[currentIndex]);
      currentIndex += 1;
    }, intervalTimer);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <ProgressSpinner id="static-demo-loading-spinner">
      {i18n.t(`demo.static-loading.${labelId}`)}
    </ProgressSpinner>
  );
}

export const staticLoadingView = <StaticLoadingView />;
