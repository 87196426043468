import EventTracking from "@thisisbud/event-tracking";

import type {
  BrowserEventPayload,
  BrowserEventTracking,
  InitOptions,
} from "@thisisbud/event-tracking";

let et: BrowserEventTracking | undefined;

/**
 * Initialize event tracking.
 *
 * @param token - The Mixpanel token
 * @param options - The event tracking options
 */
export function init(token: string, options?: InitOptions<BrowserEventPayload>): void {
  et = new EventTracking(token, options);
}

/**
 * Track a user action for user behavior analysis.
 *
 * @param eventName - The name of the event to be tracked
 * @param payload - Any data to be sent as part of the event
 */
export function track(eventName: string, payload?: BrowserEventPayload): void {
  if (typeof et === "undefined") {
    return;
  }

  et.track(eventName, payload);
}

/**
 * Set an alias to the current distinct ID.
 *
 * @param alias - The unique identifier of the user
 */
export function setAlias(alias: string): void {
  if (typeof et === "undefined") {
    return;
  }

  et.setAlias(alias);
}
