enum Events {
  // Header
  headerSignInClicked = "header - sign in button clicked",
  headerSignUpClicked = "header - sign up button clicked",

  // Sign up
  signUpPageViewed = "sign up page viewed",
  signUpPageFormCreateAccountClicked = "sign up page form - create account clicked",
  signUpPageFormFormSubmitted = "sign up page form - submitted",

  // Activate account page
  activateAccountPageViewed = "activate account page viewed",
  activateAccountContactSupportClicked = "activate account - contact support clicked",

  // Create password page
  createPasswordPageViewed = "create password page viewed",
  createPasswordSetPasswordClicked = "create password form - set password clicked",
  createPasswordContactUsClicked = "create password form - contact us clicked",

  // Sign in
  signInPageViewed = "sign in page viewed",
  forgotPasswordClicked = "forgot password button clicked",
  signInButtonClicked = "sign in button clicked",

  // Reset password
  resetPasswordPageViewed = "reset password page viewed",
  resetPasswordSubmitClicked = "reset password - submit clicked",
  resetPasswordCheckYourEmailPageViewed = "reset password - check your email page viewed",
  resetPasswordResendClicked = "reset password - resend clicked",
  resetPasswordContactUsClicked = "reset password - contact us clicked",

  // Homepage
  homepagePageView = "home page viewed",

  // Navigation
  homeTabClicked = "home tab clicked",
  projectsTabClicked = "projects tab clicked",
  customersTabClicked = "customers tab clicked",
  credentialsTabClicked = "credentials tab clicked",
  teamTabClicked = "team tab clicked",
  demoTabClicked = "demo tab clicked",

  // Projects
  projectsPageView = "projects page viewed",

  // Project detail page
  projectDetailPageView = "projects detail page viewed",
  projectDetailUpdateClicked = "projects detail page - update clicked",
  projectDetailResetClicked = "projects detail page - reset clicked",
  projectDetailDeleteClicked = "projects detail page - delete clicked", // todo button needs to be added

  // Credentials
  credentialsPageView = "credentials page viewed",
  credentialsCopyButtonClicked = "credentials page - copy button clicked",

  // Customers
  customersPageView = "customers page viewed",
  customersPageDeleteClicked = "customers page - delete button clicked",
  customersModalView = "customers page - modal viewed",
  customersModalClosed = "customers page - modal closed",
  customersModalCancelClicked = "customers page - modal cancel clicked",
  customersModalDeleteClicked = "customers page - modal delete clicked",

  // Demo
  demoPageViewed = "demo.page-viewed",
  demoAggregationLinkClicked = "demo.aggregation.link-clicked",
  demoStaticLinkClicked = "demo.static.link-clicked",
  demoProviderSelectionPageView = "demo provider page viewed",
  demoProviderClicked = "demo provider page - provider clicked",

  // Enrichment
  enrichmentLinkClicked = "enrichment link clicked",
  enrichmentPageView = "enrichment page viewed",

  // Team
  teamPageViewed = "team.page-viewed",
  teamInviteMemberClicked = "team.invite-member",
  teamInviteMemberSubmitted = "team.invite-member-submitted",
  teamInviteMemberCancelled = "team.invite-member-cancelled",
  teamInviteMemberFailed = "team.invite-member-failed",
  teamEditMemberClicked = "team.edit-member",
  teamEditMemberSubmitted = "team.edit-member-submitted",
  teamEditMemberCancelled = "team.edit-member-cancelled",
  teamEditMemberFailed = "team.edit-member-failed",
  teamDeleteMemberClicked = "team.remove-member",
  teamDeleteMemberSubmitted = "team.remove-member-submitted",
  teamDeleteMemberCancelled = "team.remove-member-cancelled",
  teamDeleteMemberFailed = "team.remove-member-failed",

  homePostmanCollectionLinkClicked = "home.get-started.postman-collection.clicked",
  homeOpenApiFileClicked = "home.get-started.openapi-file.clicked",
  homeEnrichmentLinkClicked = "home.get-started.enrichment-link.clicked",
  homeAggregationLinkClicked = "home.demos.aggregation.link-clicked",
  homeStaticLinkClicked = "home.demos.static.link-clicked",

  // create project
  createProjectModalOpened = "create project modal viewed",
  createProjectModalClosed = "create project modal closed",
  createProjectModalCreateClicked = "create project modal - create clicked",
  apiCredentialsModalOpened = "api credentials modal viewed",
  apiCredentialsModalDownloadClicked = "api credentials modal - download clicked",

  // manage project
  manageProjectClicked = "project.manage.clicked",
  manageProjectDeleteClicked = "project.manage.delete-clicked",
  manageProjectDeleteSubmitted = "project.manage.delete-submitted",
  manageProjectEditSubmitted = "project.manage.edit-submitted",
  manageProjectDeleteCancelled = "project.manage.delete-cancelled",
  manageProjectEditCancelled = "project.manage.edit-cancelled",
  manageProjectEditFailed = "project.manage.edit-failed",
  manageProjectDeleteFailed = "project.manage.delete-failed",

  // setup webhook
  setupWebhookClicked = "webhook.setup.clicked",
}

export default Events;
