import { Version, request } from "@thisisbud/internal-sdk";

import { Namespace } from "../constants";
import { formatGetProjectsResponse } from "../formatters/projects";

import type { ApiResponse } from "../formatters/projects";
import type { AuthDetails } from "../types/common";
import type { Project } from "../types/entities";

type Options = {
  page?: number;
  pageSize?: number;
};

/**
 * Get a list of projects.
 *
 * @param auth - The authentication details for the request
 * @param envType - The environment to get a project list from
 * @param options - Additional options to handle the request
 * @returns A list of projects
 */
export default async function getProjects(
  auth: AuthDetails,
  envType: string,
  options: Options = {},
): Promise<Project[]> {
  const { page, pageSize } = options;

  const response = await request<ApiResponse>(Version.v1, Namespace.web, {
    auth: auth,
    body: {
      page: page,
      page_size: pageSize,
    },
    headers: {
      "X-User-Env": envType,
    },
    method: "GET",
    path: "/api-credentials",
  });

  return formatGetProjectsResponse(response);
}
