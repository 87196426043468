import * as React from "react";
import styled from "styled-components";

import { useI18n } from "@thisisbud/i18n-react";
import { Button } from "@thisisbud/gds-ui/button";
import { selectFromTheme } from "@thisisbud/sc-utils";
import { paragraphSmallCss } from "@thisisbud/bui-react-typography";

import { RouteName } from "../../router/constants";
import { createHref } from "../../router";
import BudLogo from "../bud-logo";
import RouterLink from "../router-link";

export const appDemoHeaderClassName = "app-demo-header";
export const appDemoHeaderInnerClassName = `${appDemoHeaderClassName}__inner`;
export const appDemoHeaderActionsClassName = `${appDemoHeaderClassName}__actions`;

const StyledHeader = styled.header`
  padding: ${selectFromTheme("spacingSmallDefault")} ${selectFromTheme("spacingLargeDefault")};
  border-style: solid;
  border-width: ${selectFromTheme("thicknessAppHeaderBorderDefault")};
  border-color: ${selectFromTheme("colorBorderAppHeaderDefault")};

  @media (min-width: ${selectFromTheme("breakpointWide")}) {
    padding-right: ${selectFromTheme("spacingXXXXLargeDefault")};
    padding-left: ${selectFromTheme("spacingXXXXLargeDefault")};
  }

  .${appDemoHeaderInnerClassName} {
    display: flex;
    margin: 0 auto;
    max-width: ${selectFromTheme("breakpointExtraWide")};
  }

  .${appDemoHeaderActionsClassName} {
    ${paragraphSmallCss}
    margin-left: auto;
  }
`;

export default function AppDemoHeader(): React.ReactElement {
  const i18n = useI18n();

  return (
    <StyledHeader className={appDemoHeaderClassName}>
      <div className={appDemoHeaderInnerClassName}>
        <BudLogo />
        <aside className={appDemoHeaderActionsClassName}>
          <nav>
            <RouterLink
              as={Button}
              href={createHref({
                name: RouteName.home,
              })}
              id="demo-dashboard-back-to-console-button"
              variant="secondary"
            >
              {i18n.t("demo.header.back-button")}
            </RouterLink>
          </nav>
        </aside>
      </div>
    </StyledHeader>
  );
}
