import type { Account } from "@thisisbud/internal-sdk";
import type { DemoTransaction } from "../../../api/types/entities";

/**
 * Get the transactions for an account ID
 *
 * @param transactions - The list of transactions
 * @param accountId - The ID of the account to match
 * @returns A filtered list of transactions for an account
 */
export function getTransactionsForAccount(
  transactions: DemoTransaction[],
  accountId: string,
): DemoTransaction[] {
  return transactions.filter(function (t) {
    return t.accountId === accountId;
  });
}

/**
 * Get the transactions for a single merchant
 *
 * @param accountTransactions - The list of transactions from the account
 * @param merchantId - The merchant ID to look transactions from
 * @returns A filtered list of transactions for a single merchant
 */
export function getAccountTransactionsForMerchant(
  accountTransactions: DemoTransaction[],
  merchantId: string,
): DemoTransaction[] {
  return accountTransactions.filter(function (t) {
    if (typeof t.merchant === "undefined") {
      return false;
    }

    return t.merchant.id === merchantId;
  });
}

/**
 * Get the total spend for a single merchant
 *
 * @param transactions - The list of transactions
 * @param merchantId - The merchant ID to look transactions from
 * @returns A number representing the total spend for the merchant
 */
export function getTotalSpendForMerchant(
  transactions: DemoTransaction[],
  merchantId: string,
): number {
  return Math.abs(
    getAccountTransactionsForMerchant(transactions, merchantId).reduce(function (acc, trx) {
      return acc + trx.value;
    }, 0),
  );
}

/**
 * Get the average spend for a single merchant
 *
 * @param transactions - The list of transactions
 * @param merchantId - The merchant ID to look transactions from
 * @returns A number representing the average spend for the merchant
 */
export function getAverageSpendForMerchant(
  transactions: DemoTransaction[],
  merchantId: string,
): number {
  const merchantSum = getTotalSpendForMerchant(transactions, merchantId);
  const totalTransactionsForMerchant = getAccountTransactionsForMerchant(
    transactions,
    merchantId,
  ).length;

  return merchantSum / totalTransactionsForMerchant;
}

export function getAccountBalancesByCurrency(accounts: Account[]): { [currency: string]: number } {
  const balances: { [currency: string]: number } = {};

  accounts.forEach(function (account) {
    if (typeof balances[account.currency] === "undefined") {
      balances[account.currency] = account.balance;
    } else {
      balances[account.currency] += account.balance;
    }
  });

  return balances;
}
