import * as React from "react";
import styled from "styled-components";
import { useFormikContext } from "formik";

import { useI18n } from "@thisisbud/i18n-react";
import { selectFromTheme } from "@thisisbud/sc-utils";
import { Button, buttonClassName } from "@thisisbud/bui-react-buttons";

import { track } from "../../../../../client/utils/tracking";
import Events from "../../../../constants/events";

type Props = {
  onClose(event: React.MouseEvent<HTMLButtonElement> | KeyboardEvent): void;
};

const StyledDiv = styled.div`
  display: flex;
  margin-top: ${selectFromTheme("spacingXLargeDefault")};
  justify-content: flex-end;

  .${buttonClassName} {
    width: auto;
    margin-right: initial;
  }

  .${buttonClassName} + .${buttonClassName} {
    margin-top: 0;
    margin-left: 10px;
  }
`;

function trackDeleteClick(): void {
  track(Events.customersModalDeleteClicked);
}

function trackCancelClick(): void {
  track(Events.customersModalCancelClicked);
}

export default function DeleteCustomerDialogActions(props: Props): React.ReactElement {
  const { onClose } = props;
  const [isDisabled, setIsDisabled] = React.useState(true);
  const i18n = useI18n();

  const {
    values: { confirm },
    isSubmitting,
  } = useFormikContext<{
    confirm: string;
  }>();

  React.useEffect(
    function () {
      setIsDisabled(confirm.toLowerCase() !== "delete");
    },
    [confirm],
  );

  const onCancel = React.useCallback(
    function (e: React.MouseEvent<HTMLButtonElement> | KeyboardEvent) {
      trackCancelClick();
      onClose(e);
    },
    [onClose],
  );

  return (
    <StyledDiv>
      <Button id="delete-customer-dialog-cancel-btn" variant="secondary" onClick={onCancel}>
        {i18n.t("common.cancel")}
      </Button>
      <Button
        busy={isSubmitting}
        disabled={isDisabled}
        id="delete-customer-dialog-confirm-btn"
        type="submit"
        variant="destructive"
        onClick={trackDeleteClick}
      >
        {i18n.t("customers.delete-dialog.form.submit")}
      </Button>
    </StyledDiv>
  );
}
