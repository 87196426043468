import type { GenericResponse } from "@thisisbud/internal-sdk";
import type { OAuthToken } from "../types/responses";
import type { OAuthToken as OAuthTokenEntity } from "../types/entities";

export type ApiResponse = GenericResponse<OAuthToken>;

type OAuthHeaders = {
  "x-user-id": string;
};

export function formatOAuthTokenResponse(response: ApiResponse): OAuthTokenEntity {
  const {
    headers,
    data: { access_token, expires_in, refresh_token },
  } = response;
  const { "x-user-id": userId } = headers as OAuthHeaders;
  const expiresInMs = expires_in * 1000;

  return {
    accessToken: access_token,
    expires: Date.now() + expiresInMs,
    refreshToken: refresh_token,
    userId: userId,
  };
}
