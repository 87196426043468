import * as React from "react";

import Loadable from "@thisisbud/react-loadable";

import { loadingView } from "../../../loading";

/**
 * Lazily load the component.
 *
 * @returns The imported module
 */
async function loadComponent(): Promise<{ default: React.ComponentType }> {
  return import(/* webpackChunkName: 'views/demo/dashboard/home' */ "./component");
}

/**
 * Lazily load the component for the Demo Dashboard Home view.
 */
export default function DemoDashboardHomeViewLoader(): React.ReactElement {
  return <Loadable fallback={loadingView} fallbackDelay={0} loadComponent={loadComponent} />;
}
