import * as React from "react";
import { useSelector } from "react-redux";

import { useI18n } from "@thisisbud/i18n-react";

import { requireAuth } from "../../../client/utils/auth";
import createProject from "../../api/operations/create-project";
import { getEnvironment } from "../../store/environment";

import type { CreatedProject } from "../../api/types/entities";
import type { CreateProjectFormValues } from "./types";
import type { FormikErrors, FormikHelpers } from "formik";

type CreateProjectState = {
  busy: boolean;
  error?: string;
  project?: CreatedProject;
};

/**
 * Use the state and callback for the creation of a project.
 *
 * @param onCreate - A callback function, called before the project is created
 * @param onCreated - A callback function, called after the project is created
 * @returns The function to create a project and the action's state.
 */
export function useCreateProject(
  onCreate?: () => void,
  onCreated?: () => Promise<void>,
): CreateProjectState & {
  callback(
    values: CreateProjectFormValues,
    actions: FormikHelpers<CreateProjectFormValues>,
  ): Promise<void>;
} {
  const [state, setState] = React.useState<CreateProjectState>({
    busy: false,
    error: undefined,
    project: undefined,
  });
  const currentEnvironment = useSelector(getEnvironment);
  const i18n = useI18n();

  const callback = React.useCallback(
    async function (
      values: CreateProjectFormValues,
      actions: FormikHelpers<CreateProjectFormValues>,
    ): Promise<void> {
      const { name } = values;

      setState({
        busy: true,
        error: undefined,
        project: undefined,
      });

      if (typeof onCreate === "function") {
        onCreate();
      }

      try {
        const auth = await requireAuth();

        const project = await createProject(auth, currentEnvironment, name);

        setState({
          busy: false,
          error: undefined,
          project: project,
        });

        if (typeof onCreated === "function") {
          await onCreated();
        }
      } catch (err) {
        setState({
          busy: false,
          error: i18n.t("create-project-dialog.create.form.error.unknown"),
          project: undefined,
        });

        actions.setSubmitting(false);
      }
    },
    [setState],
  );

  return { ...state, callback };
}

/**
 * Use a callback function to validate the values of the form.
 *
 * @returns A callback function to validate the values of the form
 */
export function useValidate(): (
  values: CreateProjectFormValues,
) => FormikErrors<CreateProjectFormValues> {
  const i18n = useI18n();

  return React.useCallback(
    function (values: CreateProjectFormValues) {
      const { name } = values;
      const output: FormikErrors<CreateProjectFormValues> = {};

      const normalizedName = name.trim();

      if (normalizedName === "") {
        output.name = i18n.t("create-project-dialog.create.form.name.validation.required");
      }

      return output;
    },
    [i18n],
  );
}
