import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";

import { createHref } from "../../router";
import { RouteName } from "../../router/constants";
import { clearCurrentSession } from "../../../client/utils/auth";
import { resetEnvironment } from "../../store/environment";

export function useOnLogoutClick(): (e: React.MouseEvent<HTMLButtonElement>) => void {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return React.useCallback(
    function (e: React.MouseEvent<HTMLButtonElement>) {
      e.preventDefault();

      queryClient.removeQueries();
      queryClient.clear();
      dispatch(resetEnvironment());
      clearCurrentSession();

      navigate(
        createHref({
          name: RouteName.login,
        }),
      );
    },
    [dispatch, navigate, queryClient],
  );
}
