import * as React from "react";

import { useI18n } from "@thisisbud/i18n-react";
import { Paragraph } from "@thisisbud/bui-react-typography";
import { Form } from "@thisisbud/bui-react-form";
import { InputGroup } from "@thisisbud/bui-react-input-group";
import { Input } from "@thisisbud/bui-react-input";
import { Notification } from "@thisisbud/bui-react-notification";

import CreateDialogActions from "./actions";
import { useValidate } from "./hooks";

import type { FormikHelpers } from "formik";
import type { CreateProjectFormValues } from "./types";

type Props = {
  error?: string;
  onClose(event: React.MouseEvent<HTMLButtonElement> | KeyboardEvent): void;
  onSubmit(
    values: CreateProjectFormValues,
    actions: FormikHelpers<CreateProjectFormValues>,
  ): Promise<void>;
};

const initialFormValues = {
  name: "",
};

export default function CreateDialogForm(props: Props): React.ReactElement {
  const { onClose, onSubmit, error } = props;
  const i18n = useI18n();
  const validate = useValidate();

  return (
    <React.Fragment>
      <Paragraph>{i18n.t("create-project-dialog.create.body")}</Paragraph>
      <Form initialValues={initialFormValues} validate={validate} onSubmit={onSubmit}>
        {typeof error !== "undefined" && (
          <Notification
            id="create-project-dialog-error"
            label={i18n.t("common.error")}
            type="error"
          >
            {error}
          </Notification>
        )}
        <InputGroup label={i18n.t("create-project-dialog.create.form.name.label")}>
          <Input id="name" name="name" required={true} />
        </InputGroup>
        <CreateDialogActions onClose={onClose} />
      </Form>
    </React.Fragment>
  );
}
