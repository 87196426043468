import * as React from "react";

import { useI18n } from "@thisisbud/i18n-react";

import BudLoadingIndicator from "../../bud-loading-indicator";

export default function LoadingView(): React.ReactElement {
  const i18n = useI18n();

  return <BudLoadingIndicator id="screen-loading-indicator" label={i18n.t("common.loading")} />;
}

export const loadingView = <LoadingView />;
