import { Version, request } from "@thisisbud/internal-sdk";

import { Namespace } from "../../constants";
import { formatDemoAccountsResponse } from "../../formatters/demo-accounts";

import type { AuthDetails } from "../../types/common";
import type { GetDemoAccountsResponse } from "../../types/responses";
import type { DemoAccount } from "../../types/entities";

/**
 * Get a list of connected accounts.
 *
 * @param auth - The authentication details for the request
 * @param envType - The environment
 * @returns The connected accounts
 */
export default async function getOpenBankingDemoAccounts(
  auth: AuthDetails,
  envType: string,
): Promise<DemoAccount[]> {
  const response = await request<GetDemoAccountsResponse>(Version.v1, Namespace.demo, {
    auth: auth,
    headers: {
      "X-User-Env": envType,
    },
    method: "GET",
    path: "/accounts",
  });

  return formatDemoAccountsResponse(response);
}
