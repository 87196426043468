import * as React from "react";

import { useI18n } from "@thisisbud/i18n-react";

import { useDeleteCustomer } from "../api";

import type { initialFormValues } from "./constants";
import type { FormikBag } from "formik";

type FormValues = typeof initialFormValues;

/**
 * Use a form on submit callback function with an error state.
 *
 * @param customerId - The customer ID of the intended deletion
 * @param onDeleted - A function to call on deletion of the customer
 * @param onClose - A function to call on close of the dialog
 * @returns A submit callback function and an error if any.
 */
export function useOnSubmit(
  customerId: string,
  onDeleted: () => void,
  onClose: () => void,
): {
  error?: string;
  callback(values: FormValues, helpers: FormikBag<{}, FormValues>): Promise<void>;
} {
  const i18n = useI18n();
  const [error, setError] = React.useState<string | undefined>();
  const { mutate } = useDeleteCustomer();

  const callback = React.useCallback(
    async (values: FormValues, helpers: FormikBag<{}, FormValues>): Promise<void> => {
      try {
        helpers.setSubmitting(true);
        mutate(
          { id: customerId },
          {
            onSuccess: () => {
              onDeleted();
              helpers.setSubmitting(false);
              onClose();
            },
          },
        );
      } catch (err) {
        helpers.setSubmitting(false);
        setError(i18n.t("customers.delete-dialog.form.error.unknown"));
      }
    },
    [i18n],
  );

  return {
    callback,
    error,
  };
}
