import * as React from "react";

import Loadable from "@thisisbud/react-loadable";

import { loadingView } from "../loading";
import init from "./init";

import type { InitData } from "./init";

/**
 * Lazily load the component.
 *
 * @returns The imported module
 */
async function loadComponent(): Promise<{ default: React.ComponentType<{ data: InitData }> }> {
  return import(/* webpackChunkName: 'views/activate-account' */ "./component");
}

/**
 * Lazily load the component for the Activate account view.
 */
export default function ActivateAccountViewLoader(): React.ReactElement {
  return (
    <Loadable fallback={loadingView} fallbackDelay={0} init={init} loadComponent={loadComponent} />
  );
}
