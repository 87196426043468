import React from "react";
import { useParams } from "react-router-dom";

import {
  getAccountTransactionsForMerchant,
  getAverageSpendForMerchant,
  getTotalSpendForMerchant,
  getTransactionsForAccount,
} from "../../utils";
import { useDemoDashboardData } from "../context";

import type { DemoTransaction } from "../../../../../api/types/entities";

type TransactionTotals = {
  averageSpend: number;
  numberOfTransactions: number;
  totalSpend: number;
};

export function useTransaction(): DemoTransaction | undefined {
  const { transactions } = useDemoDashboardData();
  const { transactionId } = useParams();

  return transactions.find(function (t) {
    return t.id === transactionId;
  });
}

export function useTransactionTotals(transaction: DemoTransaction): TransactionTotals {
  const { accountId, merchant } = transaction;
  const { transactions } = useDemoDashboardData();

  const totals = React.useMemo(
    function () {
      if (typeof merchant === "undefined") {
        return {
          averageSpend: transaction.value,
          numberOfTransactions: 1,
          totalSpend: transaction.value,
        };
      }

      const transactionsForAccount = getTransactionsForAccount(transactions, accountId);
      const averageSpend = getAverageSpendForMerchant(transactionsForAccount, merchant.id);
      const totalSpend = getTotalSpendForMerchant(transactionsForAccount, merchant.id);
      const numberOfTransactions = getAccountTransactionsForMerchant(
        transactionsForAccount,
        merchant.id,
      ).length;

      return {
        averageSpend,
        numberOfTransactions,
        totalSpend,
      };
    },
    [accountId, merchant, transactions, transaction],
  );

  return totals;
}
