import * as React from "react";
import { useSearchParams } from "react-router-dom";

import Loadable from "@thisisbud/react-loadable";
import { HttpError } from "@thisisbud/internal-sdk";

import { createHref } from "../../../router";
import { RouteName } from "../../../router/constants";
import { loadingView } from "../loading";
import init from "./init";

export type LocalLoadableContext = {
  email: string;
  token: string;
};

/**
 * Lazily load the component.
 *
 * @returns The imported module
 */
async function loadComponent(): Promise<{ default: any }> {
  return import(/* webpackChunkName: 'views/invite' */ "./component");
}

/**
 * Lazily load the component and initialize data for the Invite view.
 */
export default function InviteViewLoader(): React.ReactElement {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const token = searchParams.get("token");

  if (typeof email !== "string" || typeof token !== "string") {
    // TODO: implement redirecting in error boundary
    throw new HttpError(307, "Redirected", {
      payload: {
        redirectTo: createHref({ name: RouteName.login }),
      },
    });
  }

  const localCtx: LocalLoadableContext = React.useMemo(function () {
    return {
      email,
      token,
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Loadable
      ctx={localCtx}
      fallback={loadingView}
      fallbackDelay={0}
      init={init}
      loadComponent={loadComponent}
    />
  );
}
