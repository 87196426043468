import * as React from "react";

import { useI18n } from "@thisisbud/i18n-react";
import { Dialog } from "@thisisbud/bui-react-dialog";

import CreateDialogForm from "./form";
import CreateDialogConfirmation from "./confirmation";
import { useCreateProject } from "./hooks";

type Props = {
  screen?: string;
  onClose(event: React.MouseEvent<HTMLButtonElement> | KeyboardEvent): void;
  onCreate?(): void;
  onCreated?(): Promise<void>;
};

export default function CreateProjectDialog(props: Props): React.ReactElement {
  const { screen, onClose, onCreate, onCreated } = props;
  const i18n = useI18n();

  const { busy, callback: onSubmit, project, error } = useCreateProject(onCreate, onCreated);

  const projectIsCreated = typeof project !== "undefined";

  return (
    // @ts-expect-error the prop allowClose does not work well when dynamically passing `false`
    <Dialog
      allowClose={!busy}
      closeLabel={i18n.t("common.close")}
      id="create-project-dialog"
      open={true}
      title={
        !projectIsCreated
          ? i18n.t("create-project-dialog.create.title")
          : i18n.t("create-project-dialog.confirmation.title")
      }
      onClose={onClose}
    >
      {!projectIsCreated ? (
        <CreateDialogForm error={error} onClose={onClose} onSubmit={onSubmit} />
      ) : (
        <CreateDialogConfirmation project={project} screen={screen} />
      )}
    </Dialog>
  );
}
