import { HttpError, getPollingInterval } from "@thisisbud/internal-sdk";
import { timeout } from "@thisisbud/timer-utils";

import getDemoOpenBankingConnectStatus from "./get-demo-open-banking-connect-status";

import type { AuthDetails } from "../../types/common";
import type { OpenBankingConnectStatus } from "@thisisbud/internal-sdk";

// todo: replace with type from internal-sdk, not currently possible as it's not exposed correctly
enum OpenBankingConnectStatusErrorCode {
  failedFetchingAccessToken = "failed-fetching-access-token",
  failedFetchingAccounts = "failed-fetching-accounts",
  failedFetchingBalancesAndTransactions = "failed-fetching-balances-and-transactions",
  unknown = "unknown",
}

/**
 * Match a connect status error code to a HTTP status code.
 *
 * @param errorCode - The connect status error code
 * @returns A HTTP response status code
 */
function getErrorHttpStatusCode(errorCode: string): number {
  switch (errorCode) {
    case OpenBankingConnectStatusErrorCode.failedFetchingAccessToken:
    case OpenBankingConnectStatusErrorCode.failedFetchingAccounts:
    case OpenBankingConnectStatusErrorCode.failedFetchingBalancesAndTransactions:
      return 502;
    default:
      return 500;
  }
}

/**
 * Get a list of customers.
 *
 * @param auth - The authentication details for the request
 * @param envType - The environment to get a customer list from
 * @param options - Additional options to handle the request
 * @returns A list of customers
 *
 */
export default async function confirmOpenBankingConnection(
  auth: AuthDetails,
  envType: string,
  connectionTaskId: string,
): Promise<void> {
  const pollingInterval = getPollingInterval();

  let status: OpenBankingConnectStatus | undefined;

  while (typeof status === "undefined" || status.status === "processing") {
    await timeout(pollingInterval);

    status = await getDemoOpenBankingConnectStatus(auth, envType, connectionTaskId);
  }

  if (status.status === "error") {
    throw new HttpError(getErrorHttpStatusCode(status.errorCode), status.message, {
      code: status.errorCode,
    });
  }
}
