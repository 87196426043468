import type { SortParams } from "../../../types/sorting";

export const allowedSortKeys = ["created-at", "customer-id"] as const;

export const defaultSortParams: SortParams<(typeof allowedSortKeys)[number]> = {
  sortDirection: "descending",
  sortedBy: "created-at",
};

export const pageSize = 50;

export const pagerSize = 4;

export const pageParam = "page";

export const debounceDelay = 350;
