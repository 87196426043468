import * as React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Loadable from "@thisisbud/react-loadable";

import { loadingView } from "../../../loading";
import init from "./init";

import type { NavigateFunction } from "react-router-dom";

export type InitContext = {
  navigate: NavigateFunction;
  searchParams: URLSearchParams;
};

/**
 * Lazily load the component.
 *
 * @returns The imported module
 */
async function loadComponent(): Promise<{ default: React.ComponentType }> {
  return import(/* webpackChunkName: 'views/demo/aggregation/status' */ "./component");
}

/**
 * Lazily load the component for the aggregation status view.
 */
export default function DemoConnectionStatusViewLoader(): React.ReactElement {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { demoId } = useParams();

  const initContext = React.useMemo(() => {
    return {
      demoId,
      navigate,
      searchParams,
    };
  }, [navigate, demoId, searchParams]);

  return (
    <Loadable
      ctx={initContext}
      fallback={loadingView}
      fallbackDelay={0}
      init={init}
      loadComponent={loadComponent}
    />
  );
}
