interface Window { // eslint-disable-line @typescript-eslint/consistent-type-definitions, @typescript-eslint/no-unused-vars
  __webpack_nonce__: string,
}

// This version is necessary for Styled Components to work correctly

window.__webpack_nonce__ = document.documentElement.getAttribute('data-nonce') as string // eslint-disable-line no-underscore-dangle

// @ts-expect-error This version is necessary for Webpack's lazy loading to work correctly
__webpack_nonce__ = document.documentElement.getAttribute('data-nonce') as string // eslint-disable-line no-undef
