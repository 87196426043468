import * as React from "react";
import { useOutletContext } from "react-router-dom";

import { Table } from "@thisisbud/bui-react-table";

import { NoWebhooks } from "./no-webhooks";
import {
  useGetWebhooksSortButtonLabel,
  useSortedWebhooks,
  useTableColumns,
  useTableData,
} from "./hooks";
import { useGetSubscribableEvents, useGetSubscribedEvents } from "./api/queries";
import LoadingView from "../../../loading";
import { useSorting } from "../../../../../hooks/use-sorting";
import { allowedWebhooksSortKeys, defaultWebhooksSortParams } from "./constants";

import type { WebhookContextType } from "./types";

export default function WebhookList(): React.ReactElement {
  const { openCreateWebhookDialog } = useOutletContext<WebhookContextType>();
  const { data: subscribedEvents } = useGetSubscribedEvents();
  const { data: allEvents } = useGetSubscribableEvents();
  const { onSortChange, sortDirection, sortedBy } = useSorting(
    allowedWebhooksSortKeys,
    defaultWebhooksSortParams,
  );
  const sortedWebhooks = useSortedWebhooks(subscribedEvents ?? [], sortDirection, sortedBy);
  const getSortButtonLabel = useGetWebhooksSortButtonLabel();

  const columns = useTableColumns();
  const data = useTableData(sortedWebhooks);

  if (!allEvents || !subscribedEvents) return <LoadingView />;

  const isAtLeastOneWebhookEnabled = subscribedEvents.length;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <React.Fragment>
      {isAtLeastOneWebhookEnabled ? (
        <Table
          className="mt-8"
          columns={columns}
          data={data}
          getSortButtonLabel={getSortButtonLabel}
          sortDirection={sortDirection}
          sortedBy={sortedBy}
          onSortColumnClick={onSortChange}
        />
      ) : (
        <NoWebhooks openCreateWebhookDialog={openCreateWebhookDialog} />
      )}
    </React.Fragment>
  );
}
