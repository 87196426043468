import { Version, request } from "@thisisbud/internal-sdk";

import { Namespace } from "../constants";
import { formatGetCustomInsightsResponse } from "../formatters/custom-insights";

import type { ApiResponse } from "../formatters/custom-insights";
import type { AuthDetails } from "../types/common";
import type { CustomInsight } from "../types/entities";

export type Options = {
  auth: AuthDetails;
  environment: string;
  projectId: string;
};

/**
 * Get a list of insights for a specific project.
 *
 * @param options - The options for the request
 *
 */
export default async function getCustomInsights({
  auth,
  environment,
  projectId,
}: Options): Promise<CustomInsight[]> {
  /*
   * Versioning and namespace are inverted. This is because of a new standard we are adopting. Here more info:
   * https:// budfintech.slack.com/archives/C017VBW7XAP/p1652870811542119
   */
  const response = await request<ApiResponse>(Namespace.insights, Version.v1, {
    auth: auth,
    headers: {
      "X-User-Env": environment,
    },
    method: "GET",
    path: `/custom/application/${encodeURIComponent(projectId)}/manage`,
  });

  return formatGetCustomInsightsResponse(response);
}
