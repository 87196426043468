import type { Account, Transaction } from "@thisisbud/internal-sdk";
import type { Account as AccountResponse } from "@thisisbud/internal-sdk/lib/types/types/responses";
import type { TargetEnvironment } from "../../types/environment";
import type {
  CreatedProject as CreatedProjectResponse,
  DemoTransaction as DemoTransactionResponse,
} from "./responses";

export type OAuthToken = {
  accessToken: string;
  expires: number;
  userId: string;
  refreshToken: string;
};

export type Project = {
  clientId: string;
  createdAt: Date;
  name?: string;
};

export type CustomInsight = {
  active: boolean;
  createdAt: Date;
  id: string;
  identifier: string;
  query: string;
};
export type UserInsight = CustomInsight & {
  activeOn: Project[];
};

export type CreatedCustomInsight = {
  id: string;
};

export type CreatedProject = {
  clientId: string;
  clientSecret: string;
  name?: string;
  raw: CreatedProjectResponse;
};

export type DemoCustomer = {
  customerId: string;
  customerSecret: string;
};

export type DemoTransactionMerchant = {
  id: string;
  name: string;
  logoUrl: string;
};

export type DemoTransaction = Transaction & {
  providerId: string;
  merchant?: DemoTransactionMerchant;
  raw: DemoTransactionResponse;
};

export type DemoAccount = Account & {
  raw: AccountResponse;
};

export type TeamInviteStatus = "error" | "pending" | "processing" | "success";

export type TeamInvite = {
  email: string;
  status: TeamInviteStatus;
};

export enum TeamMemberRole {
  admin = "admin",
  standard = "standard",
}

export type TeamMember = {
  email: string;
  environment: TargetEnvironment;
  firstName: string;
  id: string;
  lastName: string;
  role: TeamMemberRole;
};

export type ActivatedUser = {
  email: string;
  id: string;
};

export type Profile = {
  email: string;
};

export type L1Category = {
  displayName: string;
  name: string;
  subcategories: L2Category[];
};

export type L2Category = {
  displayName: string;
  name: string;
};
