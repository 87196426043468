import * as React from "react";
import styled from "styled-components";

import { selectFromTheme } from "@thisisbud/sc-utils";
import { Paragraph, paragraphClassName } from "@thisisbud/bui-react-typography";
import { useI18n } from "@thisisbud/i18n-react";

const StyledRoot = styled.div`
  padding: ${selectFromTheme("spacingXSmallDefault")};
  background-color: ${selectFromTheme("colorBackgroundWarningDefault")};
  text-align: center;

  .${paragraphClassName} {
    margin: 0 auto;
    text-align: center;
  }
`;

export default function EnvironmentBanner(): React.ReactElement {
  const i18n = useI18n();

  return (
    <StyledRoot role="alert">
      <Paragraph>{i18n.t("header.environment-banner.production-title")}</Paragraph>
    </StyledRoot>
  );
}
