import { parseUrlSearchParams } from "@thisisbud/url-utils";

import validateActivationToken from "../../../api/operations/validate-activation-token";

import type { HttpError } from "@thisisbud/internal-sdk";

export type InitData = {
  error?: HttpError;
  isValidToken: boolean;
  token?: string;
};

export default async function init(): Promise<InitData> {
  const { token } = parseUrlSearchParams(window.location.search);

  if (typeof token !== "string") {
    return {
      isValidToken: false,
    };
  }

  try {
    await validateActivationToken({ token });
    return {
      isValidToken: true,
    };
  } catch (error) {
    return {
      error: error,
      isValidToken: false,
    };
  }
}
