/* eslint-disable react/no-multi-comp */
import * as React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Loadable from "@thisisbud/react-loadable";

import { loadingView } from "../../../../loading";
import { useConfig } from "../../../../../../context/config";
import { getEnvironment } from "../../../../../../store/environment";
import { initSinglePaymentRequest, initSingleRequest } from "./init";

import type { ProjectParams } from "../../types";
import type { TargetEnvironment } from "../../../../../../types/environment";

export type LocalLoadableCtx = {
  environment: TargetEnvironment;
  clientId: string;
  obSubscribableEventsSupportedEnvs: string[];
  paymentsSubscribableEventsSupportedEnvs: string[];
  webhookId: string;
  eventId: string;
  requestId: string;
};

/**
 * Lazily load the component.
 *
 * @returns The imported module
 */
async function loadComponent(): Promise<{ default: React.ComponentType }> {
  return import(/* webpackChunkName: 'views/webhooks/single-request' */ "./component");
}

/**
 * Lazily load the component and initialize data for the Single Webhook view.
 */
export function SingleRequestLoader(): React.ReactElement {
  const params = useParams<keyof ProjectParams>() as ProjectParams;
  const { clientId, webhookId, eventId, requestId } = params;
  const { obSubscribableEventsSupportedEnvs, paymentsSubscribableEventsSupportedEnvs } =
    useConfig();
  const currentEnvironment = useSelector(getEnvironment);

  const localCtx = React.useMemo((): LocalLoadableCtx => {
    return {
      clientId: clientId,
      environment: currentEnvironment,
      eventId: eventId,
      obSubscribableEventsSupportedEnvs: obSubscribableEventsSupportedEnvs,
      paymentsSubscribableEventsSupportedEnvs: paymentsSubscribableEventsSupportedEnvs,
      requestId: requestId,
      webhookId: webhookId,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Loadable
      ctx={localCtx}
      fallback={loadingView}
      fallbackDelay={0}
      init={initSingleRequest}
      loadComponent={loadComponent}
      params={params}
    />
  );
}

export function SinglePaymentRequestLoader(): React.ReactElement {
  const params = useParams<keyof ProjectParams>() as ProjectParams;
  const { clientId, webhookId, eventId, requestId } = params;
  const { obSubscribableEventsSupportedEnvs, paymentsSubscribableEventsSupportedEnvs } =
    useConfig();
  const currentEnvironment = useSelector(getEnvironment);

  const localCtx = React.useMemo((): LocalLoadableCtx => {
    return {
      clientId: clientId,
      environment: currentEnvironment,
      eventId: eventId,
      obSubscribableEventsSupportedEnvs: obSubscribableEventsSupportedEnvs,
      paymentsSubscribableEventsSupportedEnvs: paymentsSubscribableEventsSupportedEnvs,
      requestId: requestId,
      webhookId: webhookId,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Loadable
      ctx={localCtx}
      fallback={loadingView}
      fallbackDelay={0}
      init={initSinglePaymentRequest}
      loadComponent={loadComponent}
      params={params}
    />
  );
}
