import * as React from "react";

import Loadable from "@thisisbud/react-loadable";
import { HttpError } from "@thisisbud/internal-sdk";

import { loadingView } from "../../loading";
import { pageNotFound } from "../../../../constants/error-codes";
import { useProject } from "./hooks";

import type { Project } from "src/common/api/types/entities";

/**
 * Lazily load the component.
 *
 * @returns The imported module
 */
async function loadComponent(): Promise<{ default: React.ComponentType<{ project: Project }> }> {
  return import(/* webpackChunkName: 'views/projects/project' */ "./component");
}

/**
 * Lazily load the component and initialize data for the single Project view.
 */
export default function ProjectViewLoader(): React.ReactElement {
  const project = useProject();

  if (typeof project === "undefined") {
    throw new HttpError(404, "Project not found", {
      code: pageNotFound,
    });
  }

  return (
    <Loadable
      fallback={loadingView}
      fallbackDelay={0}
      loadComponent={loadComponent}
      project={project}
    />
  );
}
