import { availableStatusFilters } from "../api";

import type { FilterAction, FilterState } from "./types";

export const initialFilterState: FilterState = {
  from: "",
  showCreatedEvents: true,
  showFailedEvents: true,
  showPaymentEvents: false,
  showRetryingEvents: true,
  showSentEvents: true,
  to: "",
};

export const calculateActiveFiltersAmount = (filters: FilterState): string => {
  const { from, to, showPaymentEvents, ...statusFilters } = filters;
  const isStatusFilterActive = availableStatusFilters.some((filter) => !statusFilters[filter]);

  const isShowPaymentsFilterActive = showPaymentEvents;
  const isDateFilterActive = from !== "" || to !== "";
  const activeFiltersAmount = [
    isStatusFilterActive,
    isShowPaymentsFilterActive,
    isDateFilterActive,
  ].filter(Boolean).length;

  return activeFiltersAmount > 0 ? `(${activeFiltersAmount})` : "";
};

export const eventFilterReducer = (state: FilterState, action: FilterAction): FilterState => {
  const { type, payload } = action;
  switch (type) {
    case "setDateFrom": {
      return {
        ...state,
        from: payload as string,
      };
    }
    case "setDateTo": {
      return {
        ...state,
        to: payload as string,
      };
    }
    case "setShowFailedEvents": {
      return {
        ...state,
        showFailedEvents: payload as boolean,
      };
    }
    case "setShowSentEvents": {
      return {
        ...state,
        showSentEvents: payload as boolean,
      };
    }
    case "setShowRetryingEvents": {
      return {
        ...state,
        showRetryingEvents: payload as boolean,
      };
    }
    case "setShowPaymentEvents": {
      return {
        ...state,
        showPaymentEvents: payload as boolean,
      };
    }
    case "setShowCreatedEvents": {
      return {
        ...state,
        showCreatedEvents: payload as boolean,
      };
    }
    case "reset": {
      return initialFilterState;
    }
    default:
      return state;
  }
};
