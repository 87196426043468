import type { PostTeamInviteResponse } from "../types/responses";
import type { TeamInvite } from "../types/entities";

/**
 * Format a team invite status.
 *
 * @param status - The status value
 * @returns The formatted status value
 */
function formatTeamInviteStatus(status: string): TeamInvite["status"] {
  switch (status) {
    case "COMPLETED":
      return "success";
    case "PENDING":
      return "pending";
    case "IN_PROGRESS":
      return "processing";
    default:
      return "error";
  }
}

/**
 * Formats a POST team invite response.
 *
 * @param res - The response received from the team invite endpoint
 * @returns The team invite details
 */
export function formatPostTeamInviteResponse(res: PostTeamInviteResponse): TeamInvite {
  const { data } = res;

  return {
    email: data.email,
    status: formatTeamInviteStatus(data.status),
  };
}
