import { availableStatusFilters, statusFilterMap } from "./constants";

import type { FilterState } from "../event-history/types";
import type * as types from "./types";

export const formatSubscribableEvents = (
  events: string[],
  category: types.SubscriptionCategory,
): types.SubscribableEvent[] =>
  events.map((event) => ({ category: category, title: event, type: event }));

export const formatSubscribedEvents = (
  events: types.SubscriptionResponse[],
  category: types.SubscriptionCategory,
): types.Subscription[] =>
  events.map(
    ({
      active,
      app_id,
      created_at,
      endpoint_headers,
      event_type,
      last_modified_at,
      last_modified_by,
      deleted_at,
      org_id,
      signing_enabled,
      ...rest
    }) => ({
      ...rest,
      appId: app_id,
      category: category,
      createdAt: created_at,
      deletedAt: deleted_at,
      eventType: event_type,
      headers: endpoint_headers,
      isActive: active,
      isSigningEnabled: signing_enabled,
      lastModifiedAt: last_modified_at,
      lastModifiedBy: last_modified_by,
      orgId: org_id,
    }),
  );

export const selectSingleEvent = (
  events: types.Subscription[],
  id: string,
): types.Subscription | undefined => events.find(({ eventType }) => eventType === id);

export const getIsTestEvent = (metadata?: { [key: string]: unknown } | null): boolean => {
  if (metadata && "test" in metadata && metadata.test === true) {
    return true;
  }
  return false;
};

export const formatSingleWebhookEvent = (event: types.WebhookEventResponse): types.WebhookEvent => {
  const {
    app_id,
    created_at,
    customer_id,
    org_id,
    request_id,
    event_type,
    successfully_sent_at,
    metadata: eventMetadata,
    ...rest
  } = event;

  return {
    ...rest,
    appId: app_id,
    createdAt: new Date(created_at),
    customerId: customer_id,
    eventType: event_type,
    isTestEvent: getIsTestEvent(eventMetadata),
    orgId: org_id,
    requestId: request_id,
    successfullySentAt: successfully_sent_at !== null ? new Date(successfully_sent_at) : null,
  };
};

export const formatWebhookEvents = (
  events: types.WebhookEventResponse[],
  metadata: types.MetadataResponse,
): types.WebhookEvents => {
  const formattedEvents = events.map(formatSingleWebhookEvent);
  const formattedMetadata = { count: metadata.count, nextPageToken: metadata.next_page_token };

  return { events: formattedEvents, metadata: formattedMetadata };
};

const formatRequestStatus = (responseCode: number): types.RequestStatus => {
  if (responseCode >= 200 && responseCode < 300) {
    return "succeeded";
  }
  return "failure";
};
export const formatWebhookRequest = ({
  event_id,
  attempted_at,
  requested_url,
  response_code,
  response_body,
  request_timed_out,
  signed,
  encrypted,
  ...rest
}: types.WebhookRequestResponse): types.WebhookRequest => {
  return {
    attemptedAt: new Date(attempted_at),
    eventId: event_id,
    isEncrypted: encrypted,
    isSigned: signed,
    requestTimedOut: request_timed_out,
    requestedUrl: requested_url,
    responseBody: response_body,
    responseCode: response_code,
    status: formatRequestStatus(response_code),
    ...rest,
  };
};

export const formatWebhookRequests = (
  requests: types.WebhookRequestResponse[],
): types.WebhookRequest[] => {
  return requests.map(formatWebhookRequest);
};

/**
 * Applies status filters to the query parameters.
 * @param query - The URLSearchParams object representing the query parameters.
 * @param filters - The filter state object containing the filters to be applied.
 */
const applyStatusFiltersToQuery = (query: URLSearchParams, filters?: FilterState): void => {
  if (!filters) return;

  const statusFilters = Object.entries(filters)
    .filter(([key, value]) => {
      return (
        typeof value === "boolean" &&
        value &&
        availableStatusFilters.includes(key as types.StatusFilterKey)
      );
    })
    .map(([key]) => statusFilterMap[key as types.StatusFilterKey]);

  statusFilters.forEach((status) => {
    query.append("status", status);
  });
};

/**
 * Applies date filters to the query parameters.
 * @param query - The URLSearchParams object representing the query parameters.
 * @param filters - The optional FilterState object containing filters.
 */
const applyDateFiltersToQuery = (query: URLSearchParams, filters?: FilterState): void => {
  if (!filters) return;

  const { from, to } = filters;

  if (from !== "") {
    const fromFilter = new Date(from).toISOString();
    query.set("from", fromFilter);
  }

  if (to !== "") {
    const fromFilter = new Date(to).toISOString();
    query.set("to", fromFilter);
  }
};

/**
 * Builds a query string for events API based on the provided parameters.
 * @param applicationId - The ID of the application.
 * @param pageParam - Token of the next page.
 * @param pageSize - The page size for pagination.
 * @param filters - The filters to apply to the query.
 * @returns The built query string.
 */
export const buildEventsQueryString = ({
  applicationId,
  pageParam,
  pageSize,
  filters,
}: types.EventsQueryString): string => {
  const query = new URLSearchParams();

  applyStatusFiltersToQuery(query, filters);
  applyDateFiltersToQuery(query, filters);

  if (pageParam !== undefined) {
    query.set("page_token", pageParam);
  }

  if (pageSize !== undefined) {
    query.set("page_size", pageSize.toString());
  }

  query.set("app_id", applicationId);

  return query.size > 0 ? `?${query.toString()}` : "";
};
