import { requireAuth } from "../../../../../client/utils/auth";
import getDemoOpenBankingProviders from "../../../../api/operations/demo/get-demo-open-banking-providers";

import type { LoadableContext } from "@thisisbud/react-loadable";
import type { ProviderMap } from "@thisisbud/internal-sdk";
import type { TargetEnvironment } from "../../../../types/environment";

export type InitData = {
  providers: {
    type: Extract<TargetEnvironment, "sandbox" | "production">;
    providers: ProviderMap;
  }[];
};

export default async function init(ctx: LoadableContext): Promise<InitData> {
  const { demoSandboxProvidersWhitelist } = ctx.config;
  const auth = await requireAuth();

  // TODO: hardcoded envType as only sandbox providers supported
  const providers = await getDemoOpenBankingProviders(auth, "sandbox");

  // limit the demo providers shown to the user if a filter is provided
  if (demoSandboxProvidersWhitelist.length > 0) {
    return {
      providers: [
        {
          providers: demoSandboxProvidersWhitelist.reduce(function (filteredProviders, providerId) {
            const filteredProvider = providers[providerId];

            if (typeof filteredProvider === "undefined") {
              return filteredProviders;
            }

            return {
              ...filteredProviders,
              [providerId]: filteredProvider,
            };
          }, {}),
          type: "sandbox",
        },
      ],
    };
  }

  return {
    providers: [
      {
        providers: providers,
        type: "sandbox",
      },
    ],
  };
}
