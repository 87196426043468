import React from "react";

import { Button } from "@thisisbud/gds-ui/button";
import { Dialog } from "@thisisbud/gds-ui/dialog";
import { useI18n } from "@thisisbud/i18n-react";

type Props = {
  onClose(): void;
  isOpen: boolean;
  totalAmount: number;
  openEventFilterDialog(): void;
};

export function ReplayEventsDialog({
  onClose,
  isOpen,
  totalAmount,
  openEventFilterDialog,
}: Props): React.ReactElement {
  const { t } = useI18n();

  const filterEventHistory = React.useCallback(() => {
    openEventFilterDialog();
  }, [openEventFilterDialog]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <Dialog.Set closeLabel={t("common.close")}>
        <Dialog.Title>
          {t("projects.event-history.replay-events-dialog.title", { amount: totalAmount })}
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.Description>
            {t("projects.event-history.replay-events-dialog.description")}
          </Dialog.Description>
          <div className="mt-12 flex justify-end">
            <div className="flex gap-2">
              <Button type="button" variant="secondary" onClick={filterEventHistory}>
                {t("projects.event-history.filter-dialog.filter-event-history")}
              </Button>
              <Button type="submit">
                {t("projects.event-history.replay-events-dialog.confirm")}
              </Button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Set>
    </Dialog>
  );
}
