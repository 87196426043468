import * as React from "react";
import { Navigate } from "react-router-dom";

import Loadable from "@thisisbud/react-loadable";

import { createHref } from "../../../../../router";
import { demoSelectedProviderCookieName } from "../../../../../constants/cookies";
import { getCookie } from "../../../../../../client/utils/cookies";
import { loadingView } from "../../../loading";
import { RouteName } from "../../../../../router/constants";

/**
 * Lazily load the component.
 *
 * @returns The imported module
 */
async function loadComponent(): Promise<{ default: React.ComponentType }> {
  return import(/* webpackChunkName: 'views/demo/aggregation/consent' */ "./component");
}

/**
 * Lazily load the component for the aggregation consent view.
 */
export default function DemoConsentViewLoader(): React.ReactElement {
  const selectedProviderId = getCookie<string>(demoSelectedProviderCookieName);

  if (typeof selectedProviderId !== "string") {
    return (
      <Navigate
        replace={true}
        to={createHref({
          name: RouteName.demoAggregationSelectProvider,
        })}
      />
    );
  }

  return <Loadable fallback={loadingView} fallbackDelay={0} loadComponent={loadComponent} />;
}
