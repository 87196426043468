import Cookies from "js-cookie";

import { base64Decode, base64Encode } from "@thisisbud/base64-utils";

/**
 * Get the generic cookie options.
 *
 * @returns The options for the generic cookie
 */
function getCookieOptions(): Cookies.CookieAttributes {
  const location = window.location;

  return {
    domain: location.hostname,
    path: "/",
    sameSite: "lax",
    secure: location.protocol === "https:",
  };
}

/**
 * Get the data for a generic cookie.
 *
 * @returns The current data if available, or `undefined` if not
 */
export function getCookie<T>(cookieName: string): T | undefined {
  const cookieData = Cookies.get(cookieName);

  return typeof cookieData === "string" ? (JSON.parse(base64Decode(cookieData)) as T) : undefined;
}

/**
 * Sets a generic cookie.
 *
 * @param cookieName - The name of the cookie
 * @param data - The data to store in the cookie
 */
export function setCookie<T>(
  cookieName: string,
  data: T,
  options?: Cookies.CookieAttributes,
): void {
  Cookies.set(cookieName, base64Encode(JSON.stringify(data)), {
    ...getCookieOptions(),
    ...options,
  });
}

/**
 * Clears a generic cookie.
 *
 * @param cookieName - The name of the cookie
 */
export function clearCookie(cookieName: string): void {
  Cookies.remove(cookieName, getCookieOptions());
}
