import { getCompareFn } from "../../../utils/i18n";

import type { Customer } from "./api";
import type I18n from "@thisisbud/i18n";
import type { SortDirection } from "../../../types/sorting";

/**
 * Map a column by which customers are being sorted to a customer data property.
 *
 * @param sortedBy - The column by which customers are being sorted
 * @returns A customer property corresponding to the column being sorted
 */
function getSortedByMapping(sortedBy: string): keyof Customer {
  switch (sortedBy) {
    case "created-at":
      return "createdAt";
    default:
      return "id";
  }
}

/**
 * Sort a list of customers using a given sort direction and column.
 *
 * @param i18n - The i18n instance
 * @param customers - The list of customers to sort
 * @param sortDirection - The sort direction by which customers are to be sorted
 * @param sortedBy - The column by which customers are to be sorted
 * @returns A list of customers sorted by the given direction and property
 */
export function sortCustomers(
  i18n: I18n,
  customers: Customer[],
  sortDirection: SortDirection,
  sortedBy: string,
): Customer[] {
  const compareFn = getCompareFn(i18n);
  const mappedSortBy = getSortedByMapping(sortedBy);

  return [...customers].sort(function (a, b) {
    const propertyA = a[mappedSortBy] ?? "";
    const propertyB = b[mappedSortBy] ?? "";

    if (typeof propertyA === "string" && typeof propertyB === "string") {
      const result = compareFn(propertyA, propertyB);

      if (result !== 0) {
        return result * (sortDirection === "ascending" ? 1 : -1);
      }
    }

    if (sortedBy === "created-at") {
      if (propertyA > propertyB) {
        return sortDirection === "ascending" ? 1 : -1;
      } else if (propertyA < propertyB) {
        return sortDirection === "ascending" ? -1 : 1;
      }
    }

    return 0;
  });
}
