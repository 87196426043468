import React from "react";

import { getCompareFn } from "../../../../../utils/i18n";

import type I18n from "@thisisbud/i18n";
import type { Subscription } from "./api";
import type { SortDirection } from "../../../../../types/sorting";

export const formatEventDate = (date: Date, locale: string): string => {
  const dateFormatter = new Intl.DateTimeFormat(locale, {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });

  const timeFormatter = new Intl.DateTimeFormat(locale, {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });

  return `${timeFormatter.format(date)} ${dateFormatter.format(date)}`;
};

/**
 * Sorts an array of webhooks based on the specified sort direction and property.
 *
 * @param i18n - The internationalization object.
 * @param webhooks - The array of webhooks to be sorted.
 * @param sortDirection - The sort direction ('ascending' or 'descending').
 * @param sortedBy - The property to sort the webhooks by.
 * @returns The sorted array of webhooks.
 */
export function sortWebhooks(
  i18n: I18n,
  webhooks: Subscription[],
  sortDirection: SortDirection,
  sortedBy: keyof Subscription,
): Subscription[] {
  const compareFn = getCompareFn(i18n);

  return [...webhooks].sort(function (a, b) {
    const propertyA = a[sortedBy] ?? "";
    const propertyB = b[sortedBy] ?? "";

    if (typeof propertyA === "string" && typeof propertyB === "string") {
      const result = compareFn(propertyA, propertyB);

      if (result !== 0) {
        return result * (sortDirection === "ascending" ? 1 : -1);
      }
    }

    if (sortedBy === "lastModifiedAt") {
      if (propertyA > propertyB) {
        return sortDirection === "ascending" ? 1 : -1;
      } else if (propertyA < propertyB) {
        return sortDirection === "ascending" ? -1 : 1;
      }
    }

    return 0;
  });
}

/**
 * Renders an array of i18n elements into an array of React JSX elements.
 *
 * @param elements - The array of i18n elements to render.
 * @returns An array of React JSX elements.
 */
export const renderI18nElements = (elements: unknown[]): React.JSX.Element[] => {
  const combinedElements = [];
  let currentString = "";

  elements.forEach((element) => {
    if (typeof element === "string" || typeof element === "number") {
      currentString += String(element);
    } else {
      if (currentString) {
        combinedElements.push(currentString);
        currentString = "";
      }
      combinedElements.push(element);
    }
  });

  if (currentString) {
    combinedElements.push(currentString);
  }

  return combinedElements.map((element, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <React.Fragment key={index}>{element}</React.Fragment>
  ));
};
