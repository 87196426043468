import * as React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

import { useI18n } from "@thisisbud/i18n-react";

import { createHref } from "../../router";
import { appNavigationLinkActiveClassName, appNavigationLinkClassName } from "./constants";

import type { AppNavRoute } from "./utils";

function getRouteLinkClassNames(props: { isActive: boolean }): string {
  return props.isActive
    ? classNames(appNavigationLinkClassName, appNavigationLinkActiveClassName)
    : appNavigationLinkClassName;
}

export default function AppNavigationLink(props: AppNavRoute): React.ReactElement {
  const { labelKey, name, onClick } = props;
  const i18n = useI18n();

  return (
    <NavLink
      className={getRouteLinkClassNames}
      to={createHref({
        name,
      })}
      onClick={onClick}
    >
      {i18n.t(labelKey)}
    </NavLink>
  );
}
