import * as React from "react";

import { track } from "../../client/utils/tracking";
import CreateProjectDialog from "../components/create-project-dialog";
import Events from "../constants/events";

type Options = {
  screen?: string;
  onCreate?(): void;
  onCreated?(): Promise<void>;
};

/**
 * Use a dialog window to create a project.
 *
 * @returns An element to render the dialog and functions open and close
 */
export default function useCreateProjectDialog(options: Options = {}): {
  close(): void;
  dialog: React.ReactNode | null;
  open(event: React.MouseEvent<HTMLButtonElement>): void;
} {
  const { onCreate, onCreated, screen } = options;
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const open = React.useCallback(
    function () {
      track(Events.createProjectModalOpened, {
        screen,
      });

      setIsOpen(true);
    },
    [screen],
  );

  const close = React.useCallback(
    function () {
      track(Events.createProjectModalClosed, {
        screen,
      });

      setIsOpen(false);
    },
    [screen],
  );

  const onCreateCallbackProxy = React.useCallback(
    function () {
      track(Events.createProjectModalCreateClicked, {
        screen,
      });

      if (typeof onCreate === "function") {
        onCreate();
      }
    },
    [screen, onCreate],
  );

  const onCreatedCallbackProxy = React.useCallback(
    async function () {
      track(Events.apiCredentialsModalOpened, {
        screen,
      });

      if (typeof onCreated === "function") {
        await onCreated();
      }
    },
    [screen, onCreated],
  );

  return {
    close: close,
    dialog: isOpen ? (
      <CreateProjectDialog
        screen={screen}
        onClose={close}
        onCreate={onCreateCallbackProxy}
        onCreated={onCreatedCallbackProxy}
      />
    ) : null,
    open: open,
  };
}
