type Direction = "ascending" | "descending";

export function getSortedArray<TData>(
  array: readonly TData[],
  field: keyof TData,
  direction: Direction = "ascending",
): TData[] {
  const sortValueMultiplier = direction === "ascending" ? 1 : -1;

  return [...array].sort((a, b) => {
    if (a[field] < b[field]) {
      return -1 * sortValueMultiplier;
    }
    if (a[field] > b[field]) {
      return sortValueMultiplier;
    }
    return 0;
  });
}
