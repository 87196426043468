import * as React from "react";
import { useSelector } from "react-redux";

import Loadable from "@thisisbud/react-loadable";

import { loadingView } from "../loading";
import init from "./init";
import { getEnvironment } from "../../../store/environment";

import type { ProjectsData } from "./utils";
import type { LocalLoadableCtx } from "./types";

/**
 * Lazily load the component.
 *
 * @returns The imported module
 */
async function loadComponent(): Promise<{ default: React.ComponentType<{ data: ProjectsData }> }> {
  return import(/* webpackChunkName: 'views/projects' */ "./component");
}

/**
 * Lazily load the component and initialize data for the Projects view.
 */
export default function ProjectsViewLoader(): React.ReactElement {
  const currentEnvironment = useSelector(getEnvironment);

  const localCtx = React.useMemo(function (): LocalLoadableCtx {
    return {
      environment: currentEnvironment,
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Loadable
      ctx={localCtx}
      fallback={loadingView}
      fallbackDelay={0}
      init={init}
      loadComponent={loadComponent}
    />
  );
}
