import { Version, request } from "@thisisbud/internal-sdk";

import { Namespace } from "../constants";

type TokenValidationDetails = {
  token: string;
};

/**
 * Validate account password reset token
 *
 * @param details - The details to initiate the request
 */
export default async function validatePasswordRecoveryToken(
  details: TokenValidationDetails,
): Promise<void> {
  await request(Version.v1, Namespace.passwordRecovery, {
    body: {
      token: details.token,
    },
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    path: "/validate",
  });
}
