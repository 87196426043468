import * as React from "react";

import { useI18n } from "@thisisbud/i18n-react";
import { Dialog } from "@thisisbud/bui-react-dialog";
import { Form } from "@thisisbud/bui-react-form";
import { InputGroup } from "@thisisbud/bui-react-input-group";
import { Input } from "@thisisbud/bui-react-input";
import { Notification } from "@thisisbud/bui-react-notification";
import { Paragraph } from "@thisisbud/bui-react-typography";

import { usePageViewEvent } from "../../../../hooks/use-page-view-event";
import Events from "../../../../constants/events";
import DeleteCustomerDialogActions from "./actions";
import { useOnSubmit } from "./hooks";

type Props = {
  customerId: string;
  onClose(): void;
  onDeleted(): void;
};

const initialFormValues = {
  confirm: "",
};

export default function DeleteCustomerDialog(props: Props): React.ReactElement {
  const { customerId, onClose, onDeleted } = props;
  const i18n = useI18n();
  const { callback: onCustomerDelete, error } = useOnSubmit(customerId, onDeleted, onClose);

  usePageViewEvent(Events.customersModalView);

  return (
    <Dialog
      allowClose={true}
      closeLabel={i18n.t("common.close")}
      id="delete-customer-dialog"
      title={i18n.t("customers.delete-dialog.title")}
      onClose={onClose}
    >
      <Paragraph>
        {i18n.t("customers.delete-dialog.body.0")} {i18n.t("customers.delete-dialog.body.1")}&nbsp;
        {customerId}
      </Paragraph>
      <Paragraph>{i18n.t("customers.delete-dialog.body.2")}</Paragraph>
      <Form initialValues={initialFormValues} onSubmit={onCustomerDelete}>
        {typeof error !== "undefined" && (
          <Notification
            id="delete-customer-dialog-error"
            label={i18n.t("common.error")}
            type="error"
          >
            {error}
          </Notification>
        )}
        <InputGroup label={i18n.t("customers.delete-dialog.form.confirmation.label")}>
          <Input id="confirm" name="confirm" />
        </InputGroup>
        <Notification
          id="delete-customer-dialog-warning"
          label={i18n.t("common.warning")}
          type="warning"
        >
          {i18n.t("customers.delete-dialog.notification.warning")}
        </Notification>
        <DeleteCustomerDialogActions onClose={onClose} />
      </Form>
    </Dialog>
  );
}
