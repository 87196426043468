export const invalidSession = "invalid-session";

export const expiredSession = "expired-session";

export const invalidDetails = "invalid-details";

export const userAlreadyExists = "user-already-exists";

export const pageNotFound = "page-not-found";

export const internalServerErrror = "internal-server-error";
