import * as React from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

import { useI18n } from "@thisisbud/i18n-react";

import { getEnvironment } from "../../../store/environment";
import { formatBreadcrumbs, getProjectsData } from "./utils";
import { useConfig } from "../../../context/config";

import type { Breadcrumb } from "./types";
import type { HttpError } from "@thisisbud/internal-sdk";
import type { ProjectsData } from "./utils";

const defaultData: ProjectsData = {
  projects: [],
};

/**
 * Use the projects data of the user.
 *
 * @param initialData - The initial data to use before any updates occur
 * @returns The status, data and update function for the financial data
 */
export function useProjectsData(initialData?: ProjectsData): {
  busy: boolean;
  data: ProjectsData;
  error?: HttpError;
  refetch(): Promise<void>;
} {
  const { obSubscribableEventsSupportedEnvs, paymentsSubscribableEventsSupportedEnvs } =
    useConfig();

  const environment = useSelector(getEnvironment);

  const query = useQuery<ProjectsData, HttpError>({
    initialData: initialData,
    placeholderData: defaultData,
    queryFn: getProjectsData,
    queryKey: [
      "projects",
      environment,
      obSubscribableEventsSupportedEnvs,
      paymentsSubscribableEventsSupportedEnvs,
    ],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const refetch = React.useCallback(
    async function () {
      await query.refetch();
    },
    [query.refetch],
  );

  return {
    busy: query.isFetching,
    data: query.data ?? defaultData,
    error: query.error ?? undefined,
    refetch: refetch,
  };
}

export const useProjectsBreadcrumbs = (data: ProjectsData): Breadcrumb[] => {
  const { pathname } = useLocation();
  const { t } = useI18n();
  const breadcrumbs = React.useMemo(() => {
    const paths = pathname.split("/").filter(Boolean);

    return paths.map((path, index) => {
      const href = `/${paths.slice(0, index + 1).join("/")}`;
      return { href, path };
    });
  }, [pathname]);

  const projectName =
    data.projects.find((project) => project.clientId === breadcrumbs[1]?.path)?.name ?? "";
  const formattedBreadcrumbs = formatBreadcrumbs(breadcrumbs, projectName, t);

  return formattedBreadcrumbs;
};
