import React from "react";
import { useSelector } from "react-redux";

import { Button } from "@thisisbud/gds-ui/button";
import { Dialog } from "@thisisbud/gds-ui/dialog";
import { useI18n } from "@thisisbud/i18n-react";
import { Divider } from "@thisisbud/gds-ui/divider";

import {
  closeReplayHistoryDialog,
  getIsReplayHistoryDialogOpen,
} from "../../../../../../store/webhooks";
import { useAppDispatch } from "../../../../../../store";
import { formatEventDate } from "../utils";
import { useConfig } from "../../../../../../context/config";

type Props = {
  totalAmount: number;
};

// TODO: Remove mock data, once the endpoint is ready
const mockData = [
  {
    amount: 10402,
    date: new Date(),
    failed: 101,
    id: 1,
    sent: 10301,
  },
  {
    amount: 10402,
    date: new Date(),
    failed: 101,
    id: 2,
    sent: 10301,
  },
  {
    amount: 10402,
    date: new Date(),
    failed: 101,
    id: 3,
    sent: 10301,
  },
  {
    amount: 10402,
    date: new Date(),
    failed: 101,
    id: 4,
    sent: 10301,
  },
];

export function ReplayHistoryDialog({ totalAmount }: Props): React.ReactElement {
  const { t } = useI18n();
  const isOpen = useSelector(getIsReplayHistoryDialogOpen);
  const dispatch = useAppDispatch();
  const { defaultLocale } = useConfig();

  const onClose = React.useCallback(() => {
    dispatch(closeReplayHistoryDialog());
  }, [dispatch]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <Dialog.Set closeLabel={t("common.close")}>
        <Dialog.Title>
          {t("projects.event-history.replay-history-dialog.title", { amount: totalAmount })}
        </Dialog.Title>
        <Dialog.Description>
          {t("projects.event-history.replay-history-dialog.description")}
        </Dialog.Description>
        <Dialog.Content>
          <Divider />
          <div className="mt-4 flex flex-col gap-4">
            {mockData.map(({ id, date, amount, sent, failed }) => {
              return (
                <React.Fragment key={id}>
                  <div className="flex flex-col gap-2">
                    <div className="text-xs text-surface-body">
                      {formatEventDate(date, defaultLocale)}
                    </div>
                    <span className="font-semibold">
                      {t("projects.event-history.replay-history-dialog.events-replayed", {
                        amount: amount.toLocaleString(defaultLocale),
                      })}
                    </span>
                    <ul className="flex w-full items-center justify-between">
                      <li className="flex items-center gap-4">
                        <div className="text-sm">
                          <span className="text-state-positive-text">
                            {t("projects.event-history.replay-history-dialog.sent")}
                          </span>
                          {sent.toLocaleString(defaultLocale)}
                        </div>
                        <div className="size-[4px] rounded-full bg-surface-border" />
                        <div className="text-sm">
                          <span className="text-state-negative-text">
                            {t("projects.event-history.replay-history-dialog.failure")}
                          </span>
                          {failed}
                        </div>
                      </li>
                      <Button variant="text">
                        {t("projects.event-history.replay-history-dialog.view-events")}
                      </Button>
                    </ul>
                  </div>
                  <Divider />
                </React.Fragment>
              );
            })}
          </div>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Close asChild={true}>
            <Button type="button">{t("projects.event-history.replay-history-dialog.close")}</Button>
          </Dialog.Close>
        </Dialog.Actions>
      </Dialog.Set>
    </Dialog>
  );
}
