import { requireAuth } from "../../../../../client/utils/auth";
import { getOpenBankingDemoData } from "./utils/get-open-banking-demo-data";
import { getStaticDemoData } from "./utils/get-static-demo-data";
import { staticDemoId } from "../constants";

import type { ProviderMap } from "@thisisbud/internal-sdk";
import type { LoadableContext } from "@thisisbud/react-loadable";
import type { DemoAccount, DemoTransaction } from "../../../../api/types/entities";
import type { LocalLoadableContext } from ".";

export type InitData = {
  accounts: DemoAccount[];
  providers: ProviderMap;
  transactions: DemoTransaction[];
};

export default async function init(ctx: LoadableContext & LocalLoadableContext): Promise<InitData> {
  const { demoId } = ctx;
  const auth = await requireAuth();

  if (demoId === staticDemoId) {
    return getStaticDemoData(auth, ctx);
  }

  return getOpenBankingDemoData(auth, demoId);
}
