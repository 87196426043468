import * as React from "react";
import { useParams } from "react-router-dom";

import Loadable from "@thisisbud/react-loadable";

import { loadingView } from "../../loading";
import init from "./init";
import { staticDemoFallbackMinDuration, staticDemoId } from "../constants";
import { staticLoadingView } from "./static-loading";

import type { InitData } from "./init";

export type LocalLoadableContext = {
  demoId: string;
};

/**
 * Lazily load the component.
 *
 * @returns The imported module
 */
async function loadComponent(): Promise<{ default: React.ComponentType<{ data: InitData }> }> {
  return import(/* webpackChunkName: 'views/demo/dashboard' */ "./component");
}

/**
 * Lazily load the component for the Demo Dashboard view.
 */
export default function DemoDashboardViewLoader(): React.ReactElement {
  const { demoId } = useParams();

  const loadingFallback = React.useMemo(() => {
    return demoId === staticDemoId ? staticLoadingView : loadingView;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fallbackMinDuration = demoId === staticDemoId ? staticDemoFallbackMinDuration : 0;

  const localInitContext = React.useMemo(() => {
    return {
      demoId,
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Loadable
      ctx={localInitContext}
      fallback={loadingFallback}
      fallbackDelay={0}
      fallbackMinDuration={fallbackMinDuration}
      init={init}
      loadComponent={loadComponent}
    />
  );
}
