export const sessionCookieName = "developer-console-session";

export const demoSelectedProviderCookieName = "developer-console-demo-selected-provider";

export const companyCookieName = "developer-console-company";

export const envCookieName = "developer-console-env";

export const demoCustomerDetailsCookieName = "developer-console-demo-customer-details";

export const sessionIdCookieName = "developer-console-session-id";
