/* eslint-disable object-shorthand */

import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  getAllSubscribableWebhooks,
  getAllSubscribedEvents,
  getEventById,
  getEventHistoryByCategory,
  getRequestHistoryByEventId,
  getSingleRequestAttempt,
} from "./api";
import { getEnvironment } from "../../../../../../store/environment";
import { selectSingleEvent } from "./utils";
import { useConfig } from "../../../../../../context/config";
import { WebhookQueryKeys } from "./constants";

import type { UseInfiniteQueryResult, UseQueryResult } from "@tanstack/react-query";
import type {
  GetEventHistoryParams,
  SubscribableEvent,
  Subscription,
  SubscriptionCategory,
  WebhookEvent,
  WebhookEvents,
  WebhookRequest,
} from "./types";
import type { ProjectParams } from "../../types";
import type { HttpError } from "@thisisbud/internal-sdk";

export const useGetSubscribableEvents = (): UseQueryResult<SubscribableEvent[], HttpError> => {
  const { clientId } = useParams<keyof ProjectParams>() as ProjectParams;
  const currentEnvironment = useSelector(getEnvironment);
  const { obSubscribableEventsSupportedEnvs, paymentsSubscribableEventsSupportedEnvs } =
    useConfig();
  return useQuery<SubscribableEvent[], HttpError>({
    queryFn: async () =>
      getAllSubscribableWebhooks({
        currentEnvironment,
        obSubscribableEventsSupportedEnvs,
        paymentsSubscribableEventsSupportedEnvs,
      }),
    queryKey: [
      WebhookQueryKeys.subscribableEvents,
      currentEnvironment,
      clientId,
      obSubscribableEventsSupportedEnvs,
    ],
  });
};

export const useGetSubscribedEvents = (): UseQueryResult<Subscription[], HttpError> => {
  const { clientId } = useParams<keyof ProjectParams>() as ProjectParams;
  const currentEnvironment = useSelector(getEnvironment);
  const { obSubscribableEventsSupportedEnvs } = useConfig();

  return useQuery<Subscription[], HttpError>({
    queryFn: async () => getAllSubscribedEvents({ applicationId: clientId, currentEnvironment }),
    queryKey: [
      WebhookQueryKeys.subscribedEvents,
      currentEnvironment,
      clientId,
      obSubscribableEventsSupportedEnvs,
    ],
  });
};

export const useGetSingleEvent = (
  webhookId: string,
): UseQueryResult<Subscription | undefined, HttpError> => {
  const { clientId } = useParams<keyof ProjectParams>() as ProjectParams;
  const currentEnvironment = useSelector(getEnvironment);
  const { obSubscribableEventsSupportedEnvs } = useConfig();

  return useQuery<Subscription[], HttpError, Subscription | undefined>({
    queryFn: async () => getAllSubscribedEvents({ applicationId: clientId, currentEnvironment }),
    queryKey: [
      WebhookQueryKeys.subscribedEvents,
      currentEnvironment,
      clientId,
      obSubscribableEventsSupportedEnvs,
      webhookId,
    ],
    select: (data) => selectSingleEvent(data, webhookId),
  });
};

export const useGetWebhookEvents = ({
  pageSize,
  filters,
  category,
}: GetEventHistoryParams): UseInfiniteQueryResult<WebhookEvents, HttpError> => {
  const { clientId } = useParams<keyof ProjectParams>() as ProjectParams;
  const currentEnvironment = useSelector(getEnvironment);
  const { obSubscribableEventsSupportedEnvs } = useConfig();

  return useInfiniteQuery<WebhookEvents, HttpError>({
    getNextPageParam: (lastPage) => {
      return lastPage.metadata.nextPageToken;
    },
    refetchOnMount: true,
    queryFn: async ({ pageParam }) =>
      getEventHistoryByCategory({
        applicationId: clientId,
        category,
        currentEnvironment,
        filters,
        pageParam,
        pageSize,
      }),
    queryKey: [
      WebhookQueryKeys.webhookEventHistory,
      {
        category,
        clientId,
        currentEnvironment,
        filters,
        obSubscribableEventsSupportedEnvs,
      },
    ],
  });
};

export const useGetSingleWebhookEvent = (
  eventId: string,
  category: SubscriptionCategory,
  initialData: WebhookEvent,
): UseQueryResult<WebhookEvent | undefined, HttpError> => {
  const { clientId } = useParams<keyof ProjectParams>() as ProjectParams;
  const currentEnvironment = useSelector(getEnvironment);
  const { obSubscribableEventsSupportedEnvs } = useConfig();

  return useQuery<WebhookEvent, HttpError>({
    initialData,
    queryFn: async () =>
      getEventById({
        applicationId: clientId,
        category: category,
        currentEnvironment,
        id: eventId,
      }),
    queryKey: [
      WebhookQueryKeys.webhookEventHistory,
      {
        clientId,
        currentEnvironment,
        eventId,
        obSubscribableEventsSupportedEnvs,
      },
    ],
  });
};

export const useGetWebhookRequests = (
  category: SubscriptionCategory,
): UseQueryResult<WebhookRequest[], HttpError> => {
  const { clientId, eventId } = useParams<keyof ProjectParams>() as ProjectParams;
  const currentEnvironment = useSelector(getEnvironment);
  const { obSubscribableEventsSupportedEnvs } = useConfig();

  return useQuery<WebhookRequest[], HttpError>({
    queryFn: async () =>
      getRequestHistoryByEventId({
        applicationId: clientId,
        category: category,
        currentEnvironment,
        eventId,
      }),
    queryKey: [
      WebhookQueryKeys.webhookRequestsHistory,
      currentEnvironment,
      clientId,
      obSubscribableEventsSupportedEnvs,
      eventId,
    ],
  });
};

export const useGetSingleWebhookRequest = (
  category: SubscriptionCategory,
  initialData: WebhookRequest,
): UseQueryResult<WebhookRequest | undefined, HttpError> => {
  const { clientId, requestId } = useParams<keyof ProjectParams>() as ProjectParams;
  const currentEnvironment = useSelector(getEnvironment);
  const { obSubscribableEventsSupportedEnvs } = useConfig();

  return useQuery({
    initialData,
    queryFn: async () =>
      getSingleRequestAttempt({
        applicationId: clientId,
        category,
        currentEnvironment,
        requestId,
      }),
    queryKey: [
      WebhookQueryKeys.webhookRequestsHistory,
      currentEnvironment,
      clientId,
      obSubscribableEventsSupportedEnvs,
      requestId,
    ],
  });
};
