import * as React from "react";

import { useI18n } from "@thisisbud/i18n-react";
import { toLocalDateString } from "@thisisbud/datetime-utils";
import { Button } from "@thisisbud/bui-react-buttons";
import { Form } from "@thisisbud/bui-react-form";
import { InputGroup } from "@thisisbud/bui-react-input-group";
import { Input } from "@thisisbud/bui-react-input";
import { Notification } from "@thisisbud/bui-react-notification";
import { Paragraph } from "@thisisbud/bui-react-typography";

import { downloadJson } from "../../utils/download-json";
import DialogFooter from "../dialog-footer";
import { track } from "../../../client/utils/tracking";
import Events from "../../constants/events";

import type { CreatedProject } from "../../api/types/entities";

// TODO: temporarily passing screen down as props. Refactor needed to allow using a screen slug
type Props = {
  project: CreatedProject;
  screen?: string;
};

const detailsFileNamePart = "bud-client-details";

export default function CreateDialogConfirmation(props: Props): React.ReactElement {
  const { project, screen } = props;
  const i18n = useI18n();

  const onDownloadDetails = React.useCallback(
    function () {
      track(Events.apiCredentialsModalDownloadClicked, {
        screen,
      });

      const now = new Date();
      const dateString = toLocalDateString(now).replace(/-/g, "");
      const timeString = now.toTimeString().slice(0, 8).replace(/:/g, "");
      const filename = `${detailsFileNamePart}-${dateString}${timeString}`;

      downloadJson(project.raw, filename);
    },
    [project, screen],
  );

  return (
    <React.Fragment>
      <Paragraph>{i18n.t("create-project-dialog.confirmation.body")}</Paragraph>
      <Form initialValues={project}>
        <InputGroup label={i18n.t("create-project-dialog.confirmation.form.name.label")}>
          <Input id="created-project-name" name="name" readOnly={true} />
        </InputGroup>
        <InputGroup label={i18n.t("create-project-dialog.confirmation.form.client-id.label")}>
          <Input id="client-id" name="clientId" readOnly={true} />
        </InputGroup>
        <InputGroup label={i18n.t("create-project-dialog.confirmation.form.client-secret.label")}>
          <Input id="client-secret" name="clientSecret" readOnly={true} />
        </InputGroup>
        <Notification
          id="create-project-confirmation-dialog-warning"
          label={i18n.t("common.warning")}
          type="warning"
        >
          {i18n.t("create-project-dialog.confirmation.notification.warning")}
        </Notification>
        <DialogFooter>
          <Button id="create-project-confirmation-dialog-submit-button" onClick={onDownloadDetails}>
            {i18n.t("create-project-dialog.confirmation.form.submit")}
          </Button>
        </DialogFooter>
      </Form>
    </React.Fragment>
  );
}
