import * as React from "react";

import type { ProviderMap } from "@thisisbud/internal-sdk";
import type { DemoAccount, DemoTransaction } from "../../../../api/types/entities";

export type DemoDashboardData = {
  accounts: DemoAccount[];
  transactions: DemoTransaction[];
  selectedAccount: string;
  providers: ProviderMap;
};

export type DemoDashboardProps = {
  readonly value: DemoDashboardData;
};

const DemoDashboardDataContext = React.createContext<DemoDashboardData>(
  null as unknown as DemoDashboardData,
);

/**
 * Provides demo dashboard data for use in React components.
 *
 * @param props - Provider props including the demo dashboard data to provide to consumers of the DemoDashboardData context
 */
export function DemoDashboardDataProvider(
  props: React.PropsWithChildren<DemoDashboardProps>,
): React.ReactElement {
  const { children, value } = props;

  return (
    <DemoDashboardDataContext.Provider value={value}>{children}</DemoDashboardDataContext.Provider>
  );
}

DemoDashboardDataProvider.displayName = "DemoDashboardDataProvider";

/**
 * Use the demo dashboard data provided by the closest DemoDashboardData provider above.
 *
 * @returns The demo dashboard data currently provided
 */
export function useDemoDashboardData(): DemoDashboardData {
  return React.useContext(DemoDashboardDataContext);
}
