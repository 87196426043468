import * as React from "react";
import { useLocation } from "react-router-dom";

import { useI18n } from "@thisisbud/i18n-react";
import { ChevronRight } from "@thisisbud/gds-ui/icon";
import { Link } from "@thisisbud/gds-ui/link";

import { RouteName } from "../../../router/constants";
import { createHref } from "../../../router";
import RouterLink from "../../router-link";

export default function ContinueButton(): React.ReactElement {
  const i18n = useI18n();
  const location = useLocation();

  const continueHref = createHref({
    name: RouteName.home,
  });

  const onClick = React.useCallback(
    function (e: React.MouseEvent): void {
      const helper = document.createElement("a");

      helper.href = continueHref;

      // Reload page if the current page is the same as the destination
      if (location.pathname === helper.pathname) {
        e.preventDefault();

        window.location.reload();
      }
    },
    [continueHref, location],
  );

  return (
    <RouterLink
      as={Link}
      endIcon={<ChevronRight />}
      href={continueHref}
      id="error-screen-continue-btn"
      onClick={onClick}
    >
      {i18n.t("error.links.to-home")}
    </RouterLink>
  );
}
