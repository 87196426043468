// TODO: move to bui-react as an option for bud logo

import * as React from "react";
import styled from "styled-components";

import { selectFromTheme } from "@thisisbud/sc-utils";

const StyledLogo = styled.span`
  svg {
    height: ${selectFromTheme("spacingSmallDefault")};
  }
`;

export default function BudLogo(): React.ReactElement {
  return (
    <StyledLogo>
      <svg fill="none" viewBox="0 0 64 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M56 15.308v6.617c0 .926-.725 1.654-1.647 1.654H1.647A1.634 1.634 0 0 1 0 21.925c0-.893.725-1.654 1.647-1.654H51.06c.923 0 1.647-.728 1.647-1.654 0-.894-.724-1.655-1.647-1.655H1.647A1.635 1.635 0 0 1 0 15.308V8.692c0-.894.725-1.654 1.647-1.654H51.06c.923 0 1.647-.728 1.647-1.655 0-.893-.724-1.654-1.647-1.654H1.647A1.635 1.635 0 0 1 0 2.075C0 1.182.725.421 1.647.421h52.706c.922 0 1.647.76 1.647 1.654v6.617c0 .926-.725 1.654-1.647 1.654H4.94c-.922 0-1.647.76-1.647 1.654 0 .926.725 1.654 1.647 1.654h49.412c.922 0 1.647.761 1.647 1.654Z"
          fill="currentColor"
        />
      </svg>
    </StyledLogo>
  );
}
