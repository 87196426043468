/* eslint-disable no-useless-escape */
const emailValidationRegex =
  /^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i;
const urlWithPathRegex =
  /^((?:(https?):\/\/)((?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[0-9][0-9]|[0-9])\.(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[0-9][0-9]|[0-9])\.)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[0-9][0-9]|[0-9])\.)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[0-9][0-9]|[0-9]))|(?:(?:(?:[a-zA-Z0-9-_]+[a-zA-Z0-9]+\.)+[\w]{2,63})))(?::(\d+))?((?:\/|\/[a-zA-Z0-9-_]+)+)(?:\/[a-zA-Z0-9-_]+|(\/?[a-zA-Z0-9-_]+\.[a-zA-Z0-9-_]{3,4})|(\?(?:([a-zA-Z0-9-_]+=[a-zA-Z0-9-_]+)&)*([a-zA-Z0-9-_]+=[a-zA-Z0-9-_#]+))?|\?(?:[\w])))$/;

/**
 * Test if a string is empty.
 *
 * @param str - The string to test
 * @returns A boolean indicating if the string is empty
 */
export function isEmptyString(str: string): boolean {
  return str.trim().length === 0;
}

/**
 * Validate that a string is an email address.
 *
 * @param str - The string to test
 * @returns A boolean indicating if the email is valid
 */
export function isValidEmail(str: string): boolean {
  return emailValidationRegex.test(str);
}

/**
 * Validate that a string is a valid URL with a path
 *
 * @param str - The string to test
 * @returns A boolean indicating if the url is valid with a path
 */
export function isValidUrlWithPath(str: string): boolean {
  return urlWithPathRegex.test(str);
}

/**
 * Check if a string is a valid UUID.
 * @param value - The string to check.
 * @returns A boolean value indicating whether the string is a valid UUID.
 */
export function isUUID(value: string): boolean {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidRegex.test(value);
}
