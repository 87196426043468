import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import z from "zod";

import { getEnvironment } from "../../../../store/environment";
import { getCustomerById, getCustomers } from "./api";
import { CustomerQueryKeys } from "./constants";

import type { Customer, CustomerData, UseGetCustomerById, UseGetCustomers } from "./types";
import type { HttpError } from "@thisisbud/internal-sdk";
import type { UseQueryResult } from "@tanstack/react-query";

export const useGetCustomers = ({
  options,
}: UseGetCustomers): UseQueryResult<CustomerData, HttpError> => {
  const environment = useSelector(getEnvironment);

  return useQuery({
    queryFn: async () => getCustomers({ environment, options }),
    queryKey: [CustomerQueryKeys.customers, { page: options.page, pageSize: options.pageSize }],
  });
};

export const useGetCustomerById = ({
  customerId,
}: UseGetCustomerById): UseQueryResult<Customer[], HttpError> => {
  const environment = useSelector(getEnvironment);
  const formattedCustomerId = z.string().trim().uuid().safeParse(customerId);

  return useQuery({
    enabled: formattedCustomerId.success,
    queryFn: async () => getCustomerById({ customerId, environment }),
    queryKey: [CustomerQueryKeys.customers, { customerId: formattedCustomerId }],
    retry: false,
  });
};
