import { parseDate } from "@thisisbud/datetime-utils";

import type { GenericResponse } from "@thisisbud/internal-sdk";
import type { Project } from "../types/responses";
import type { Project as ProjectEntity } from "../types/entities";

export type ApiResponse = GenericResponse<Project[] | null>;

/**
 * Formats a GET Projects API response.
 *
 * @param res - The response received from the projects endpoint
 * @returns A list of projects
 * @public
 */
export function formatGetProjectsResponse(response: ApiResponse): ProjectEntity[] {
  const { data } = response;

  if (data === null || data.length === 0) {
    return [];
  }

  return data.map(function (project) {
    return {
      clientId: project.client_id,
      createdAt: parseDate(project.created_at),
      name: project.label.length ? project.label : undefined,
    };
  });
}
