import { Version, getHost, request } from "@thisisbud/internal-sdk";

import { requireAuth } from "../../../../../../../client/utils/auth";
import {
  buildEventsQueryString,
  formatSingleWebhookEvent,
  formatSubscribableEvents,
  formatSubscribedEvents,
  formatWebhookEvents,
  formatWebhookRequest,
  formatWebhookRequests,
} from "./utils";
import { Namespace } from "../../../../../../api/constants";

import type { TargetEnvironment } from "../../../../../../types/environment";
import type * as types from "./types";
import type { GenericResponse } from "@thisisbud/internal-sdk";

const getSubscribableWebhooks = async (
  currentEnvironment: TargetEnvironment,
): Promise<string[]> => {
  const auth = await requireAuth();

  const { data } = await request<GenericResponse<string[]>>(Namespace.webhooks, Version.v1, {
    auth: auth,
    headers: {
      "Content-Type": "application/json",
      "X-Logged-User": auth.userId,
      "X-User-Env": currentEnvironment,
    },
    path: "/subscribable-events",
  });

  return data;
};

const getSubscribablePaymentWebhooks = async (
  currentEnvironment: TargetEnvironment,
): Promise<string[]> => {
  const auth = await requireAuth();

  try {
    const { data } = await request<GenericResponse<string[]>>(
      Namespace.paymentWebhooks,
      Version.v1,
      {
        auth: auth,
        headers: {
          "Content-Type": "application/json",
          "X-Logged-User": auth.userId,
          "X-User-Env": currentEnvironment,
        },
        path: "/subscribable-events",
        retries: 0,
      },
    );

    return data;
  } catch {
    return [];
  }
};

export const getAllSubscribableWebhooks = async (
  params: types.GetAllSubscribableWebhooks,
): Promise<types.SubscribableEvent[]> => {
  const {
    currentEnvironment,
    obSubscribableEventsSupportedEnvs,
    paymentsSubscribableEventsSupportedEnvs,
  } = params;
  const [obEvents, paymentEvents] = await Promise.all([
    getSubscribableWebhooks(currentEnvironment),
    getSubscribablePaymentWebhooks(currentEnvironment),
  ]);
  const areObEventsSupported = obSubscribableEventsSupportedEnvs.includes(currentEnvironment);
  const arePaymentEventsSupported =
    paymentsSubscribableEventsSupportedEnvs.includes(currentEnvironment);

  const formattedObEvents = areObEventsSupported
    ? formatSubscribableEvents(obEvents, "open-banking")
    : [];
  const formattedPaymentEvents = arePaymentEventsSupported
    ? formatSubscribableEvents(paymentEvents, "payments")
    : [];

  return [...formattedObEvents, ...formattedPaymentEvents];
};

const getSubscribedEvents = async ({
  applicationId,
  currentEnvironment,
}: types.GetSubscribedEvents): Promise<types.SubscriptionResponse[]> => {
  const auth = await requireAuth();

  const { data } = await request<GenericResponse<types.SubscriptionResponse[]>>(
    Namespace.webhooks,
    Version.v1,
    {
      auth: auth,
      headers: {
        "Content-Type": "application/json",
        "X-Logged-User": auth.userId,
        "X-User-Env": currentEnvironment,
      },
      path: `/subscriptions/application/${applicationId}?status=all`,
    },
  );

  return data;
};

const getSubscribedPaymentEvents = async ({
  applicationId,
  currentEnvironment,
}: types.GetSubscribedEvents): Promise<types.SubscriptionResponse[]> => {
  const auth = await requireAuth();

  try {
    const { data } = await request<GenericResponse<types.SubscriptionResponse[]>>(
      Namespace.paymentWebhooks,
      Version.v1,
      {
        auth: auth,
        headers: {
          "Content-Type": "application/json",
          "X-Logged-User": auth.userId,
          "X-User-Env": currentEnvironment,
        },
        path: `/subscriptions/application/${applicationId}?status=all`,
        retries: 0,
      },
    );

    return data;
  } catch {
    return [];
  }
};

export const getAllSubscribedEvents = async ({
  applicationId,
  currentEnvironment,
}: types.GetSubscribedEvents): Promise<types.Subscription[]> => {
  const [events, paymentEvents] = await Promise.all([
    getSubscribedEvents({ applicationId, currentEnvironment }),
    getSubscribedPaymentEvents({ applicationId, currentEnvironment }),
  ]);

  return [
    ...formatSubscribedEvents(events, "open-banking"),
    ...formatSubscribedEvents(paymentEvents, "payments"),
  ];
};

export const createSubscription = async ({
  applicationId,
  eventType,
  webhookUrl,
  currentEnvironment,
  optionalSettings,
  category,
}: types.CreateSubscription): Promise<void> => {
  const auth = await requireAuth();

  const nameSpace = category === "open-banking" ? Namespace.webhooks : Namespace.paymentWebhooks;

  await request(nameSpace, Version.v1, {
    auth: auth,
    body: {
      encryption_key: optionalSettings?.encryptionKey,
      endpoint: webhookUrl,
      endpoint_headers: optionalSettings?.headers,
      event_type: eventType,
      signing_token: optionalSettings?.signingToken,
    },
    headers: {
      "Content-Type": "application/json",
      "X-Logged-User": auth.userId,
      "X-User-Env": currentEnvironment,
    },
    method: "POST",
    path: `/subscriptions/create/application/${applicationId}`,
  });
};

export const toggleSubscriptionStatus = async ({
  applicationId,
  eventType,
  currentEnvironment,
  action,
  category,
}: types.ToggleSubscriptionStatus): Promise<void> => {
  const auth = await requireAuth();

  const nameSpace = category === "open-banking" ? Namespace.webhooks : Namespace.paymentWebhooks;

  await request(nameSpace, Version.v1, {
    auth: auth,
    body: {
      event_type: eventType,
    },
    headers: {
      "Content-Type": "application/json",
      "X-Logged-User": auth.userId,
      "X-User-Env": currentEnvironment,
    },
    method: "PUT",
    path: `/subscriptions/${action}/application/${applicationId}`,
  });
};

export const sendTestEvent = async ({
  applicationId,
  eventType,
  currentEnvironment,
  category,
}: types.SendTestEvent): Promise<Response> => {
  const auth = await requireAuth();
  const host = getHost();
  const nameSpace = category === "open-banking" ? "webhooks/v1" : "payments/webhooks/v1";

  try {
    const response = await fetch(
      `${host}${nameSpace}/events/application/${applicationId}/test-event/${eventType}`,
      {
        headers: {
          Authorization: `Bearer ${auth.accessToken ?? ""}`,
          "Content-Type": "application/json",
          "X-Logged-User": auth.userId,
          "X-User-Env": currentEnvironment,
          "X-User-Id": auth.userId,
        },
        method: "POST",
      },
    );

    if (!response.ok) {
      throw new Error("Failed to send test event");
    }

    return response;
  } catch {
    throw new Error("Failed to send test event");
  }
};

const getEventHistory = async ({
  currentEnvironment,
  applicationId,
  pageParam,
  pageSize,
  filters,
}: types.GetEventHistory): Promise<{
  data: types.WebhookEventResponse[];
  metadata: types.MetadataResponse;
}> => {
  const auth = await requireAuth();

  const queryString = buildEventsQueryString({ applicationId, filters, pageParam, pageSize });

  const { data, metadata } = await request<
    GenericResponse<types.WebhookEventResponse[], types.MetadataResponse>
  >(Namespace.webhooks, Version.v1, {
    auth: auth,
    headers: {
      "Content-Type": "application/json",
      "X-Logged-User": auth.userId,
      "X-User-Env": currentEnvironment,
    },
    path: `events/organisation${queryString}`,
  });

  return { data, metadata };
};

const getPaymentEventHistory = async ({
  currentEnvironment,
  applicationId,
  pageParam,
  pageSize,
  filters,
}: types.GetEventHistory): Promise<{
  data: types.WebhookEventResponse[];
  metadata: types.MetadataResponse;
}> => {
  const auth = await requireAuth();

  const queryString = buildEventsQueryString({ applicationId, filters, pageParam, pageSize });

  const { data, metadata } = await request<
    GenericResponse<types.WebhookEventResponse[], types.MetadataResponse>
  >(Namespace.paymentWebhooks, Version.v1, {
    auth: auth,
    headers: {
      "Content-Type": "application/json",
      "X-Logged-User": auth.userId,
      "X-User-Env": currentEnvironment,
    },
    path: `events/organisation${queryString}`,
    retries: 0,
  });

  return { data, metadata };
};

export const getEventHistoryByCategory = async ({
  currentEnvironment,
  applicationId,
  pageParam,
  pageSize,
  filters,
  category,
}: types.GetEventHistoryByCategory): Promise<types.WebhookEvents> => {
  const eventHistoryFetcher =
    category === "open-banking" ? getEventHistory : getPaymentEventHistory;

  const { data, metadata } = await eventHistoryFetcher({
    applicationId,
    currentEnvironment,
    filters,
    pageParam,
    pageSize,
  });

  return formatWebhookEvents(data, metadata);
};

const fetchEventById = async ({
  currentEnvironment,
  applicationId,
  id,
  nameSpace,
}: types.GetEventById): Promise<types.WebhookEventResponse> => {
  const auth = await requireAuth();

  const { data } = await request<GenericResponse<types.WebhookEventResponse>>(
    nameSpace ?? Namespace.webhooks,
    Version.v1,
    {
      auth: auth,
      headers: {
        "Content-Type": "application/json",
        "X-Logged-User": auth.userId,
        "X-User-Env": currentEnvironment,
      },
      path: `events/application/${applicationId}/event/${id}`,
      retries: 0,
    },
  );
  return data;
};

export const getEventById = async ({
  currentEnvironment,
  applicationId,
  id,
  category,
}: types.GetEventById): Promise<types.WebhookEvent> => {
  const nameSpace = category === "open-banking" ? Namespace.webhooks : Namespace.paymentWebhooks;
  const data = await fetchEventById({
    applicationId,
    category,
    currentEnvironment,
    id,
    nameSpace,
  });

  return formatSingleWebhookEvent(data);
};

export const getRequestHistoryByEventId = async ({
  currentEnvironment,
  applicationId,
  eventId,
  category,
}: types.GetRequestHistoryByEventId): Promise<types.WebhookRequest[]> => {
  const auth = await requireAuth();
  const nameSpace = category === "open-banking" ? Namespace.webhooks : Namespace.paymentWebhooks;

  const { data } = await request<GenericResponse<types.WebhookRequestResponse[]>>(
    nameSpace,
    Version.v1,
    {
      auth: auth,
      headers: {
        "Content-Type": "application/json",
        "X-Logged-User": auth.userId,
        "X-User-Env": currentEnvironment,
      },
      path: `/requests/application/${applicationId}/event/${eventId}`,
    },
  );

  return formatWebhookRequests(data);
};

export const getSingleRequestAttempt = async ({
  currentEnvironment,
  applicationId,
  requestId,
  category,
}: types.GetSingleRequestAttempt): Promise<types.WebhookRequest> => {
  const auth = await requireAuth();
  const nameSpace = category === "open-banking" ? Namespace.webhooks : Namespace.paymentWebhooks;

  const { data } = await request<GenericResponse<types.WebhookRequestResponse>>(
    nameSpace,
    Version.v1,
    {
      auth: auth,
      headers: {
        "Content-Type": "application/json",
        "X-Logged-User": auth.userId,
        "X-User-Env": currentEnvironment,
      },
      path: `/requests/application/${applicationId}/attempt/${requestId}`,
    },
  );

  return formatWebhookRequest(data);
};

export const deleteSubscription = async ({
  applicationId,
  eventType,
  currentEnvironment,
  category,
}: types.DeleteSubscription): Promise<void> => {
  const auth = await requireAuth();
  const nameSpace = category === "open-banking" ? Namespace.webhooks : Namespace.paymentWebhooks;

  await request(nameSpace, Version.v1, {
    auth: auth,
    headers: {
      "Content-Type": "application/json",
      "X-Logged-User": auth.userId,
      "X-User-Env": currentEnvironment,
    },
    method: "DELETE",
    path: `/subscriptions/application/${applicationId}/event-type/${eventType}`,
  });
};
