import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { useDebounce } from "react-use";

import { useI18n } from "@thisisbud/i18n-react";
import { AccessibleContent } from "@thisisbud/bui-react-accessible-content";
import { TextButton } from "@thisisbud/bui-react-buttons";
import { FormattedDate } from "@thisisbud/bui-react-formatted-date";

import { track } from "../../../../client/utils/tracking";
import Events from "../../../constants/events";
import { sortCustomers } from "./utils";
import DeleteCustomerDialog from "./delete-dialog";
import { useGetCustomerById, useGetCustomers } from "./api";
import {
  allowedSortKeys,
  debounceDelay,
  defaultSortParams,
  pageParam,
  pageSize,
} from "./constants";
import { useSetLocationParams } from "../../../hooks/use-set-location-params";
import { useSorting } from "../../../hooks/use-sorting";

import type { Customer, CustomerData } from "./api";
import type { SortDirection } from "../../../types/sorting";
import type {
  TableColumn,
  TableDataCell,
  TableDataOptions,
  UseCustomerListArgs,
  UserCustomerList,
} from "./types";

/**
 * Use the configuration for the table's columns.
 *
 * @returns The table column configuration
 */
export function useTableColumns(): TableColumn[] {
  const i18n = useI18n();

  return React.useMemo(
    function () {
      return [
        { key: "customer-id", sortable: true, title: i18n.t("customers.list.column.customer-id") },
        { key: "created-at", sortable: true, title: i18n.t("customers.list.column.created-at") },
        { key: "options", textAlign: "right", title: i18n.t("customers.list.column.options") },
      ];
    },
    [i18n],
  );
}

/**
 * Use the configuration for the table data.
 *
 * @param customers - The customers list
 * @param options - The table data options
 * @returns The table data configuration
 */
export function useTableData(customers: Customer[], options: TableDataOptions): TableDataCell[][] {
  const { filter, onDeleteClick } = options;
  const i18n = useI18n();

  return React.useMemo(
    function () {
      if (!customers.length) {
        return [
          [
            {
              content:
                filter === ""
                  ? i18n.t("customers.list.empty")
                  : i18n.t("customers.list.no-matching-filter"),
              span: 4,
            },
          ],
        ];
      }

      return customers.map(function (customer) {
        return [
          customer.id,
          typeof customer.createdAt !== "undefined" ? (
            <FormattedDate date={customer.createdAt} key={`${customer.id}-formatted-date`} />
          ) : (
            <AccessibleContent
              accessibleAlternative={i18n.t("customers.list.created-at-unavailable")}
            >
              -
            </AccessibleContent>
          ),
          <TextButton
            align="right"
            id={`${customer.id}-delete-button`}
            key={`${customer.id}-delete`}
            value={customer.id}
            onClick={onDeleteClick}
          >
            {i18n.t("customers.list.actions.delete.label")}
          </TextButton>,
        ];
      });
    },
    [i18n, customers, filter, onDeleteClick],
  );
}

/**
 * Use a dialog to confirm deletion of a customer.
 *
 * @param onCustomerDeleted - A function to be called when a customer has been deleted
 * @returns The dialog component and a function to be called after customer deletion
 */
export function useCustomerDeleteDialog(onCustomerDeleted: () => void): {
  dialog: React.ReactNode;
  onCustomerDelete(event: React.MouseEvent<HTMLButtonElement>): void;
} {
  const [customerId, setCustomerId] = React.useState<string | undefined>();

  const onCustomerDelete = React.useCallback(function (e: React.MouseEvent<HTMLButtonElement>) {
    track(Events.customersPageDeleteClicked);
    const { value } = e.currentTarget;
    setCustomerId(value);
  }, []);

  const onDialogClose = React.useCallback(function () {
    track(Events.customersModalClosed);
    setCustomerId(undefined);
  }, []);

  return {
    dialog:
      typeof customerId !== "undefined" ? (
        <DeleteCustomerDialog
          customerId={customerId}
          onClose={onDialogClose}
          onDeleted={onCustomerDeleted}
        />
      ) : null,
    onCustomerDelete: onCustomerDelete,
  };
}

/**
 * Use a list of sorted customers.
 *
 * @param customers - The list of customers to sort
 * @param sortDirection - The sort direction by which customers are to be sorted
 * @param sortedBy - The column by which customers are to be sorted
 * @returns A list of sorted customers
 */
export function useSortedCustomers(
  customers: Customer[],
  sortDirection: SortDirection,
  sortedBy: string,
): Customer[] {
  const i18n = useI18n();

  return React.useMemo(
    function () {
      return sortCustomers(i18n, customers, sortDirection, sortedBy);
    },
    [i18n, sortDirection, sortedBy, customers],
  );
}

/**
 * Use a callback function to provide a button label callback.
 *
 * @returns A callback function to generate button label
 */
export function useGetSortButtonLabel(): (key: string, direction: string) => string {
  const i18n = useI18n();

  return React.useCallback(
    function (key: string, direction: string) {
      const columnName = i18n.t(`customers.list.column.${key}`);
      const directionName = i18n.t(`common.sort-direction.${direction}`);

      return i18n.t("common.sort-by", {
        direction: directionName,
        property: columnName,
      });
    },
    [i18n],
  );
}

/**
 * Use the current value of the page search parameter.
 *
 * @returns The current value of the page parameter or 1
 */
export function usePageValue(): number {
  const [searchParams] = useSearchParams();

  const pageValue = searchParams.get("page");
  const pageValueAsNum = Number(pageValue);

  return pageValueAsNum !== 0 && !isNaN(pageValueAsNum) ? pageValueAsNum : 1;
}

type UseCustomerData = {
  data?: CustomerData;
  pageSize: number;
  isLoading: boolean;
  totalAmount?: number;
};

/**
 * Use the customer data of the user.
 *
 * @param initialData - The initial data to use before any updates occur
 * @returns The status, data and update function for the financial data
 */
export function useCustomerData(): UseCustomerData {
  const pageValue = usePageValue();

  const { data, isLoading } = useGetCustomers({
    options: { page: pageValue, pageSize: pageSize },
  });

  return {
    data: data,
    isLoading: isLoading,
    pageSize: pageSize,
    totalAmount: data?.totalAmount,
  };
}

/**
 * Custom hook for managing customer list functionality.
 *
 * @param customers - The list of customers.
 * @param page - The current page number.
 * @param pageSize - The number of customers to display per page.
 * @param totalAmount - The total number of customers.
 * @returns An object containing the necessary data and functions for managing the customer list.
 */
export function useCustomerList({
  customers,
  page,
  totalAmount,
}: UseCustomerListArgs): UserCustomerList {
  const [filter, setFilter] = React.useState("");
  const [debouncedFilter, setDebouncedFilter] = React.useState("");
  useDebounce(
    () => {
      setDebouncedFilter(filter);
    },
    debounceDelay,
    [filter],
  );
  const resetFilter = React.useCallback(() => {
    setFilter("");
  }, [setFilter]);
  const i18n = useI18n();
  const setLocationParams = useSetLocationParams();
  const { onCustomerDelete, dialog } = useCustomerDeleteDialog(resetFilter);
  const { onSortChange, sortDirection, sortedBy } = useSorting(allowedSortKeys, defaultSortParams);
  const columns = useTableColumns();
  const sortedCustomers = useSortedCustomers(customers, sortDirection, sortedBy);

  const { data: filteredCustomer, isFetching: isLoading } = useGetCustomerById({
    customerId: debouncedFilter,
  });

  const data = useTableData(Boolean(debouncedFilter) ? filteredCustomer ?? [] : sortedCustomers, {
    filter: debouncedFilter,
    onDeleteClick: onCustomerDelete,
  });

  const onSearch = React.useCallback(
    function (value: string) {
      setFilter(value);
    },
    [setFilter],
  );

  const totalPageCount = Math.ceil(totalAmount / pageSize);

  // TODO: can be passed as an optional label
  const customerRangeLabel = React.useMemo(
    function () {
      return i18n.tToParts("customers.list.pagination.range", {
        firstCustomerRange: totalAmount === 0 ? 0 : (page - 1) * pageSize + 1, // eslint-disable-line @typescript-eslint/no-extra-parens
        lastCustomerRange: page * pageSize > totalAmount ? totalAmount : page * pageSize,
        renderBold: function (content: string) {
          return <strong key={content}>{content}</strong>;
        },
        totalAmount: totalAmount,
      }) as React.ReactNode[];
    },
    [i18n, page, totalAmount],
  );

  const onPageChange = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const { value } = e.currentTarget;

      let nextPage: number;

      if (value === "next") {
        nextPage = page < totalPageCount ? page + 1 : page;
      } else if (value === "prev") {
        nextPage = page > 1 ? page - 1 : page;
      } else {
        nextPage = Number(value);
      }

      setLocationParams({
        [pageParam]: nextPage,
      });

      // TODO: this should not be tracked in state
      setFilter("");
    },
    [page, totalPageCount, setLocationParams],
  );

  return {
    columns,
    customerRangeLabel,
    data,
    dialog,
    filter,
    isLoading,
    onPageChange,
    onSearch,
    onSortChange,
    sortDirection,
    sortedBy,
    totalPageCount,
  };
}
