import * as React from "react";
import { Navigate } from "react-router-dom";

import { createRouter } from "@thisisbud/react-utils";
import { HttpError } from "@thisisbud/internal-sdk";

import { basePath } from "../constants/paths";
import Login from "../components/views/login";
import Signup from "../components/views/signup";
import ResetPassword from "../components/views/reset-password";
import PasswordRecovery from "../components/views/password-recovery";
import ActivateAccount from "../components/views/activate-account";
import Home from "../components/views/home";
import Customers from "../components/views/customers";
import Credentials from "../components/views/credentials";
import Projects from "../components/views/projects";
import Team from "../components/views/team";
import Demo from "../components/views/demo";
import DemoAggregation from "../components/views/demo/aggregation";
import DemoAggregationSelectProvider from "../components/views/demo/aggregation/select-provider";
import DemoAggregationStatus from "../components/views/demo/aggregation/status";
import DemoAggregationConsent from "../components/views/demo/aggregation/consent";
import DemoDashboard from "../components/views/demo/dashboard";
import DemoDashboardHome from "../components/views/demo/dashboard/home";
import DemoDashboardTransaction from "../components/views/demo/dashboard/transaction";
import EnrichmentGuide from "../components/views/enrichment";
import Invite from "../components/views/invite";
import { RouteName } from "./constants";
import ErrorView from "../components/views/error";
import { pageNotFound } from "../constants/error-codes";
import Project from "../components/views/projects/project";
import ProjectsList from "../components/views/projects/list";
import ProjectWebhooks from "../components/views/projects/project/webhooks";
import ProjectInsightsList from "../components/views/projects/project/insights/list";
import CreateInsightView from "../components/views/projects/project/insights/create";
import EditInsightView from "../components/views/projects/project/insights/edit";
import AddExistingInsightView from "../components/views/projects/project/insights/add";
import SingleWebhook from "../components/views/projects/project/webhooks/single-webhook";
import {
  SingleEventLoader,
  SinglePaymentEventLoader,
} from "../components/views/projects/project/webhooks/single-event";
import {
  SinglePaymentRequestLoader,
  SingleRequestLoader,
} from "../components/views/projects/project/webhooks/single-request";
import WebhookList from "../components/views/projects/project/webhooks/webhook-list";
import { EventHistory } from "../components/views/projects/project/webhooks/event-history";

const notFoundError = new HttpError(404, "Not found", { code: pageNotFound });

export const { Router, Routes, createHref, matchRoutes } = createRouter({
  basePath: basePath,
  routes: [
    {
      element: <Login />,
      name: RouteName.login,
      path: "/login",
    },
    {
      element: <ResetPassword />,
      name: RouteName.resetPassword,
      path: "/reset-password",
    },
    {
      element: <PasswordRecovery />,
      name: RouteName.passwordRecovery,
      path: "/forgotten-password",
    },
    {
      element: <Signup />,
      name: RouteName.signup,
      path: "/signup",
    },
    {
      element: <Invite />,
      name: RouteName.invite,
      path: "/invite",
    },
    {
      element: <Home />,
      name: RouteName.home,
      path: "/home",
    },
    {
      element: <Customers />,
      name: RouteName.customers,
      path: "/customers",
    },
    {
      element: <Credentials />,
      name: RouteName.credentials,
      path: "/credentials",
    },
    {
      element: <ActivateAccount />,
      name: RouteName.activate,
      path: "/activate",
    },
    {
      children: [
        {
          element: <ProjectsList />,
          path: "",
        },
        {
          children: [
            {
              children: [
                {
                  element: <WebhookList />,
                  name: RouteName.projectWebhookList,
                  path: "webhooks",
                },
                {
                  element: <EventHistory />,
                  name: RouteName.projectEventList,
                  path: "event-history",
                },
              ],
              element: <ProjectWebhooks />,
              name: RouteName.projectWebhooks,
            },
            {
              element: <ProjectInsightsList />,
              name: RouteName.projectInsightsList,
              path: "insights",
            },
            {
              element: <Project />,
              name: RouteName.project,
              path: "",
            },
            {
              element: <SingleWebhook />,
              name: RouteName.projectSingleWebhook,
              path: "webhooks/:webhookId",
            },
            {
              element: <SingleEventLoader />,
              name: RouteName.projectSingleEvent,
              path: "webhooks/events/:eventId",
            },
            {
              element: <SinglePaymentEventLoader />,
              name: RouteName.projectSinglePaymentEvent,
              path: "webhooks/payment/events/:eventId",
            },
            {
              element: <SingleRequestLoader />,
              name: RouteName.projectSingleRequest,
              path: "webhooks/events/:eventId/requests/:requestId",
            },
            {
              element: <SinglePaymentRequestLoader />,
              name: RouteName.projectSinglePaymentRequest,
              path: "webhooks/payment/events/:eventId/requests/:requestId",
            },
            {
              element: <CreateInsightView />,
              name: RouteName.projectInsightsCreate,
              path: "insights/create",
            },
            {
              element: <AddExistingInsightView />,
              name: RouteName.projectInsightsAddExisting,
              path: "insights/add",
            },
            {
              element: <EditInsightView />,
              name: RouteName.projectInsightsEdit,
              path: "insights/edit/:insightId",
            },
          ],
          name: RouteName.project,
          path: ":clientId",
        },
      ],
      element: <Projects />,
      name: RouteName.projects,
      path: "/projects",
    },
    {
      element: <Team />,
      name: RouteName.team,
      path: "/team",
    },
    {
      children: [
        {
          element: <Demo />,
          path: "",
        },
        {
          children: [
            {
              element: <Navigate replace={true} to="select-provider" />,
              path: "",
            },
            {
              element: <DemoAggregationSelectProvider />,
              name: RouteName.demoAggregationSelectProvider,
              path: "select-provider",
            },
            {
              element: <DemoAggregationConsent />,
              name: RouteName.demoAggregationConsent,
              path: "consent",
            },
            {
              element: <DemoAggregationStatus />,
              name: RouteName.demoAggregationStatus,
              path: ":demoId/status",
            },
          ],
          element: <DemoAggregation />,
          name: RouteName.demoAggregation,
          path: "aggregation",
        },
        {
          children: [
            {
              element: <DemoDashboardHome />,
              name: RouteName.demoDashboardHome,
              path: "",
            },
            {
              element: <DemoDashboardTransaction />,
              name: RouteName.demoDashboardTransaction,
              path: "transaction/:transactionId",
            },
          ],
          element: <DemoDashboard />,
          path: ":demoId/dashboard",
        },
      ],
      name: RouteName.demo,
      path: "/demo",
    },
    {
      element: <EnrichmentGuide />,
      name: RouteName.enrichmentGuide,
      path: "/enrichment-guide",
    },
    {
      element: <ErrorView error={notFoundError} />,
      name: RouteName.notFound,
      path: "*",
    },
  ],
});
