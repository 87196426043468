import type { TargetEnvironment } from "../../types/environment";
import type { GetEnvironmentsResponse } from "../types/responses";

/**
 * Formats a GET Environments API response.
 *
 * @param res - The response received from the environments URL endpoint
 * @returns A list of environments
 * @public
 */
export function formatGetEnvironmentsResponse(
  response: GetEnvironmentsResponse,
): TargetEnvironment[] {
  const { data } = response;
  return data.map(function (env) {
    return env.environment as TargetEnvironment;
  });
}
