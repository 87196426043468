import { Version, request } from "@thisisbud/internal-sdk";

import { Namespace } from "../constants";
import { formatPostTeamInviteResponse } from "../formatters/team-invite";

import type { PostTeamInviteResponse } from "../types/responses";
import type { TeamInvite } from "../types/entities";

type TeamInviteOptions = {
  email: string;
  token: string;
};

/**
 * Validate a team invite.
 *
 * @param options - The options to initiate the request
 */
export default async function validateTeamInvite(options: TeamInviteOptions): Promise<TeamInvite> {
  const response = await request<PostTeamInviteResponse>(Version.v1, Namespace.invite, {
    body: {
      email: options.email,
      token: options.token,
    },
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    path: "/validate",
  });

  return formatPostTeamInviteResponse(response);
}
