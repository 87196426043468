import { getProjectsData } from "./utils";

import type { LoadableContext } from "@thisisbud/react-loadable";
import type { ProjectsData } from "./utils";
import type { LocalLoadableCtx } from "./types";

export default async function init(ctx: LoadableContext & LocalLoadableCtx): Promise<ProjectsData> {
  const {
    config: { obSubscribableEventsSupportedEnvs, paymentsSubscribableEventsSupportedEnvs },
    environment,
  } = ctx;

  return getProjectsData({
    meta: {},
    queryKey: [
      "projects",
      environment,
      obSubscribableEventsSupportedEnvs,
      paymentsSubscribableEventsSupportedEnvs,
    ],
  });
}
