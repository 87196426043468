import "./client/webpack.config";

import * as React from "react";
import { createRoot } from "react-dom/client";
import { Provider as StoreProvider } from "react-redux";

import { setHost } from "@thisisbud/internal-sdk";

import { getI18n } from "./client/i18n";
import Root from "./common";
import { Router, Routes } from "./common/router";
import { init as initEventTracking } from "./client/utils/tracking";
import {
  getUserId,
  isAuthenticated,
  setOnSessionEnded,
  setOnSessionStarted,
} from "./client/utils/auth";
import { getSessionId } from "./client/utils/session";
import createStore from "./common/store";
import { setAuthenticated } from "./common/store/auth";
import { getUserEmailDomain, resetUser } from "./common/store/user";

import type { AppConfig } from "./types/config";

const container = document.getElementById("app") as HTMLDivElement;
const config: AppConfig = JSON.parse(
  (document.getElementById("app-config") as HTMLDivElement).innerHTML,
);
const theme = JSON.parse((document.getElementById("bui-theme") as HTMLDivElement).innerHTML);

if (
  // @ts-expect-error The properties only exist in modern browsers, this is necessary to be able to use the `dangerouslySetInnerHTML` prop in React
  typeof window.trustedTypes !== "undefined" &&
  // @ts-expect-error The properties only exist in modern browsers, this is necessary to be able to use the `dangerouslySetInnerHTML` prop in React
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  typeof window.trustedTypes.createPolicy !== "undefined"
) {
  // @ts-expect-error The properties only exist in modern browsers
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  window.trustedTypes.createPolicy("default", {
    createHTML(str: string) {
      return str;
    },
    createScriptURL(str: string) {
      return str;
    },
  });
}

async function init(): Promise<void> {
  const i18n = await getI18n({
    appBasePath: config.basePath,
  });

  const store = createStore({
    preloadedState: {
      auth: {
        authenticated: isAuthenticated(),
      },
    },
  });

  setHost(config.apiHost);

  setOnSessionStarted(function () {
    store.dispatch(setAuthenticated(true));
  });

  setOnSessionEnded(function () {
    store.dispatch(setAuthenticated(false));
    store.dispatch(resetUser());
  });

  if (typeof config.mixpanelToken === "string") {
    initEventTracking(config.mixpanelToken, {
      getBasePayload: function () {
        return {
          appName: process.env.APP_NAME,
          appVersion: process.env.APP_VERSION,
          environment: config.envName,
          userEmailDomain: getUserEmailDomain(store.getState()),
          userId: getUserId() ?? getSessionId(),
        };
      },
      trackUnidentifiedUserEvents: true,
    });
  }

  if (config.envName === "development") {
    const { worker } = await import("./mocks/msw/browser");

    void worker.start({
      onUnhandledRequest: "bypass",
      quiet: !config.enableMswLogging,
    });
  }

  const root = createRoot(container);

  root.render(
    <Router>
      <StoreProvider store={store}>
        <Root config={config} i18n={i18n} theme={theme}>
          <Routes />
        </Root>
      </StoreProvider>
    </Router>,
  );
}

init().catch(function (e) {
  console.error(e); // TODO: Monitor the error
});
