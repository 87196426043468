import React from "react";
import { useSelector } from "react-redux";

import { Pill } from "@thisisbud/gds-ui/pill";

import { getEnvironment } from "../../store/environment";

import type { TargetEnvironment } from "../../types/environment";

/**
 * Determines if the current domain is a US domain.
 * @returns {boolean} True if the domain is a US domain, false otherwise.
 */
export const getIsUsDomain = (): boolean => {
  return window.location.hostname.toLowerCase().split(".")[0].includes("console-us");
};

/**
 * Generates the environment URL based on the target environment and domain flag.
 * @param environment - The target environment (e.g., 'sandbox', 'production').
 * @returns The generated environment URL.
 */
export const generateEnvironmentUrl = (environment: TargetEnvironment): string => {
  const isUsDomain = getIsUsDomain();

  if (environment === "sandbox") {
    return "https://api-sandbox.thisisbud.com";
  }

  return isUsDomain ? "https://api-us.thisisbud.com" : "https://api.thisisbud.com";
};

export function EnvironmentUrl(): React.ReactElement {
  const currentEnvironment = useSelector(getEnvironment);
  const environmentUrl = generateEnvironmentUrl(currentEnvironment);

  return (
    <div className="flex items-center" data-testid="environment-url-tag">
      <Pill status="info" text={environmentUrl} />
    </div>
  );
}
