import { createSlice } from "@reduxjs/toolkit";

import type { State } from "../types";

export type StateSlice = {
  isReplayHistoryDialogOpen: boolean;
};

const initialState: StateSlice = {
  isReplayHistoryDialogOpen: false,
};

const stateSlice = createSlice({
  initialState: initialState,
  name: "webhooks",
  reducers: {
    closeReplayHistoryDialog(state: StateSlice) {
      state.isReplayHistoryDialogOpen = false;
    },
    openReplayHistoryDialog(state: StateSlice) {
      state.isReplayHistoryDialogOpen = true;
    },
  },
});

/**
 * Get the user state slice.
 *
 * @param state - The root state of the application
 * @returns The authentication state slice
 */
function getStateSlice(state: State): StateSlice {
  return state[stateSlice.name];
}

/**
 * Get the domain of the current user's email.
 *
 * @param state - The root state of the application
 * @returns The current user's email domain if available
 */
export function getIsReplayHistoryDialogOpen(state: State): boolean {
  return getStateSlice(state).isReplayHistoryDialogOpen;
}

export const { name, reducer } = stateSlice;
export const { openReplayHistoryDialog, closeReplayHistoryDialog } = stateSlice.actions;
