import { requireAuth } from "../../../../../../../client/utils/auth";
import getUserInsights from "../../../../../../api/operations/get-user-insights";

import type { LoadableContext } from "@thisisbud/react-loadable";
import type { LocalLoadableCtx } from ".";

export type InitData = {
  userHasInsights: boolean;
  projectId: string;
  environment: string;
};

export default async function init(ctx: LoadableContext & LocalLoadableCtx): Promise<InitData> {
  const {
    environment,
    availableEnvironments,
    project: { clientId: projectId },
  } = ctx;

  const auth = await requireAuth();
  const userInsights = await getUserInsights({
    auth: auth,
    environments: availableEnvironments,
  });

  return {
    environment: environment,
    projectId: projectId,
    userHasInsights: userInsights.length > 0,
  };
}
