import * as React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Loadable from "@thisisbud/react-loadable";

import { loadingView } from "../../../../loading";
import { useConfig } from "../../../../../../context/config";
import { getEnvironment } from "../../../../../../store/environment";

import type { ProjectParams } from "../../types";
import type { TargetEnvironment } from "../../../../../../types/environment";
import type { Params } from "./types";

export type LocalLoadableCtx = {
  environment: TargetEnvironment;
  clientId: string;
  obSubscribableEventsSupportedEnvs: string[];
  paymentsSubscribableEventsSupportedEnvs: string[];
  webhookId?: string;
};

/**
 * Lazily load the component.
 *
 * @returns The imported module
 */
async function loadComponent(): Promise<{ default: React.ComponentType<{ params: Params }> }> {
  return import(/* webpackChunkName: 'views/webhooks/single-webhook' */ "./component");
}

/**
 * Lazily load the component and initialize data for the Single Webhook view.
 */
export default function SingleWebhookLoader(): React.ReactElement {
  const params = useParams();
  const { clientId } = useParams<keyof ProjectParams>() as ProjectParams;
  const { obSubscribableEventsSupportedEnvs, paymentsSubscribableEventsSupportedEnvs } =
    useConfig();
  const currentEnvironment = useSelector(getEnvironment);

  const localCtx = React.useMemo((): LocalLoadableCtx => {
    return {
      clientId: clientId,
      environment: currentEnvironment,
      obSubscribableEventsSupportedEnvs: obSubscribableEventsSupportedEnvs,
      paymentsSubscribableEventsSupportedEnvs: paymentsSubscribableEventsSupportedEnvs,
      webhookId: params.webhookId,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Loadable
      ctx={localCtx}
      fallback={loadingView}
      fallbackDelay={0}
      loadComponent={loadComponent}
      params={params}
    />
  );
}
