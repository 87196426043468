import * as React from "react";

import { useI18n } from "@thisisbud/i18n-react";
import { Paragraph } from "@thisisbud/bui-react-typography";
import { StatusNotification } from "@thisisbud/bui-react-status-notification";

import ContinueButton from "./continue-button";

import type { HttpError } from "@thisisbud/internal-sdk";
import type I18n from "@thisisbud/i18n";

type Props = {
  error?: Error | HttpError;
};

function getErrorTitle(i18n: I18n, err?: Error | HttpError): string {
  // @ts-expect-error The properties will not always be present
  const { code = "generic", status } = err ?? {};

  const titleCode = `error.${code as string}.title`;

  if (i18n.hasTranslation(titleCode)) {
    return i18n.t(titleCode);
  } else if (typeof status !== "undefined") {
    switch (status) {
      case 503:
        return i18n.t("error.page-unavailable.title");
    }
  }

  return i18n.t("error.generic.title");
}

function getErrorBody(i18n: I18n, err?: Error | HttpError): string | undefined {
  // @ts-expect-error The properties will not always be present
  const { code = "generic", status } = err ?? {};

  const bodyCode = `error.${code as string}.body`;

  if (i18n.hasTranslation(bodyCode)) {
    return i18n.t(bodyCode);
  } else if (typeof status !== "undefined") {
    switch (status) {
      case 503:
        return i18n.t("error.page-unavailable.body");
    }
  }

  return i18n.t("error.generic.body");
}

export default function ErrorView(props: Props): React.ReactElement {
  const { error } = props;
  const i18n = useI18n();

  const errorTitle = getErrorTitle(i18n, error);
  const errorBody = getErrorBody(i18n, error);

  return (
    <StatusNotification className="flex flex-col items-center" title={errorTitle} type="error">
      <Paragraph align="center">{errorBody}</Paragraph>
      <ContinueButton />
    </StatusNotification>
  );
}
