import "./index.css";
import "@fontsource-variable/fira-code";

import * as React from "react";
import { createGlobalStyle } from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { GlobalStyle, ThemeProvider } from "@thisisbud/bui-react-theme";
import { I18nProvider } from "@thisisbud/i18n-react";
import { LoadableContextProvider } from "@thisisbud/react-loadable";
import ToastNotifications from "@thisisbud/bui-react-toast-notifications";

import { requireAuth } from "../client/utils/auth";
import { ConfigProvider } from "./context/config";
import { QueryProvider } from "./context/query";
import getEnvironments from "./api/operations/get-environments";
import { setAvailableEnvironments } from "./store/environment";
import { getAuthenticated } from "./store/auth";
import AppLayout from "./components/app-layout";
import ErrorBoundary from "./components/error-boundary";
import getProfile from "./api/operations/get-profile";
import { setUserEmail } from "./store/user";

import type { ThemePack } from "@thisisbud/bui-themes";
import type I18n from "@thisisbud/i18n";
import type { AppConfig } from "../types/config";

type Props = {
  children: React.ReactNode;
  config: AppConfig;
  theme: ThemePack;
  i18n: I18n;
};

declare module "@thisisbud/react-loadable" {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface LoadableContext {
    config: AppConfig;
  }
}

const GlobalAppStyle = createGlobalStyle`
body {
  padding: 0;
  margin: 0;
}
`;

/**
 * The root component of the application.
 *
 * @param props - The props required to render the application
 */
export default function Root(props: Props): React.ReactElement {
  const { children, config, theme, i18n } = props;

  const dispatch = useDispatch();
  const authenticated = useSelector(getAuthenticated);

  const loadableCtx = React.useMemo(() => {
    return { config };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(
    function () {
      if (authenticated) {
        requireAuth()
          .then(async function (auth) {
            const [profile, environments] = await Promise.all([
              getProfile(auth),
              getEnvironments(auth),
            ]);

            dispatch(setUserEmail(profile.email));
            dispatch(setAvailableEnvironments(environments));
          })
          .catch(function () {
            // Safe to ignore, defaults to sandbox
          });
      }
    },
    [dispatch, authenticated],
  );

  return (
    <ConfigProvider config={config}>
      <I18nProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <ToastNotifications id="toast-notifications">
            <GlobalAppStyle />
            <GlobalStyle />
            <LoadableContextProvider ctx={loadableCtx}>
              <QueryProvider>
                <AppLayout>
                  <ErrorBoundary>{children}</ErrorBoundary>
                </AppLayout>
              </QueryProvider>
            </LoadableContextProvider>
          </ToastNotifications>
        </ThemeProvider>
      </I18nProvider>
    </ConfigProvider>
  );
}
