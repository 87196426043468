import type { Provider } from "@thisisbud/internal-sdk";

export const staticDemoId = "static";

export const staticDemoFallbackMinDuration = 5000;

export const staticDemoLoadingLabelKeys = [
  "retrieving-accounts",
  "retrieving-transactions",
  "enriching-transactions",
];

export const defaultDemoProvider = {
  logoUrl: "https://assets.thisisbud.com/openbanking/bud-bank-logo.png",
  name: "Bud Bank",
} satisfies Partial<Provider>;
