import getOpenBankingDemoTransactions from "../../../../../api/operations/demo/get-demo-open-banking-account-transactions";
import getOpenBankingDemoAccounts from "../../../../../api/operations/demo/get-demo-open-banking-accounts";
import { getDemoCustomerById } from "../../../../../../client/utils/demo-customer";
import getDemoOpenBankingProviders from "../../../../../api/operations/demo/get-demo-open-banking-providers";

import type { InitData } from "../init";
import type { AuthDetails } from "../../../../../api/types/common";

/**
 * Get the open banking demo data for a given customer.
 *
 * @param auth - The request auth
 * @param customer - The details of the customer
 * @returns The transaction and accounts data for the given demo customer
 */
export async function getOpenBankingDemoData(auth: AuthDetails, demoId: string): Promise<InitData> {
  const customer = getDemoCustomerById(demoId);
  const authWithCustomer = { ...auth, ...customer };

  const today = new Date();

  const [providers, accounts, transactions] = await Promise.all([
    getDemoOpenBankingProviders(auth, "sandbox"),
    getOpenBankingDemoAccounts(authWithCustomer, "sandbox"),
    getOpenBankingDemoTransactions(authWithCustomer, "sandbox", {
      from: new Date(today.getFullYear(), today.getMonth() - 4, today.getDate()),
      to: today,
    }),
  ]);

  return {
    accounts,
    providers,
    transactions,
  };
}
