/* eslint-disable @typescript-eslint/no-misused-promises */

import React from "react";
import { useForm } from "react-hook-form";

import { Button } from "@thisisbud/gds-ui/button";
import { Dialog } from "@thisisbud/gds-ui/dialog";
import { Label } from "@thisisbud/gds-ui/label";
import { useI18n } from "@thisisbud/i18n-react";

import { EventFilterFormFields } from "./event-filter-form-fields";

import type { FilterActions, FilterState } from "./types";

type Props = {
  onClose(): void;
  isOpen: boolean;
  filters: FilterState;
  actions: FilterActions;
};

export function EventFilterDialog({
  onClose,
  isOpen,
  filters,
  actions,
}: Props): React.ReactElement {
  const { t } = useI18n();
  const { handleSubmit, control, reset } = useForm({ defaultValues: filters });

  const handleReset = React.useCallback(() => {
    actions.handleResetFilters();
    reset();
  }, [actions, reset]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <Dialog.Set closeLabel={t("common.close")}>
        <Dialog.Title>
          {t("projects.event-history.filter-dialog.filter-event-history")}
        </Dialog.Title>
        <Dialog.Content>
          <form className="flex flex-col" onSubmit={handleSubmit(actions.onSubmit)}>
            <Label label={t("projects.event-history.filter-dialog.status")} />
            <EventFilterFormFields control={control} />
            <div className="mt-6 flex justify-between">
              <Button type="button" variant="text" onClick={handleReset}>
                {t("projects.event-history.filter-dialog.reset-filters")}
              </Button>
              <div className="flex gap-2">
                <Button type="button" variant="secondary" onClick={onClose}>
                  {t("common.cancel")}
                </Button>
                <Button type="submit">
                  {t("projects.event-history.filter-dialog.show-events")}
                </Button>
              </div>
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Set>
    </Dialog>
  );
}
