import * as React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Loadable from "@thisisbud/react-loadable";

import { loadingView } from "../loading";
import { createHref } from "../../../router";
import { RouteName } from "../../../router/constants";
import { getAuthenticated } from "../../../store/auth";
import init from "./init";

/**
 * Lazily load the component.
 *
 * @returns The imported module
 */
async function loadComponent(): Promise<{ default: any }> {
  return import(/* webpackChunkName: 'views/signup' */ "./component");
}

/**
 * Lazily load the component and initialize data for the Signup view.
 */
export default function SignupViewLoader(): React.ReactElement {
  const authenticated = useSelector(getAuthenticated);

  if (authenticated) {
    return (
      <Navigate
        replace={true}
        to={createHref({
          name: RouteName.home,
        })}
      />
    );
  }

  return (
    <Loadable fallback={loadingView} fallbackDelay={0} init={init} loadComponent={loadComponent} />
  );
}
