import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { name as authName, reducer as authReducer } from "./auth";
import { name as envName, reducer as envReducer } from "./environment";
import { name as userName, reducer as userReducer } from "./user";
import { name as webhooksName, reducer as webhooksReducer } from "./webhooks";

import type { State, Store } from "./types";

type CreateStoreOptions = {
  preloadedState?: Partial<State>;
};

const reducer = combineReducers({
  [authName]: authReducer,
  [envName]: envReducer,
  [userName]: userReducer,
  [webhooksName]: webhooksReducer,
});

/**
 * Create a Redux store.
 *
 * @param options - The options for the store creation
 * @returns A Redux store
 */
export default function createStore(options: CreateStoreOptions = {}): Store {
  const { preloadedState } = options;

  return configureStore({
    preloadedState,
    reducer,
  });
}

export type RootState = ReturnType<typeof reducer>;
export type AppStore = ReturnType<typeof createStore>;

const store = configureStore({
  reducer,
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
