import * as React from "react";
import Fuse from "fuse.js";

import { useI18n } from "@thisisbud/i18n-react";
import { FormattedDate } from "@thisisbud/bui-react-formatted-date";
import { Link } from "@thisisbud/gds-ui/link";

import { createHref } from "../../../../router";
import { RouteName } from "../../../../router/constants";
import RouterLink from "../../../router-link";
import { sortProjects } from "./utils";

import type { Project } from "../../../../api/types/entities";
import type { TableColumn, TableDataCell, TableDataOptions } from "./types";
import type { SortDirection } from "../../../../types/sorting";

/**
 * Use the configuration for the table's columns.
 *
 * @returns The table column configuration
 */
export function useTableColumns(): TableColumn[] {
  const i18n = useI18n();

  return React.useMemo(
    function () {
      return [
        { key: "name", sortable: true, title: i18n.t("projects.list.column.name") },
        { key: "client-id", sortable: true, title: i18n.t("projects.list.column.client-id") },
        { key: "created-at", sortable: true, title: i18n.t("projects.list.column.created-at") },
        {
          key: "options",
          textAlign: "right",
          title: i18n.t("projects.list.column.options"),
        },
      ];
    },
    [i18n],
  );
}

/**
 * Use the configuration for the table data.
 *
 * @param projects - The projects list
 * @param options - The table data options
 * @returns The table data configuration
 */
export function useTableData(projects: Project[], options: TableDataOptions): TableDataCell[][] {
  const { filter } = options;
  const i18n = useI18n();

  return React.useMemo(
    function () {
      if (!projects.length) {
        return [
          [
            {
              content:
                filter === ""
                  ? i18n.t("projects.list.empty")
                  : i18n.t("projects.list.no-matching-filter"),
              span: 4,
            },
          ],
        ];
      }

      return projects.map(function (project) {
        return [
          project.name ?? i18n.t("projects.list.name-fallback"),
          project.clientId,
          <FormattedDate date={project.createdAt} key={`${project.clientId}-formatted-date`} />,
          <RouterLink
            as={Link}
            href={createHref({
              name: RouteName.project,
              params: {
                clientId: project.clientId,
              },
            })}
            id={`${project.clientId}-view-button`}
            key={`${project.clientId}-view-button`}
          >
            {i18n.t("projects.list.actions.view.label")}
          </RouterLink>,
        ];
      });
    },
    [i18n, filter, projects],
  );
}

/**
 * Use a list of named projects filtered based on a given filter.
 *
 * @param projects - The projects to be filtered
 * @param filter - The clientId filter to use
 * @returns A list of projects filtered based on the filter provided
 */
export function useFilteredProjects(projects: Project[], filter: string): Project[] {
  const computedFilter = filter.trim();

  const fuse = React.useMemo(
    function () {
      return new Fuse(projects, {
        keys: ["clientId", "name"],
        threshold: 0.2,
      });
    },
    [projects],
  );

  return React.useMemo(
    function () {
      if (computedFilter === "") {
        return projects;
      }

      return fuse
        .search({
          $and: computedFilter.split(" ").map(function (token) {
            return {
              $or: [{ clientId: token }, { name: token }] as Fuse.Expression[],
            };
          }),
        })
        .map(function (match) {
          return match.item;
        });
    },
    [computedFilter, fuse, projects],
  );
}

/**
 * Use a list of sorted projects.
 *
 * @param projects - The list of projects to sort
 * @param sortDirection - The sort direction by which projects are to be sorted
 * @param sortedBy - The column by which projects are to be sorted
 * @returns A list of sorted projects
 */
export function useSortedProjects(
  projects: Project[],
  sortDirection: SortDirection,
  sortedBy: string,
): Project[] {
  const i18n = useI18n();

  return React.useMemo(
    function () {
      return sortProjects(i18n, projects, sortDirection, sortedBy);
    },
    [i18n, sortDirection, sortedBy, projects],
  );
}

/**
 * Use a callback function to provide a button label callback.
 *
 * @returns A callback function to generate button label
 */
export function useGetSortButtonLabel(): (key: string, direction: string) => string {
  const i18n = useI18n();

  return React.useCallback(
    function (key: string, direction: string) {
      const columnName = i18n.t(`projects.list.column.${key}`);
      const directionName = i18n.t(`common.sort-direction.${direction}`);

      return i18n.t("common.sort-by", {
        direction: directionName,
        property: columnName,
      });
    },
    [i18n],
  );
}
