import * as React from "react";
import { useLocation } from "react-router-dom";

/**
 * Use the current location query as an object of key value pairs.
 *
 * @returns An object with the current location query
 */
export function useQuery(): { [key: string]: string } {
  const { search } = useLocation();

  return React.useMemo(
    function () {
      return Object.fromEntries(new URLSearchParams(search));
    },
    [search],
  );
}
