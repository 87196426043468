import { toUTCDateString } from "@thisisbud/datetime-utils";
import { Version, request } from "@thisisbud/internal-sdk";

import { Namespace } from "../../constants";
import { formatGetTransactionsResponse } from "../../formatters/transactions";

import type { AuthDetails } from "../../types/common";
import type { DemoTransaction } from "../../types/entities";
import type { GetDemoTransactionsResponse } from "../../types/responses";

type Options = {
  from: Date;
  to: Date;
};

/**
 * Get a list of transactions for a user's customer.
 *
 * @param auth - The authentication details for the request
 * @param envType - The environment
 * @returns A list of transactions
 *
 */
export default async function getOpenBankingDemoTransactions(
  auth: AuthDetails,
  envType: string,
  options: Options,
): Promise<DemoTransaction[]> {
  const response = await request<GetDemoTransactionsResponse>(Version.v1, Namespace.demo, {
    auth: auth,
    headers: {
      "X-From": toUTCDateString(options.from),
      "X-To": toUTCDateString(options.to),
      "X-User-Env": envType,
    },
    method: "GET",
    path: "/transactions",
  });

  return formatGetTransactionsResponse(response);
}
