import { Namespace, Version, request } from "@thisisbud/internal-sdk";
import { base64Encode } from "@thisisbud/base64-utils";

import { formatOAuthTokenResponse } from "../formatters/oauth-token";

import type { ApiResponse } from "../formatters/oauth-token";
import type { UserLoginDetails } from "../types/common";
import type { OAuthToken } from "../types/entities";

/**
 * Login with credentials and create an OAuth token.
 *
 * @param details - The details to initiate the request
 */
export default async function login(details: UserLoginDetails): Promise<OAuthToken> {
  const response = await request<ApiResponse>(Version.v1, Namespace.oAuth, {
    body: {
      grant_type: "password",
    },
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      email: base64Encode(details.email),
      password: base64Encode(details.password),
    },
    method: "POST",
    path: "/token",
  });

  return formatOAuthTokenResponse(response);
}
