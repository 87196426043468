import * as React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Loadable from "@thisisbud/react-loadable";

import { loadingView } from "../../../loading";
import { getEnvironment } from "../../../../../store/environment";

import type { ProjectParams } from "../types";
import type { LocalLoadableCtx } from "./types";

/**
 * Lazily load the component.
 *
 * @returns The imported module
 */
async function loadComponent(): Promise<{ default: React.ComponentType }> {
  return import(/* webpackChunkName: 'views/projects/project/webhooks/component' */ "./component");
}

/**
 * Lazily load the component and initialize data for the single Project webhooks view.
 */
export default function WebhooksViewLoader(): React.ReactElement {
  const { clientId } = useParams<keyof ProjectParams>() as ProjectParams;
  const currentEnvironment = useSelector(getEnvironment);

  const localCtx = React.useMemo((): LocalLoadableCtx => {
    return {
      clientId: clientId,
      environment: currentEnvironment,
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Loadable
      ctx={localCtx}
      fallback={loadingView}
      fallbackDelay={0}
      loadComponent={loadComponent}
    />
  );
}
