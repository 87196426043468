import React from "react";

import { Link } from "@thisisbud/gds-ui/link";
import { useI18n } from "@thisisbud/i18n-react";

import { createHref } from "../../../../../../router";
import RouterLink from "../../../../../router-link";
import { RouteName } from "../../../../../../router/constants";

type Props = {
  clientId: string;
  webhookId: string;
};

export function TestEventNotification({ clientId, webhookId }: Props): React.ReactElement {
  const { t } = useI18n();

  return (
    <React.Fragment>
      {t("projects.single-webhook.details.send-event-success", { name: webhookId })}
      <RouterLink
        as={Link}
        href={createHref({ name: RouteName.projectEventList, params: { clientId, webhookId } })}
        id="view-event-log"
      >
        {t("projects.single-webhook.view-event-log")}
      </RouterLink>
    </React.Fragment>
  );
}
