import * as React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { rem } from "polished";

import { useI18n } from "@thisisbud/i18n-react";
import { selectFromTheme } from "@thisisbud/sc-utils";
import { paragraphSmallCss } from "@thisisbud/bui-react-typography";
import { InlineLink } from "@thisisbud/bui-react-inline-link";
import { joinUrlPaths } from "@thisisbud/url-utils";

import { useConfig } from "../../context/config";
import { RouteName } from "../../router/constants";
import { createHref } from "../../router";
import { getAuthenticated } from "../../store/auth";
import BudLogo from "../bud-logo";
import AppNavigation from "../app-navigation";
import { useOnLogoutClick } from "./hooks";
import { track } from "../../../client/utils/tracking";
import Events from "../../constants/events";
import EnvironmentSelector from "./environment-selector";

export const appHeaderClassName = "app-header";
export const appHeaderInnerClassName = `${appHeaderClassName}__inner`;
export const appHeaderUserOptionsClassName = `${appHeaderClassName}__user-options`;

const StyledHeader = styled.header`
  background-color: white;
  padding: ${selectFromTheme("spacingXXSmallDefault")} ${selectFromTheme("spacingLargeDefault")};
  border-style: solid;
  border-width: ${selectFromTheme("thicknessAppHeaderBorderDefault")};
  border-color: ${selectFromTheme("colorBorderAppHeaderDefault")};

  @media (min-width: ${selectFromTheme("breakpointWide")}) {
    padding-right: ${selectFromTheme("spacingXXXXLargeDefault")};
    padding-left: ${selectFromTheme("spacingXXXXLargeDefault")};
  }

  .${appHeaderInnerClassName} {
    display: flex;
    margin: 0 auto;
    max-width: ${selectFromTheme("breakpointExtraWide")};
    align-items: center;
    // This is required to ensure the header does not jump in size if the env selector is displayed
    min-height: ${rem(50)};
  }

  .${appHeaderUserOptionsClassName} {
    ${paragraphSmallCss}
    display: flex;
    align-items: center;
    margin-left: auto;
  }
`;

function onSignInClicked(): void {
  track(Events.headerSignInClicked);
}

function onSignUpClicked(): void {
  track(Events.headerSignUpClicked);
}

export default function AppHeader(): React.ReactElement {
  const i18n = useI18n();
  const { basePath, enableEnvironmentSelector } = useConfig();
  const authenticated = useSelector(getAuthenticated);
  const onLogout = useOnLogoutClick();

  const displayEnvironmentSelector = enableEnvironmentSelector && authenticated;
  const displaySignUpLink =
    !authenticated && location.pathname.startsWith(joinUrlPaths(basePath, RouteName.login));

  const renderSignIn = React.useCallback(function (text: string) {
    // TODO: using navigation in this way is causing a full reload
    return (
      <InlineLink
        href={createHref({
          name: RouteName.login,
        })}
        id="sign-in-link"
        key="sign-in-link"
        onClick={onSignInClicked}
      >
        {text}
      </InlineLink>
    );
  }, []);

  const renderSignUp = React.useCallback(function (text: string) {
    // TODO: using navigation in this way is causing a full reload
    return (
      <InlineLink
        href={createHref({
          name: RouteName.signup,
        })}
        id="sign-up-link"
        key="sign-up-link"
        onClick={onSignUpClicked}
      >
        {text}
      </InlineLink>
    );
  }, []);

  const loginOptions = displaySignUpLink ? (
    <span>{i18n.tToParts("header.no-account", { renderSignUp }) as React.ReactNode} </span>
  ) : (
    <span>{i18n.tToParts("header.have-an-account", { renderSignIn }) as React.ReactNode} </span>
  );

  return (
    <StyledHeader className={appHeaderClassName}>
      <div className={appHeaderInnerClassName}>
        <BudLogo />
        {authenticated ? <AppNavigation /> : null}
        <aside className={appHeaderUserOptionsClassName}>
          {displayEnvironmentSelector ? <EnvironmentSelector /> : null}
          <nav>
            {authenticated ? (
              <InlineLink type="button" onClick={onLogout}>
                {i18n.t("header.logout.label")}
              </InlineLink>
            ) : (
              loginOptions
            )}
          </nav>
        </aside>
      </div>
    </StyledHeader>
  );
}
