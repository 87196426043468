import * as React from "react";

import type { AppConfig } from "../../types/config";

// TODO: use @thisisbud/react-utils utility

export type ConfigProps = {
  readonly config: AppConfig;
};

const ConfigContext = React.createContext<ConfigProps["config"]>(
  null as unknown as ConfigProps["config"],
);

/**
 * Provides a configuration for use in React components.
 *
 * @param props - Provider props including the configuration to provide to consumers of the Config context
 */
export function ConfigProvider(props: React.PropsWithChildren<ConfigProps>): React.ReactElement {
  const { children, config } = props;

  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
}

ConfigProvider.displayName = "ConfigProvider";

/**
 * Use the current configuration provided by the closest Config context provider above.
 *
 * @returns The configuration currently provided
 */
export function useConfig(): ConfigProps["config"] {
  return React.useContext(ConfigContext);
}
