export enum RouteName {
  login = "login",
  signup = "signup",
  invite = "invite",
  home = "home",
  customers = "customers",
  credentials = "credentials",
  projects = "projects",
  project = "project",
  projectEventList = "project-event-list",
  projectSingleEvent = "project-single-event",
  projectSinglePaymentEvent = "project-single-payment-event",
  projectSingleRequest = "project-single-request",
  projectSinglePaymentRequest = "project-single-payment-request",
  projectSingleWebhook = "project-single-webhook",
  projectWebhooks = "project-webhooks",
  projectWebhookList = "project-webhook-list",
  projectInsightsAddExisting = "project-insights-add-existing",
  projectInsightsList = "project-insights-list",
  projectInsightsCreate = "project-insights-create",
  projectInsightsEdit = "project-insights-edit",
  resetPassword = "reset-password",
  passwordRecovery = "forgotten-password",
  activate = "activate",
  enrichmentGuide = "enrichment-guide",
  notFound = "not-found",
  demo = "demo",
  demoAggregation = "demo-aggregation",
  demoAggregationSelectProvider = "demo-aggregation-select-provider",
  demoAggregationConsent = "demo-aggregation-consent",
  demoAggregationStatus = "demo-aggregation-status",
  demoDashboardHome = "demo-dashboard-home",
  demoDashboardTransaction = "demo-dashboard-transaction",
  team = "team",
}

export const demoPath = "demo-project";

export const demoAggregationRoutePath = "aggregation";

export const demoAggregationStatusRoutePath = "status";

export const demoAuthorizePath = "authorize";

export const demoAccountsPath = "api/accounts";

export const demoTransactionsPath = "api/transactions";
