import React from "react";

import { track } from "../../client/utils/tracking";

import type { Payload } from "@thisisbud/event-tracking";
import type Events from "../constants/events";

export function usePageViewEvent(event: Events, properties?: Payload): void {
  React.useEffect(
    function () {
      track(event, properties);
    },
    [event],
  );
}
