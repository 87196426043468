import * as React from "react";

import Loadable from "@thisisbud/react-loadable";

import { loadingView } from "../loading";

/**
 * Lazily load the component.
 *
 * @returns The imported module
 */
async function loadComponent(): Promise<{ default: React.ComponentType }> {
  return import(/* webpackChunkName: 'views/pasword-reset' */ "./component");
}

/**
 * Lazily load the component and initialize data for the Login view.
 */
export default function PasswordResetViewLoader(): React.ReactElement {
  return <Loadable fallback={loadingView} fallbackDelay={0} loadComponent={loadComponent} />;
}
