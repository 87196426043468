import React from "react";
import { Controller } from "react-hook-form";

import { Checkbox } from "@thisisbud/gds-ui/checkbox";
import { Input } from "@thisisbud/gds-ui/input";
import { useI18n } from "@thisisbud/i18n-react";
import { Label } from "@thisisbud/gds-ui/label";
import { Switch } from "@thisisbud/gds-ui/switch";

import { useGetSubscribedEvents } from "../api";

import type { Control } from "react-hook-form";
import type { FilterState } from "./types";

type Props = {
  control: Control<FilterState>;
};
export function EventFilterFormFields({ control }: Props): React.ReactElement {
  const { t } = useI18n();
  const { data: webhooks } = useGetSubscribedEvents();
  const isSubscribedToPayments = webhooks?.some(({ category }) => category === "payments");

  return (
    <React.Fragment>
      <div className="mb-10 flex flex-col gap-4">
        <Controller
          control={control}
          name="showCreatedEvents"
          render={({ field: { value, onChange } }) => (
            <Checkbox
              checked={value}
              label={t("projects.event-history.filter-dialog.created-events")}
              name="showCreatedEvents"
              value="checkbox"
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="showSentEvents"
          render={({ field: { value, onChange } }) => (
            <Checkbox
              checked={value}
              label={t("projects.event-history.filter-dialog.sent-events")}
              name="showSentEvents"
              value="checkbox"
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="showFailedEvents"
          render={({ field: { value, onChange } }) => (
            <Checkbox
              checked={value}
              label={t("projects.event-history.filter-dialog.failed-events")}
              name="showFailedEvents"
              value="checkbox"
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="showRetryingEvents"
          render={({ field: { value, onChange } }) => (
            <Checkbox
              checked={value}
              label={t("projects.event-history.filter-dialog.retrying-events")}
              name="showRetryingEvents"
              value="checkbox"
              onChange={onChange}
            />
          )}
        />
      </div>
      {Boolean(isSubscribedToPayments) ? (
        <React.Fragment>
          <Label label={t("projects.event-history.filter-dialog.type")} />
          <div className="mb-10 flex w-fit flex-col gap-2">
            <Controller
              control={control}
              name="showPaymentEvents"
              render={({ field: { value, onChange } }) => (
                <Switch
                  checked={value}
                  label={t("projects.event-history.filter-dialog.payment")}
                  onCheckedChange={onChange}
                />
              )}
            />
          </div>
        </React.Fragment>
      ) : null}
      <Label label={t("projects.event-history.filter-dialog.time-frame")} />
      <div className="flex w-fit flex-col gap-2">
        <Controller
          control={control}
          name="from"
          render={({ field: { value, onChange } }) => (
            <Input
              hideLabel={true}
              label={t("projects.event-history.filter-dialog.from")}
              name="dateFrom"
              type="datetime-local"
              value={value}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="to"
          render={({ field: { value, onChange } }) => (
            <Input
              hideLabel={true}
              label={t("projects.event-history.filter-dialog.to")}
              name="dateTo"
              type="datetime-local"
              value={value}
              onChange={onChange}
            />
          )}
        />
      </div>
    </React.Fragment>
  );
}
