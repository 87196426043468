import { HttpError } from "@thisisbud/internal-sdk";

import { useConfig } from "../../../../../context/config";
import { pageNotFound } from "../../../../../constants/error-codes";

function CustomInsightsRouteGuard(): null {
  const { enableCustomInsights } = useConfig();

  if (!enableCustomInsights) {
    throw new HttpError(404, "Not found", {
      code: pageNotFound,
    });
  }

  return null;
}

export default CustomInsightsRouteGuard;
