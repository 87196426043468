import getProjects from "./get-projects";
import getCustomInsights from "./get-custom-insights";
import { getSortedArray } from "../../utils/get-sorted-array";

import type { AuthDetails } from "../types/common";
import type { UserInsight } from "../types/entities";

export type Options = {
  auth: AuthDetails;
  environments: string[];
  excludedProjects?: string[];
};

async function getCustomInsightsForEnvironment({
  auth,
  environment,
}: Pick<Options, "auth" | "excludedProjects"> & { environment: string }): Promise<UserInsight[]> {
  const projects = await getProjects(auth, environment);

  // Get all insights across all projects
  const allProjectInsights = await Promise.all(
    projects.map<Promise<UserInsight[]>>(async (project) => {
      const insights = await getCustomInsights({
        auth: auth,
        environment: environment,
        projectId: project.clientId,
      });
      return insights
        .filter((insight) => insight.active)
        .map((insight) => ({
          ...insight,
          activeOn: [project],
        }));
    }),
  );

  return allProjectInsights.flat();
}

/**
 * Get a list of insights across all projects and environments for a user
 *
 * @param options - The options for the request
 *
 */
export default async function getUserInsights({
  auth,
  environments,
}: Options): Promise<UserInsight[]> {
  const allProjectInsights = await Promise.all(
    environments.map(async (environment) => getCustomInsightsForEnvironment({ auth, environment })),
  );

  // Group by query
  const grouped = allProjectInsights
    .flat()
    .reduce<{ [key: string]: UserInsight }>((collection, insight) => {
      const { activeOn, query } = insight;
      const queryKey = query.toLowerCase();

      if (queryKey in collection) {
        const existingInsight = collection[queryKey];
        const existingProjectIds = existingInsight.activeOn.map((project) => project.clientId);
        const newProjects = activeOn.filter(
          (project) => !existingProjectIds.includes(project.clientId),
        );
        existingInsight.activeOn.push(...newProjects);
      } else {
        collection[queryKey] = { ...insight };
      }

      return collection;
    }, {});

  return getSortedArray(Object.values(grouped), "identifier");
}
