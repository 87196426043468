import React from "react";

import { Pill } from "@thisisbud/gds-ui/pill";
import { useI18n } from "@thisisbud/i18n-react";

import { type EventStatus } from "../api";
import { formatEventStatusLabel, getEventPillStatus } from "./utils";

import type { RequestStatus } from "../api";

type Props = {
  status?: EventStatus | RequestStatus;
  isTestEvent?: boolean;
};

export function EventStatusPill({ status, isTestEvent = false }: Props): React.ReactElement {
  const i18n = useI18n();
  const pillStatus = getEventPillStatus(status);
  const pillLabel = formatEventStatusLabel({ i18n, isTestEvent, status });

  return <Pill status={pillStatus} text={pillLabel} />;
}
