import { requireAuth } from "../../../../../../client/utils/auth";
import { getDemoCustomerById } from "../../../../../../client/utils/demo-customer";
import { createHref } from "../../../../../router";
import confirmOpenBankingConnection from "../../../../../api/operations/demo/confirm-open-banking-connection";
import { RouteName } from "../../../../../router/constants";

import type { InitContext } from ".";
import type { LoadableContext } from "@thisisbud/react-loadable";

export default async function init(ctx: LoadableContext & InitContext): Promise<void> {
  const { demoId, navigate, searchParams } = ctx;
  const auth = await requireAuth();
  const customer = getDemoCustomerById(demoId);
  const connectionTaskId = searchParams.get("connection_task_id");

  if (connectionTaskId !== null && typeof customer !== "undefined") {
    try {
      await confirmOpenBankingConnection(
        {
          ...auth,
          ...customer,
        },
        "sandbox",
        connectionTaskId,
      );

      navigate(
        createHref({
          name: RouteName.demoDashboardHome,
          params: {
            demoId,
          },
        }),
        { replace: true },
      );
    } catch (err) {
      // allow the error screen to be displayed
    }
  }
}
