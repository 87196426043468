import { Version, request } from "@thisisbud/internal-sdk";

import { getCompareFn } from "../../../../utils/i18n";
import { requireAuth } from "../../../../../client/utils/auth";
import { Namespace } from "../../../../api/constants";
import { formatCustomer, formatGetCustomersResponse } from "./utils";

import type { GenericResponse } from "@thisisbud/internal-sdk";
import type {
  Customer,
  CustomerData,
  CustomerResponse,
  GetCustomersArgs,
  GetCustomersByIdArgs,
  GetCustomersResponse,
  GetCustomersStatsResponse,
} from "./types";
import type {} from "../../../../api/types/responses";
import type I18n from "@thisisbud/i18n";
import type { SortDirection } from "../../../../types/sorting";

/**
 * Get a list of customers with a total amount.
 *
 * @param auth - The authentication details for the request
 * @param envType - The environment to get a customer list from
 * @param options - Additional options to handle the request
 * @returns A list of customers with a total amount
 *
 */
export const getCustomers = async (args: GetCustomersArgs): Promise<CustomerData> => {
  const { environment, options } = args;
  const { page, pageSize } = options;

  const auth = await requireAuth();

  const [customers, customersStats] = await Promise.all([
    request<GetCustomersResponse>(Version.v1, Namespace.web, {
      auth: auth,
      body: {
        // TODO: this endpoint uses 0 based indexing. Check with core for update
        page: typeof page !== "undefined" ? page - 1 : undefined,
        page_size: pageSize,
      },
      headers: {
        "X-User-Env": environment,
      },
      method: "GET",
      path: "/customers",
    }),
    request<GetCustomersStatsResponse>(Version.v1, Namespace.web, {
      auth: auth,
      headers: {
        "X-User-Env": environment,
      },
      method: "GET",
      path: "/customers/stats",
    }),
  ]);

  return formatGetCustomersResponse(customers, customersStats.data.total);
};

/**
 * Map a column by which customers are being sorted to a customer data property.
 *
 * @param sortedBy - The column by which customers are being sorted
 * @returns A customer property corresponding to the column being sorted
 */
function getSortedByMapping(sortedBy: string): keyof Customer {
  switch (sortedBy) {
    case "created-at":
      return "createdAt";
    default:
      return "id";
  }
}

/**
 * Sort a list of customers using a given sort direction and column.
 *
 * @param i18n - The i18n instance
 * @param customers - The list of customers to sort
 * @param sortDirection - The sort direction by which customers are to be sorted
 * @param sortedBy - The column by which customers are to be sorted
 * @returns A list of customers sorted by the given direction and property
 */
export function sortCustomers(
  i18n: I18n,
  customers: Customer[],
  sortDirection: SortDirection,
  sortedBy: string,
): Customer[] {
  const compareFn = getCompareFn(i18n);
  const mappedSortBy = getSortedByMapping(sortedBy);

  return [...customers].sort(function (a, b) {
    const propertyA = a[mappedSortBy] ?? "";
    const propertyB = b[mappedSortBy] ?? "";

    if (typeof propertyA === "string" && typeof propertyB === "string") {
      const result = compareFn(propertyA, propertyB);

      if (result !== 0) {
        return result * (sortDirection === "ascending" ? 1 : -1);
      }
    }

    if (sortedBy === "created-at") {
      if (propertyA > propertyB) {
        return sortDirection === "ascending" ? 1 : -1;
      } else if (propertyA < propertyB) {
        return sortDirection === "ascending" ? -1 : 1;
      }
    }

    return 0;
  });
}

/**
 * Delete a customer.
 *
 * @param auth - The authentication details for the request
 * @param envType - The environment to delete a customer from
 * @param customerId - The ID of the cutomer to delete
 */
export const deleteCustomer = async (environment: string, customerId: string): Promise<void> => {
  const auth = await requireAuth();
  await request(Version.v1, Namespace.web, {
    auth: auth,
    headers: {
      "X-User-Env": environment,
    },
    method: "DELETE",
    path: `/customers/${encodeURIComponent(customerId)}`,
  });
};

export const getCustomerById = async ({
  environment,
  customerId,
}: GetCustomersByIdArgs): Promise<Customer[]> => {
  const auth = await requireAuth();

  const { data } = await request<GenericResponse<CustomerResponse>>(Version.v1, Namespace.web, {
    auth: auth,
    headers: {
      "X-User-Env": environment,
    },
    method: "GET",
    path: `/customers/${customerId}`,
    retries: 0,
  });

  return [formatCustomer(data)];
};
