import type { StatusFilter } from "../event-history/types";
import type { StatusFilterMap } from "./types";

export enum WebhookQueryKeys {
  projects = "projects",
  webhooks = "webhooks",
  subscribedEvents = "subscribedEvents",
  webhookEventHistory = "webhook-event-history",
  subscribableEvents = "subscribable-events",
  subsribedEvents = "subscribed-events",
  webhookRequestsHistory = "webhook-requests-history",
}

export const webhookEventsPageSize = 15;

export const statusFilterMap: StatusFilterMap = {
  showCreatedEvents: "created",
  showFailedEvents: "failed",
  showRetryingEvents: "retrying",
  showSentEvents: "sent",
};

export const availableStatusFilters: StatusFilter[] = [
  "showCreatedEvents",
  "showFailedEvents",
  "showSentEvents",
  "showRetryingEvents",
];
