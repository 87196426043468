import { joinUrlPaths } from "@thisisbud/url-utils";

import type { LoadableContext } from "@thisisbud/react-loadable";
import type { SignupOptions } from "./context";

export type InitData = SignupOptions;

export default async function init(ctx: LoadableContext): Promise<InitData> {
  const { basePath } = ctx.config;

  const endpointUrl = joinUrlPaths(basePath, "/hubspot/signup-options");

  // retrieve options from hubspot
  const { countries, reasons, roles } = await fetch(endpointUrl, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "GET",
  }).then(async function (res) {
    return res.json();
  });

  return {
    countries,
    reasons,
    roles,
  };
}
