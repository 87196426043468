import React from "react";
import styled from "styled-components";

import { Form } from "@thisisbud/bui-react-form";
import { SearchIcon } from "@thisisbud/bui-react-icons";
import { Input } from "@thisisbud/bui-react-input";
import { selectFromTheme } from "@thisisbud/sc-utils";
import { Constrictor } from "@thisisbud/bui-react-constrictor";

export type Props = {
  id: string;
  onChange(clientId: string): void;
};

const searchClassname = "search";
const searchConstrictorClassname = `${searchClassname}__constrictor`;

const debounceDelay = 350;
const initialFormValues = {
  searchField: "",
};

const StyledForm = styled(Form)`
  margin-bottom: ${selectFromTheme("spacingMediumDefault")};

  .${searchConstrictorClassname} {
    margin-left: 0;
  }
`;

export function SearchInput(props: Props): React.ReactElement {
  const { id, onChange } = props;

  const timeout = React.useRef<number>();

  React.useEffect(function () {
    return function () {
      clearTimeout(timeout.current);
    };
  }, []);

  // debounce the search
  const onSearchChangeHandler = React.useCallback(
    function (e: React.ChangeEvent<HTMLInputElement>) {
      const { value } = e.currentTarget;

      clearTimeout(timeout.current);

      timeout.current = window.setTimeout(function () {
        onChange(value);
      }, debounceDelay);
    },
    [onChange],
  );

  const inputId = `${id}-search-input`;

  return (
    <StyledForm initialValues={initialFormValues}>
      <Constrictor className={searchConstrictorClassname} narrow={true}>
        <Input
          PrefixIconComponent={SearchIcon}
          id={inputId}
          name="searchField"
          type="search"
          onChange={onSearchChangeHandler}
        />
      </Constrictor>
    </StyledForm>
  );
}
