import * as React from "react";
import styled from "styled-components";

import { selectFromTheme } from "@thisisbud/sc-utils";
import { Button } from "@thisisbud/bui-react-buttons";
import { H2, Paragraph } from "@thisisbud/bui-react-typography";

import {
  pageHeaderActionClassName,
  pageHeaderClassName,
  pageHeaderHeadingGroupClassName,
} from "./constants";

type Props = {
  title: React.ReactNode;
  description?: string | React.ReactNode[];
  actionButtonId?: string;
  actionLabel?: string;
  actionButtonVariant?: "primary" | "secondary";
  onActionClick?(event: React.MouseEvent<HTMLButtonElement>): void;
};

const StyledWrapper = styled.header`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  * + & {
    margin-top: ${selectFromTheme("spacingLargeDefault")};
  }

  // TODO: avoid applying styles to adjacent elements outside of the component
  + * {
    margin-top: ${selectFromTheme("spacingXXXLargeDefault")};
  }

  .${pageHeaderActionClassName} {
    width: auto;
    margin-top: 0;
    margin-right: 0;
  }
`;

export default function PageHeader(props: Props): React.ReactElement {
  const {
    actionButtonId,
    actionLabel,
    actionButtonVariant = "primary",
    description,
    title,
    onActionClick,
  } = props;

  return (
    <StyledWrapper className={pageHeaderClassName}>
      <hgroup className={pageHeaderHeadingGroupClassName}>
        <H2 as="h1">{title}</H2>
        {typeof description !== "undefined" ? <Paragraph>{description}</Paragraph> : null}
      </hgroup>
      {typeof actionLabel !== "undefined" ? (
        <Button
          className={pageHeaderActionClassName}
          id={actionButtonId}
          variant={actionButtonVariant}
          onClick={onActionClick}
        >
          {actionLabel}
        </Button>
      ) : null}
    </StyledWrapper>
  );
}
