import { HttpError } from "@thisisbud/internal-sdk";
import { joinUrlPaths } from "@thisisbud/url-utils";

import validateTeamInvite from "../../../api/operations/validate-team-invite";
import { createHref } from "../../../router";
import { RouteName } from "../../../router/constants";
import { hubspotRouterPath, signupOptionsPath } from "../../../constants/paths";

import type { LoadableContext } from "@thisisbud/react-loadable";
import type { TeamInvite } from "../../../api/types/entities";
import type { CreateUserOptions } from "./types";
import type { LocalLoadableContext } from ".";

export type InitData = {
  email: TeamInvite["email"];
  errorCode?: string;
  options?: CreateUserOptions;
  status: TeamInvite["status"];
};

export default async function init(ctx: LoadableContext & LocalLoadableContext): Promise<InitData> {
  const {
    config: { basePath },
    email,
    token,
  } = ctx;

  let teamInvite: TeamInvite;
  let options: CreateUserOptions;

  try {
    teamInvite = await validateTeamInvite({ email, token });

    // retrieve options from hubspot
    options = await fetch(joinUrlPaths(basePath, hubspotRouterPath, signupOptionsPath), {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    }).then(async function (res) {
      return res.json();
    });
  } catch (err) {
    // there is only 1 known point of error
    if ((err as HttpError).status === 410) {
      return {
        email: email,
        errorCode: "token-expired",
        status: "error",
      };
    }
    throw new HttpError(307, "Redirected", {
      payload: {
        redirectTo: createHref({ name: RouteName.login }),
      },
    });
  }

  return {
    email: teamInvite.email,
    options: options,
    status: teamInvite.status,
  };
}
