import React from "react";

import { H4, Paragraph } from "@thisisbud/bui-react-typography";
import { useI18n } from "@thisisbud/i18n-react";
import { Button } from "@thisisbud/gds-ui/button";

type Props = {
  openCreateWebhookDialog(): void;
};

export function NoWebhooks({ openCreateWebhookDialog }: Props): React.ReactElement {
  const i18n = useI18n();

  return (
    <section className="mt-[4rem] flex flex-col items-center">
      <H4 as="h3" className="!mr-0">
        {i18n.t("projects.project.webhooks.title")}
      </H4>
      <Paragraph className="!mr-0">{i18n.t("projects.project.webhooks.body")}</Paragraph>
      <div className="mt-6">
        <Button onClick={openCreateWebhookDialog}>
          {i18n.t("projects.project.webhooks.setup-button")}
        </Button>
      </div>
    </section>
  );
}
