import { Version, request } from "@thisisbud/internal-sdk";

import { Namespace } from "../../constants";

import type { AuthDetails } from "../../types/common";

type Options = {
  name: string;
};

/**
 * Update a project.
 *
 * @param auth - The authentication details for the request
 * @param envType - The environment of the project
 * @param projectClientId - The project client ID
 * @param options - The options for the request
 */
export default async function updateProject(
  auth: AuthDetails,
  envType: string,
  projectClientId: string,
  options: Options,
): Promise<void> {
  const { name } = options;

  await request(Version.v1, Namespace.web, {
    auth: auth,
    body: {
      label: name,
    },
    headers: {
      "X-User-Env": envType,
    },
    method: "PATCH",
    path: `api-credentials/${encodeURIComponent(projectClientId)}`,
  });
}
