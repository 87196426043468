import * as React from "react";

import Loadable from "@thisisbud/react-loadable";

import { loadingView } from "../loading";
import init from "./init";
import EnrichmentRouteGuard from "./route-guard";

/**
 * Lazily load the enrichment component.
 *
 * @returns The imported module
 */
async function loadComponent(): Promise<{ default: React.ComponentType }> {
  return import(/* webpackChunkName: 'views/enrichment-guide' */ "./component");
}

/**
 * Lazily load the component and initialize data for the Enrichment Guide view.
 */
export default function EnrichmentViewLoader(): React.ReactElement {
  return (
    <React.Fragment>
      <EnrichmentRouteGuard />
      <Loadable
        fallback={loadingView}
        fallbackDelay={0}
        init={init}
        loadComponent={loadComponent}
      />
    </React.Fragment>
  );
}
