import * as React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import Loadable from "@thisisbud/react-loadable";

import { loadingView } from "../loading";
import { createHref } from "../../../router";
import { RouteName } from "../../../router/constants";
import { getAuthenticated } from "../../../store/auth";

/**
 * Lazily load the component.
 *
 * @returns The imported module
 */
async function loadComponent(): Promise<{ default: React.ComponentType }> {
  return import(/* webpackChunkName: 'views/login' */ "./component");
}

/**
 * Lazily load the component and initialize data for the Login view.
 */
export default function LoginViewLoader(): React.ReactElement {
  const authenticated = useSelector(getAuthenticated);

  if (authenticated) {
    return (
      <Navigate
        replace={true}
        to={createHref({
          name: RouteName.home,
        })}
      />
    );
  }

  return <Loadable fallback={loadingView} fallbackDelay={0} loadComponent={loadComponent} />;
}
