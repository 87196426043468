import { Version, request } from "@thisisbud/internal-sdk";

import { Namespace } from "../constants";
import { formatGetEnvironmentsResponse } from "../formatters/environments";

import type { AuthDetails } from "../types/common";
import type { TargetEnvironment } from "../../types/environment";
import type { GetEnvironmentsResponse } from "../types/responses";

/**
 * Retrieve the allowed environments for the user
 */
export default async function getEnvironments(auth: AuthDetails): Promise<TargetEnvironment[]> {
  const response = await request<GetEnvironmentsResponse>(Version.v1, Namespace.me, {
    auth: auth,
    headers: {
      "Content-Type": "application/json",
    },
    method: "GET",
    path: "/environments",
  });

  return formatGetEnvironmentsResponse(response);
}
