import { Version, formatGetAccountProvidersResponse, request } from "@thisisbud/internal-sdk";

import { Namespace } from "../../constants";

import type { AuthDetails } from "../../types/common";
import type { GetDemoOpenBankingProvidersResponse } from "../../types/responses";
import type { ProviderMap } from "@thisisbud/internal-sdk";

/**
 * Get a list of available demo open banking providers.
 *
 * @param auth - The authentication details for the request
 * @param envType - The environment to get a list from
 * @returns A map of provider IDs and provider data pairs
 *
 */
export default async function getDemoOpenBankingProviders(
  auth: AuthDetails,
  envType: string,
): Promise<ProviderMap> {
  const response = await request<GetDemoOpenBankingProvidersResponse>(
    Version.v1,
    Namespace.openBankingDemo,
    {
      auth: auth,
      headers: {
        "X-User-Env": envType,
      },
      method: "GET",
      path: "/providers",
    },
  );

  return formatGetAccountProvidersResponse(response);
}
