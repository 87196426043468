import { HttpError } from "@thisisbud/internal-sdk";

import getCustomInsights from "../../../../../../api/operations/get-custom-insights";
import { requireAuth } from "../../../../../../../client/utils/auth";
import { pageNotFound } from "../../../../../../constants/error-codes";

import type { LoadableContext } from "@thisisbud/react-loadable";
import type { LocalLoadableCtx } from ".";
import type { CustomInsight } from "../../../../../../api/types/entities";

export type InitData = {
  insight: CustomInsight;
  environment: string;
  projectId: string;
};

export default async function init(ctx: LoadableContext & LocalLoadableCtx): Promise<InitData> {
  const {
    insightId,
    environment,
    project: { clientId: projectId },
  } = ctx;

  const auth = await requireAuth();
  const insights = await getCustomInsights({ auth, environment, projectId });

  const insight = insights.find((p) => insightId === p.id);

  if (typeof insight === "undefined") {
    throw new HttpError(404, "Insight not found", {
      code: pageNotFound,
    });
  }

  return {
    environment,
    insight,
    projectId,
  };
}
